import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {DecroissanceModel} from '../../core/models/decroissance.model';
import {PlanCroissanceModel} from '../../core/models/planCroissance.model';
import {AttachmentService} from '../../shared/services/attachment.service';
import {Router} from '@angular/router';
import {Utils} from '../../core/utils/Utils';

@Component({
  selector: 'app-vue-fournisseur',
  templateUrl: './vue-fournisseur.component.html',
  styleUrls: ['./vue-fournisseur.component.scss']
})
export class VueFournisseurComponent implements OnInit {

  countries = [];
  years = [];
  osVersions = [];
  isAccepted: any = false;
  yearControl: any = '0';
  rayonControl: any = '0';
  deptControl: any = '0';
  rayons = [];
  depts = [];
  fournisseurControl: any = '0';
  fournisseurs = [];
  questions = [];
  demandes: PlanCroissanceModel[] = [];
  listDecroissance: DecroissanceModel[] = [];
  sQuestions = [];
  user: any;
  currentYear: string;
  currentFournisseur: string;
  issfamille = false;
  isfamille = false;
  totalCA = 0;
  totalTM = 0;
  totalDCV = 0;
  totalDMV = 0;
  totalDTV = 0;
  totalDTT = 0;
  defaultrakingList = ['Volume', 'Espace', 'Gamme', 'Promotions',
    'Partenariat', 'Lancement d\'un nouveau produit', 'Données', 'Prix'];
  rakingList = [];
  rakingLastYear = [];
  indexRemoveOrder: number;
  commentL2: string = '';
  commentL3: string = '';
  targetDragged: any;
  lastCa: any = 0;
  isShowRankingLastYear: boolean = false;
  lastYear: number;
  currentRayon: string;
  currentFamille: string;
  username: string;
  actions = [];

  constructor(private http: HttpClient,
              private router: Router,
              private attachmentService: AttachmentService) {
    if (!attachmentService.canConnect) {
      this.router.navigate(['access-denied']);
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userObject'));
    this.username = localStorage.getItem('username');
    this.getRanking();
    this.getActions();
  }

  getActions() {
    this.actions.push('Assortiment');
    this.actions.push('Merchandising');
    this.actions.push('Logistique');
    this.actions.push('Promo');
  }

  isFormulaireValid() {
    if (!this.isRayonValid()) {
      return false;
    } else if (this.yearControl == '0') {
      return false;
    } else {
      return this.fournisseurControl != '0';
    }
  }

  yearChanged() {
    console.log(this.yearControl);
  }

  isRayonValid() {
    return this.rayonControl != '0';
  }

  isFournisValid() {
    return this.fournisseurControl != '0';
  }

  isdeptValid() {
    return this.deptControl != '0';
  }

  rayonChanged() {
    //this.getFornisseurs();
  }

  getQuestions() {
    this.questions = [];
    this.attachmentService.getComments(this.fournisseurControl).subscribe(response => {
      response.forEach(comment => {
        this.questions.push(comment);
      });
    });
  }

  getRanking() {
    this.currentYear = localStorage.getItem('year');
    this.lastYear = Number(this.currentYear) - 1;
    this.currentFournisseur = localStorage.getItem('fournisseur');
    this.currentFamille = localStorage.getItem('famille');
    this.currentRayon = localStorage.getItem('rayon');
    this.rakingList = [];
    let dto = {
      fournisseur: this.currentFournisseur,
      annee: this.currentYear
    };
    this.attachmentService.getRanking(dto).subscribe(response => {
      if (response && response.length) {
        response.forEach(rank => this.rakingList.push(rank.column));
      } else {
        this.rakingList = this.defaultrakingList;
      }
      this.getDemandes();
    });
  }

  getRankingLastYear() {
    this.rakingLastYear = [];
    let dto = {
      fournisseur: this.currentFournisseur,
      annee: this.lastYear
    };
    this.attachmentService.getRanking(dto).subscribe(response => {
      if (response && response.length) {
        response.forEach(rank => this.rakingLastYear.push(rank.column));
      } else {
        this.rakingLastYear = this.defaultrakingList;
      }
    });
  }

  getDemandes() {
    this.demandes = [];
    let dto = {
      fournisseur: this.currentFournisseur,
      annee: this.currentYear,
      issfamille: false,
      isfamille: true
    };
    this.attachmentService.getPlanCroissance(dto, this.username).subscribe(response => {
      this.lastCa = this.checkNumber(response[response.length - 1].ca);
      let dtol2l3 = {
        fournisseur: this.currentFournisseur,
        annee: this.currentYear,
        rayon: this.currentRayon
      };
      this.attachmentService.getl2l3(dtol2l3, this.username).subscribe(res => {
        this.getDecroissance();
        if (res && res.length) {
          this.commentL2 = res[0].levier2;
          this.commentL3 = res[0].levier3;
        }
      });
      /*let indexOf = response.indexOf(dm=>dm.rayon==this.currentRayon);
      this.demandes.splice(indexOf, 1);*/
      this.demandes = response.slice(0, response.length - 1);
      this.demandes.forEach(dm => {
        dm.ca = this.checkNumber(dm.ca);
        dm.annee = this.currentYear;
      });
      this.changeCa();
    });
  }

  getDecroissance() {
    this.listDecroissance = [];
    let dto = {
      fournisseur: this.currentFournisseur,
      annee: this.currentYear
    };
    this.attachmentService.getPlanDecroissance(dto).subscribe(response => {
      if (response && response.length) {
        this.listDecroissance = response;
        /*this.listDecroissance.forEach(oneDec => {
            if (oneDec.action && oneDec.action != '') {
                if (!this.actions.includes(oneDec.action)) {
                    oneDec.listAction = 'Autre';
                } else {
                    oneDec.listAction = oneDec.action;
                }
            }
        });*/
      } else {
        if (!this.listDecroissance.find(ld => ld.impact == 'Limité')) {
          this.listDecroissance.push(new DecroissanceModel('Limité', this.listDecroissance.length + 1));
        }
        if (!this.listDecroissance.find(ld => ld.impact == 'Moyen')) {
          this.listDecroissance.push(new DecroissanceModel('Moyen', this.listDecroissance.length + 1));
        }
        if (!this.listDecroissance.find(ld => ld.impact == 'Elevé')) {
          this.listDecroissance.push(new DecroissanceModel('Elevé', this.listDecroissance.length + 1));
        }
      }
    });
  }

  checkNumber(number) {
    return (!number || number == '') ? null : Number(number);
  }

  saveDemandes() {
    this.saveRanking();
  }

  showEditDialogPosture(question: any) {
    Swal.fire({
      title: '<strong></strong>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: question.argument,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d5d5efde',
      focusConfirm: false,
      /*inputValidator: result => {
        if (!result)
          return 'Il faut écrire un texte!';
      },*/
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      cancelButtonText:
        '<i class="fa fa-ban" style="color: black">Annuller</i>',
      cancelButtonAriaLabel: 'Annuller'
    }).then(response => {
      console.log(response);
      if (response.isConfirmed) {
        question.argument = response.value;
      }
    });
  }

  showEditJustCommentL2() {
    Swal.fire({
      title: '<strong></strong>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: this.commentL2,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d5d5efde',
      focusConfirm: false,
      /*inputValidator: result => {
        if (!result)
          return 'Il faut écrire un texte!';
      },*/
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      cancelButtonText:
        '<i class="fa fa-ban" style="color: black">Annuller</i>',
      cancelButtonAriaLabel: 'Annuller'
    }).then(response => {
      console.log(response);
      if (response.isConfirmed) {
        this.commentL2 = response.value;
      }
    });
  }

  showEditJustCommentL3() {
    Swal.fire({
      title: '<strong></strong>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: this.commentL3,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d5d5efde',
      focusConfirm: false,
      /*inputValidator: result => {
        if (!result)
          return 'Il faut écrire un texte!';
      },*/
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      cancelButtonText:
        '<i class="fa fa-ban" style="color: black">Annuller</i>',
      cancelButtonAriaLabel: 'Annuller'
    }).then(response => {
      console.log(response);
      if (response.isConfirmed) {
        this.commentL3 = response.value;
      }
    });
  }

  drop(ev, att: any, i: number) {
    let indexDropOrder = i;
    if (indexDropOrder != this.indexRemoveOrder) {
      ev.preventDefault();
      this.targetDragged.style.opacity = 0.8;
      setTimeout(() => this.targetDragged.style.opacity = 1, 1000);
      /*var data = ev.dataTransfer.getData("text");
      ev.target.appendChild(document.getElementById(data));*/
      this.rakingList = this.array_move(this.rakingList, this.indexRemoveOrder, indexDropOrder);
    }
  }

  allowDrop(ev) {
    ev.preventDefault();
  }

  array_move(arr, old_index, new_index) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  }

  drag(ev, att: any, i: number) {
    this.targetDragged = ev.target;
    this.targetDragged.style.opacity = 0.5;
    this.indexRemoveOrder = i;
    ev.dataTransfer.setData('text', ev.target.id);
  }

  saveRanking() {
    let listData = [];
    for (let i = 0; i < this.rakingList.length; i++) {
      listData.push({
        fournisseur: this.currentFournisseur,
        annee: this.currentYear,
        column: this.rakingList[i],
        number: (i + 1)
      });
    }
    this.attachmentService.saveRanking(listData).subscribe(response => {
      this.savePlanCroissance();
      /*this.getRanking();*/
    });
  }

  savePlanDecroissance() {
    this.listDecroissance.forEach(plan => {
      plan.annee = this.currentYear;
      plan.fournisseur = this.currentFournisseur;
    });
    this.attachmentService.savePlanDecroissance(this.listDecroissance).subscribe(response => {
      this.getRanking();
      /*this.getDecroissance();*/
    });
  }

  savePlanCroissance() {
    let findedDemande = this.demandes.find(dm => dm.rayon == this.currentRayon);
    if (findedDemande) {
      findedDemande.levier2 = this.commentL2;
      findedDemande.levier3 = this.commentL3;
    }
    this.demandes.push({
      fournisseur: this.currentFournisseur,
      annee: (Number(this.currentYear) + 1).toString(),
      famille: '',
      rayon: '',
      ca: this.totalCA,
      argument: '',
      levier2: this.commentL2,
      levier3: this.commentL3
    });
    this.demandes.push({
      fournisseur: this.currentFournisseur,
      annee: (Number(this.currentYear)).toString(),
      famille: '',
      rayon: '',
      ca: this.lastCa,
      argument: '',
      levier2: this.commentL2,
      levier3: this.commentL3
    });
    this.attachmentService.savePlanCroissance(this.demandes).subscribe(response => {
      this.savel2l3();
    });
  }

  savel2l3() {
    let l2l3Data = [];
    l2l3Data.push({
      fournisseur: this.currentFournisseur,
      annee: this.currentYear,
      famille: '',
      rayon: this.currentRayon,
      levier2: this.commentL2,
      levier3: this.commentL3
    });
    this.attachmentService.savel2l3(l2l3Data).subscribe(response => {
      this.savePlanDecroissance();
    });
  }

  changeCa() {
    this.totalCA = 0;
    this.demandes.forEach(dmn => this.totalCA += dmn.ca);
    this.totalCA += this.lastCa;
    this.totalCA = Number(this.totalCA.toFixed(2));
  }

  addNewPlanDec(i: any, impact: any) {
    this.listDecroissance.splice(i + 1, 0, new DecroissanceModel(impact, i + 1));
    let etape = 1;
    this.listDecroissance.forEach(plan => {
      plan.etape = etape.toString();
      etape++;
    });
  }

  removePlanDec(i: any, impact: any) {
    if (this.listDecroissance.filter(plan => plan.impact == impact).length > 1) {
      this.listDecroissance.splice(i, 1);
      let etape = 1;
      this.listDecroissance.forEach(plan => {
        plan.etape = etape.toString();
        etape++;
      });
    }
  }

  difine() {

  }

  showRankingLastYear() {
    this.getRankingLastYear();
    this.isShowRankingLastYear = true;
  }

  onItemSelect($event) {

  }

  /*listActionChanged(demande: DecroissanceModel) {
      if (demande.listAction == 'Autre') {
          Swal.fire({
              title: '<strong></strong>',
              input: 'text',
              inputLabel: 'Action',
              inputValue: '',
              inputPlaceholder: 'Tapez votre action ici...',
              inputAttributes: {
                  'aria-label': 'Tapez votre action ici...'
              },
              showCloseButton: true,
              showCancelButton: true,
              cancelButtonColor: '#d5d5efde',
              focusConfirm: false,
              inputValidator: result => {
                  if (!result) {
                      return 'Il faut écrire un texte!';
                  }
              },
              confirmButtonText:
                  '<i class="fa fa-edit"></i> Modifier!',
              confirmButtonAriaLabel: 'Modifier',
              cancelButtonAriaLabel: 'Annuller',
              cancelButtonText:
                  '<i class="fa fa-ban" style="color: black"> Annuller</i>'
          }).then(res => {
              if (res.isConfirmed) {
                  demande.action = res.value;
              } else if (res.isDismissed && res.dismiss == Swal.DismissReason.cancel) {
                  demande.listAction = demande.action;
              }
          });
      } else {
          demande.action = demande.listAction;
      }
  }*/
}
