<div class="page">
  <!--<app-stats></app-stats>-->
  <app-stats [isGestionListePlaybook]="true" [currentUser]="username"></app-stats>
  <main>
    <div class="panel">
      <div class="table-panel users">
        <div class="panel-title">
          Gestion Liste Playbook
        </div>
        <div class="panel-body" style="display: flex;align-items: center;justify-content: space-around;">
          <div class="row w-100">
            <div class="col-10">
              <div class="row">
                <div class="form-group col-4" style="padding: 0 0.5rem;">
                  <label class="form-label">Fournisseur :</label>
                  <ng-select *ngIf="fournisseurs&&fournisseurs.length" [disabled]="isEdit"
                             [items]="fournisseurs"
                             placeholder="Selectionner un utilisateur"
                             [(ngModel)]="fournisseurControl">
                  </ng-select>
                </div>
                <div class="form-group col-4" style="padding: 0 0.5rem;">
                  <label class="form-label">Annee :</label>
                  <ng-select *ngIf="years&&years.length"
                             [items]="years"
                             placeholder="Selectionner l'annee"
                             [(ngModel)]="yearControl">
                  </ng-select>
                </div>
                <div class="form-group col-4" style="padding: 0 0.5rem;">
                  <label class="form-label">Utilisateur :</label>
                  <ng-select *ngIf="users&&users.length"
                             [items]="users"
                             placeholder="Selectionner un utilisateur"
                             [(ngModel)]="userControl">
                  </ng-select>
                </div>
                <div class="form-group col-4" style="padding: 0 0.5rem;">
                  <label class="form-label">Date fin finalisation :</label>
                  <input type="date" [max]="dateFinValidation" class="form-control" [(ngModel)]="dateFinFinalisation">
                </div>
                <div class="form-group col-4" style="padding: 0 0.5rem;">
                  <label class="form-label">Date fin validation :</label>
                  <input type="date" [min]="dateFinFinalisation" class="form-control" [(ngModel)]="dateFinValidation">
                </div>
                <div class="form-group col-4" style="padding: 0 0.5rem;">
                  <label class="form-label">Tier :</label>
                  <ng-select *ngIf="tiers&&tiers.length"
                             [items]="tiers"
                             placeholder="Selectionner tier"
                             [(ngModel)]="tierControl">
                  </ng-select>
                </div>
                <div class="form-group col-4" style="padding: 0 0.5rem;">
                  <label class="form-label">Niveau de validation :</label>
                  <ng-select *ngIf="niveaux&&niveaux.length"
                             [items]="niveaux"
                             placeholder="Selectionner niveau de validation"
                             [(ngModel)]="niveauControl">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <div style="width: 60%;margin-left: auto;margin-right: auto;">
                  <button class="btn btn-primary flexAutoMarge" *ngIf="!isEdit"
                          style="color: white;background-color: #b22a2e;height: 3.3rem;width: 100%;margin: 0.5rem"
                          [disabled]="!isFormulaireValid()" (click)="addUser()">Ajouter
                  </button>
                  <button class="btn btn-primary flexAutoMarge" *ngIf="isEdit"
                          style="color: white;background-color: #b22a2e;height: 3.3rem;width: 100%;margin: 0.5rem"
                          [disabled]="!isFormulaireValid()" (click)="editUser()">Modifier
                  </button>
                  <button class="btn btn-light flexAutoMarge"
                          style="height: 3.3rem;width: 100%;margin: 0.5rem"
                          (click)="this.isEdit = false;this.userControl = null;this.fournisseurControl = null;this.niveauControl = null;this.dateFinFinalisation = null;this.dateFinValidation = null;this.tierControl = null;">
                    Annuler
                  </button>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    <div class="panel">
      <div class="table-panel users">
        <div class="panel-title">
          Liste des playbooks
        </div>
        <input type="text" class="form-control" [(ngModel)]="searchFilter" placeholder="Recherche"
               style="width: 90%;margin-left: auto;margin-right: auto">
        <div class="panel-body">
          <table>
            <thead>
            <tr>
              <th style="width: 7%">
                Playbook
              </th>
              <th style="width: 5%;text-align: center">
                Annee
              </th>
              <th style="width: 15%;text-align: center">
                Utilisateur
              </th>
              <th style="width: 7%;text-align: center">
                Date fin finalisation
              </th>
              <th style="width: 7%;text-align: center">
                Date fin validation
              </th>
              <th style="width: 5%;text-align: center">
                Tier
              </th>
              <th style="width: 6%;text-align: center">
                Statut
              </th>
              <th style="width: 25%;text-align: center">
                Achteur
              </th>
              <th style="width: 7%;text-align: center">
                Sous départements
              </th>
              <th style="width: 7%;text-align: center">
                Niveau de validation
              </th>
              <th style="width: 25%;text-align: center">
                Actions
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of getLeadFiltered()">
              <td >{{ user.fournisseur }}</td>
              <td style="text-align: center">{{ user.annee }}</td>
              <td style="text-align: center">{{ user.responsable }}</td>
              <td style="text-align: center">{{ user.date_fin_finalisation }}</td>
              <td style="text-align: center">{{ user.date_fin_validation }}</td>
              <td style="text-align: center">{{ user.tier }}</td>
              <td style="text-align: center"><span [style.background-color]="user.status=='CREATED'?'#57ef57':'#01ab01'"
                                                   style="color: white;border-radius: 50px;padding: 0.4rem;">{{ user.status }}</span>
              </td>
              <td style="text-align: center">
                <div class="divWithTickets">
                  <div class="row" *ngIf="user.achteurs">
                                        <pre class="col-lg-7 col-md-7 col-sm-7 mx-auto"
                                             style="color:white;background-color: rgba(54,153,255,0.71);border-radius: 1rem;padding: 0.2rem;margin: 0.1rem"
                                             [style.margin-top]="ifCanAddMargin(user.achteurs,i)?'1rem':''"
                                             *ngFor="let fournis of user.achteurs.slice(0,9);let i = index;">{{ fournis }}</pre>
                    <pre class="col-lg-7 col-md-7 col-sm-7 mx-auto"
                         style="color:white;background-color: rgba(54,153,255,0.71);border-radius: 1rem;padding: 0.2rem;margin: 0.1rem"
                         *ngIf="user.achteurs.length>9"
                         [title]="user.achteursTitle">...</pre>
                  </div>
                </div>
              </td>
              <td style="text-align: center">
                <div class="divWithTickets">
                  <div class="row" *ngIf="user.sous_depts">
                                        <pre class="col-lg-7 col-md-7 col-sm-7 mx-auto"
                                             style="color:white;background-color: rgba(54,153,255,0.71);border-radius: 1rem;padding: 0.2rem;margin: 0.1rem"
                                             [style.margin-top]="i!=0?'2rem':''"
                                             *ngFor="let fournis of user.sous_depts.slice(0,9);let i = index;">{{ fournis }}</pre>
                    <pre class="col-lg-7 col-md-7 col-sm-7 mx-auto"
                         style="color:white;background-color: rgba(54,153,255,0.71);border-radius: 1rem;padding: 0.2rem;margin: 0.1rem"
                         *ngIf="user.sous_depts.length>9"
                         [title]="user.sous_deptsTitle">...</pre>
                  </div>
                </div>
              </td>
              <td style="text-align: center">{{ user.niveau_validation }}</td>
              <td style="text-align: center"><i class="fa fas fa-edit cursor-pointer"
                                                (click)="isEdit=true;chargeUser(user)"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</div>
