<div class="page">
  <!--<app-stats></app-stats>-->
  <app-stats [isForQuestion]="true" [currentYear]="yearControl" [currentFournisseur]="fournisseurControl"></app-stats>
  <main>
    <div class="panel">
      <div class="table-panel countries">
        <div class="panel-title" style="color: #b22a2e;text-transform: initial">Recherche avancée</div>
        <div class="panel-body">
          <div style="display: flex;margin: 1rem 0;align-items: center">
            <div class="form-group fournControlClass" style="position:relative;">
              <i class="fa fa-fw fa-caret-down"
                 style="position: absolute;right: 0.5rem;top: 2rem;font-size: large;"></i>
              <label>Fournisseur</label>
              <ng-select *ngIf="fournisseurs&&fournisseurs.length"
                         [items]="fournisseurs"
                         [bindLabel]="'label'"
                         placeholder="Selectionner un role"
                         [(ngModel)]="fournisseurControl"
                         (ngModelChange)="controlChangedFournisseur()">
              </ng-select>
              <!--<select class="form-control" [(ngModel)]="fournisseurControl"
                      (change)="controlChangedFournisseur()">
                  <option disabled value="0">Selectionner un fournisseur</option>
                  <option *ngFor="let fournisseur of fournisseurs"
                          value="{{fournisseur}}">{{fournisseur}}</option>
              </select>-->

            </div>
            <button class="btn btn-primary flexAutoMarge"
                    style="color: white;background-color: #b22a2e;top: 0.6rem;position: relative;margin: 0 2rem;"
                    *ngIf="canClickLead() && !canValideChef()"
                    (click)="toLeadFunction()">Lead
            </button>
            <button class="btn btn-primary flexAutoMarge"
                    style="color: white;background-color: #b22a2e;top: 0.6rem;position: relative;margin: 0 2rem;"
                    *ngIf="canValideChef()"
                    (click)="toLeadFunction()">A valider
            </button>
          </div>
          <div style="display: flex;margin: 1rem 0;align-items: center">
            <div class="form-group flexAutoMarge" style="position:relative;">
              <i class="fa fa-fw fa-calendar"
                 style="position: absolute;right: 0.5rem;top: 2rem;font-size: large;"></i>
              <label>Annee</label>
              <select class="form-control" [(ngModel)]="yearControl" (change)="controlChangedYear()">
                <option disabled value="0">Selectionner une annee</option>
                <option *ngFor="let year of years" value="{{year}}">{{ year }}</option>
              </select>
              <small style="visibility: hidden" class="form-text text-muted">Annee</small>
            </div>
            <div class="form-group flexAutoMarge" style="position:relative;">
              <i class="fa fa-fw fa-caret-down"
                 style="position: absolute;right: 0.5rem;top: 2rem;font-size: large;"></i>
              <label>Département</label>
              <select class="form-control" [(ngModel)]="deptControl" (change)="deptChanged()"
                      [disabled]="!isFournisValid()">
                <option disabled value="0">Selectionner un Département</option>
                <option *ngFor="let dept of depts" value="{{dept}}">{{ dept }}</option>
              </select>
              <small class="form-text text-muted">Choisir le fournisseur pour choisir département</small>
            </div>
            <div class="form-group flexAutoMarge" style="position:relative;">
              <i class="fa fa-fw fa-caret-down"
                 style="position: absolute;right: 0.5rem;top: 2rem;font-size: large;"></i>
              <label>Rayon</label>
              <select class="form-control" [(ngModel)]="rayonControl" (change)="rayonChanged()"
                      [disabled]="!isdeptValid()">
                <option disabled value="0">Selectionner un rayon</option>
                <option *ngFor="let rayon of rayons" value="{{rayon}}">{{ rayon }}</option>
              </select>
              <small class="form-text text-muted">Choisir département pour choisir le rayon</small>
            </div>
            <button class="btn btn-primary flexAutoMarge"
                    style="color: white;background-color: #b22a2e;height: 3.3rem;"
                    [disabled]="!isFormulaireValid()" (click)="getQuestions()">Rechercher
            </button>
          </div>

          <!--<div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="isAccepted">
            <label class="form-check-label" for="exampleCheck1">Accept</label>
          </div>-->
        </div>
      </div>
    </div>
    <ng-container *ngFor="let qg of questionsGrouped;index as iqg">
      <div class="panel">
        <div class="table-panel countries">
          <div class="panel-title">{{ qg.title }}</div>
          <div class="panel-body">
            <table>
              <thead [class.hiddenDiv]="false">
              <tr>
                <!--<th>

                </th>-->
                <th style="width: 32rem" *ngIf="qg.list.length>1||qg.list[0].question!='_'">
                </th>
                <th style="width: 23rem;font-weight: 400;color: #7a7a7a;text-align: center">
                  Commentaire
                </th>
                <th class="widthThModif widthDateLastEdit" style="font-weight: 400;color: #7a7a7a;">
                  Dernière modification (user)
                </th>
                <th class="widthThModif widthDateLastEdit" style="font-weight: 400;color: #7a7a7a;">
                  Dernière date modification
                </th>
              </tr>
              </thead>
              <tbody>

              <tr *ngFor="let question of qg.list;index as i">
                <!--<td>
                  #
                </td>-->
                <!--<td class="normalTextTable" style="font-weight: bold" *ngIf="i==0" [rowSpan]="qg.list.length">
                  {{question.partie}}
                </td>-->
                <td class="normalTextTable2" *ngIf="question.question!='_'">
                  {{ question.question }}
                </td>
                <td class="cursor-pointer normalTextTable">
                  <div class="cursor-pointer"
                       style="min-height: 3rem;min-width: 40rem;text-align: start;padding: 0.5rem;"
                       [class.textCommentRose]="true"
                       (click)="canShowListComments(question)">
                    {{ question.reponse }}
                  </div>
                </td>
                <td class="normalTextTable widthDateLastEdit">
                  {{ question.lastUserEdit }}
                </td>
                <td class="normalTextTable widthDateLastEdit">
                  {{ question.lastTimeEdit }}
                </td>
                <!--<td>
                  <div class="borderDivIcon" style="background-color: #0bc326"
                       [class.disabledIcon]="yearControl<currentYear">
                    <i class='fas fa fa-edit iconInsideDiv'
                       (click)="showEditDialog(question)"></i>
                  </div>
                </td>-->
              </tr>

              </tbody>
            </table>
          </div>
          <div style="display: flex" *ngIf="qg.title=='Posture de négociation'">
            <div class="panel-title" style="width: 80%"></div>
            <div class="form-check form-switch center-div-inside-td" style="width: 17rem"
                 *ngIf="postures&&postures.length">
              <input type="checkbox" class="form-check-input" [(ngModel)]="issfamille"
                     (change)="changeTempPosture();getPostures()">
              <label class="form-check-label">Sous-Famille</label>
            </div>
          </div>
          <div class="panel-body" *ngIf="qg.title=='Posture de négociation'">
            <table>
              <thead>
              <tr>
                <th class="normalTextTableTh">
                  Département
                </th>
                <th class="normalTextTableTh">
                  Rayon
                </th>
                <th class="normalTextTableTh">
                  Famille
                </th>
                <th class="normalTextTableTh" *ngIf="issfamille">
                  Sous-Famille
                </th>
                <th class="normalTextTableTh postTypeStyle">
                  Décroissance
                </th>
                <th class="normalTextTableTh postTypeStyle">
                  Maintien
                </th>
                <th class="normalTextTableTh postTypeStyle">
                  Croissance
                </th>
                <th class="normalTextTableTh" style="width: 23rem">
                  Commentaire
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let question of postures">
                <td class="normalTextTable">
                  <input type="text" disabled class="form-control inputTableText"
                         value="{{question.departement}}">
                </td>
                <td class="normalTextTable">
                  <input type="text" disabled class="form-control inputTableText"
                         value="{{question.rayon}}">
                </td>
                <td class="normalTextTable">
                  <input type="text" disabled class="form-control inputTableText"
                         [class.isCroissance]="isCroissance(question)"
                         [class.isMaintien]="isMaintien(question)"
                         [class.isDecroissance]="isDecroissance(question)"
                         value="{{question.famille}}">
                </td>
                <td class="normalTextTable" *ngIf="issfamille">
                  <input type="text" disabled class="form-control inputTableText"
                         value="{{question.sfamille}}">
                </td>
                <td class="normalTextTable">
                  <div class="form-check form-switch center-div-inside-td">
                    <input class="form-check-input" type="checkbox"
                           (click)="editPosture(question,'decroissance')"
                           [checked]="question.status=='decroissance'">
                    <label class="form-check-label"></label>
                  </div>
                </td>
                <td class="normalTextTable">
                  <div class="form-check form-switch center-div-inside-td">
                    <input class="form-check-input" type="checkbox"
                           (click)="editPosture(question,'maintien')"
                           [checked]="question.status=='maintien'">
                    <label class="form-check-label"></label>
                  </div>
                </td>
                <td class="normalTextTable">
                  <div class="form-check form-switch center-div-inside-td">
                    <input class="form-check-input" type="checkbox"
                           (click)="editPosture(question,'croissance')"
                           [checked]="question.status=='croissance'">
                    <label class="form-check-label"></label>
                  </div>
                </td>
                <td class="cursor-pointer normalTextTable">
                  <div class="cursor-pointer" style="min-height: 3rem;text-align: start;padding: 0.5rem;"
                       [class.textCommentRose]="true"
                       (click)="showEditDialogPosture(question)">
                    {{ question.reponse }}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--<div class="table-panel os-versions">
          <div class="panel-title">Sous questions</div>
          <div class="panel-body">
            <table>
              <thead>
              <tr>
                <th>
                  #
                </th>
                <th>
                  Sous question
                </th>
                <th>
                  Reponse
                </th>
                <th>
                  dernier edit
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let sQuestion of sQuestions">
                <td>#</td>
                <td><span>{{sQuestion.question}}</span></td>
                <td>{{sQuestion.reponse}}</td>
                <td>{{sQuestion.lastEdit}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>-->
      </div>
    </ng-container>
    <div>
      <input *ngIf="questionsGrouped&&questionsGrouped.length&&canFinalise()" type="button" (click)="finalisePlaybook()"
             class="form-control btn-success" value="Finaliser"
             style="width: 10%;height: 3rem;float: right;background-color: #30bd70;margin-right: 2rem">
    </div>
    <!--<div class="panel">
      <div class="table-panel countries">

      </div>
    </div>-->
  </main>
</div>
<!--<div style="display: none" id="parentDivToSwal">
    <div id="divToSwal">
        <label class="swal2-input-label">Commentaire historique</label>
        <table>
            <thead>
            <tr>
                <th class="normalTextTableTh">
                    Commentaire historique
                </th>
                <th class="normalTextTableTh">
                    Dernière modification (user)
                </th>
                <th class="normalTextTableTh">
                    Dernière date modification
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let question of responseArchives">
                <td class="normalTextTable">
                    {{question.reponse}}
                </td>
                <td class="normalTextTable">
                    {{question.lastUserEdit}}
                </td>
                <td class="normalTextTable">
                    {{question.lastTimeEdit}}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>-->
