import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, finalize, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {HttpQueueService} from '../../shared/services/httpQueueService.service';
import {HttpStatusService} from '../../shared/services/httpStatusService.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    tries = 3;

    constructor(private ngxUiLoaderService: NgxUiLoaderService,
                private httpClient: HttpClient,
                private httpStatusService: HttpStatusService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers;
        let randomId = Math.random();
        // headers = this.authHelper.addHeaderAuthorization(req.headers);
        this.ngxUiLoaderService.start(randomId.toString());
        const clonedRequest = req.clone({
            // Exclude the header you want to remove
            headers: req.headers.delete('Authorization'),
        });
        // Clone the request to add the new header
        // const clonedRequest = req.clone({headers}); null
        const disableAuthorization = false;
        // Pass the cloned request instead of the original request to the next handle
        this.tries = 3;
        return this.callApiIfAlone(next, disableAuthorization, clonedRequest, req, randomId);


        /*return next.handle(disableAuthorization ? clonedRequest : req)
          .pipe(
            map(event => {
              return event;
            }),
            catchError(error => {
              return observableThrowError(error);
            }),
            finalize(() => {
              setTimeout(() => {
                this.ngxUiLoaderService.stop(randomId.toString());
              }, 100);
            })
          );*/
    }

    private callApiIfAlone(next: HttpHandler, disableAuthorization: boolean, clonedRequest: HttpRequest<any>, req: HttpRequest<any>, randomId: number) {
        /*if (this.httpStatusService.isRequestNotPending()) {
          this.httpStatusService.incrementRequestCount();*/

        const observable = next.handle(disableAuthorization ? clonedRequest : req);
        return observable.pipe(
            map(event => {
                return event;
            }),
            catchError(error => {
                console.log('MyError', error);
                if (this.tries > 0) {
                    this.tries--;
                    return this.callApiIfAlone(next, disableAuthorization, clonedRequest, req, randomId);
                }else {
                  return observableThrowError(error);
                }
            }),
            finalize(() => {
                this.httpStatusService.decrementRequestCount();
                setTimeout(() => {
                    this.ngxUiLoaderService.stop(randomId.toString());
                }, 100);
            })
        );
        /*} else {
          setTimeout(() => this.callApiIfAlone(next, disableAuthorization, clonedRequest, req, randomId), 500);
        }*/
    }
}
