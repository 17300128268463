import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AccessDeniedComponent} from './access-denied/access-denied.component';
import {AuthGuard} from './auth/auth.guard';
import {ManagerComponent} from './manager/manager.component';
import {DashboardComponent} from './admin/dashboard/dashboard.component';
import {DemandePerformanceComponent} from './admin/demande-performance/demande-performance.component';
import {LeadBnComponent} from './admin/lead-bn/lead-bn.component';
import {DemandeNvxActifsComponent} from './admin/demande-nvx-actifs/demande-nvx-actifs.component';
import {ActifPhysiqueComponent} from './admin/actif-physique/actif-physique.component';
import {VueFournisseurComponent} from './admin/vue-founisseur/vue-fournisseur.component';
import {AdminQuestionsComponent} from './admin/admin-questions/admin-questions.component';
import {SuiviConnexionComponent} from './admin/suivi-connexion/suivi-connexion.component';
import {JointComponent} from './admin/joint/joint.component';
import {ServerDownComponent} from './server-down/server-down.component';
import {GestionLeadComponent} from './admin/gestion-lead/gestion-lead.component';
import {GestionChefDeptComponent} from './admin/gestion-chef-dept/gestion-chef-dept.component';
import {GestionListePlaybookComponent} from './admin/gestion-liste-playbook/gestion-liste-playbook.component';
import {ListeValidationComponent} from './admin/liste-validation/liste-validation.component';
import {RapportTiersComponent} from './admin/rapport-tiers/rapport-tiers.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {roles: ['view-profile']},
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: 'server-down',
    component: ServerDownComponent,
  },
  /*{
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: {roles: ['view-profile']},
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'demande-performance',
        component: DemandePerformanceComponent
      },
      {
        path: 'lead-bn',
        component: LeadBnComponent
      },
      {
        path: 'demande-nvx-actifs',
        component: DemandeNvxActifsComponent
      },
      {
        path: 'actif-physique',
        component: ActifPhysiqueComponent
      },
      {
        path: 'vue-fournisseur',
        component: VueFournisseurComponent
      },
      {
        path: 'questions-admin',
        component: AdminQuestionsComponent
      }, {
        path: 'suivi-connexion',
        component: SuiviConnexionComponent
      },
      {
        path: 'joint',
        component: JointComponent
      },
      /!*{
        path: 'home-page',
        component: AllowedHomePageComponent
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        canActivate: []
      }*!/
    ]
  },*/
  {
    path: 'manager',
    component: ManagerComponent,
    canActivate: [AuthGuard],
    data: {roles: ['view-profile']},
  },
  {
    path: 'dashboard',
    component: DashboardComponent/*,
    canActivate: [AuthGuard],
    data: {roles: ['view-profile']},*/
  },
  {
    path: 'demande-performance',
    component: DemandePerformanceComponent
  },
  {
    path: 'lead-bn',
    component: LeadBnComponent
  },
  {
    path: 'demande-nvx-actifs',
    component: DemandeNvxActifsComponent
  },
  {
    path: 'actif-physique',
    component: ActifPhysiqueComponent
  },
  {
    path: 'vue-fournisseur',
    component: VueFournisseurComponent
  },
  {
    path: 'user-admin',
    component: SuiviConnexionComponent
  },
  {
    path: 'questions-admin',
    component: AdminQuestionsComponent
  }, {
    path: 'gestion-lead',
    component: GestionLeadComponent
  },
  {
    path: 'gestion-chef-dept',
    component: GestionChefDeptComponent
  },{
    path: 'gestion-liste-playbook',
    component: GestionListePlaybookComponent
  },{
    path: 'liste-validation',
    component: ListeValidationComponent
  },{
    path: 'rapport-tiers',
    component: RapportTiersComponent
  },
  {
    path: 'joint',
    component: JointComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
