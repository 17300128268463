import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../../../environments/environment';
import {Utils} from '../../../core/utils/Utils';

@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() user: any;
  @Input() username: any;
  public topMenu: any[];
  public applogo: string;
  dropdownOpen = false;

  isSidebarFolded = false;

  @Output() toggleSidebar = new EventEmitter<boolean>();

  constructor(private router: Router,
              private keycloakService: KeycloakService) {
  }

  ngOnInit() {
    this.topMenu = [
      /*{
        'title': 'Suivi De Connexion',
        'action': 'suivi-connexion',
        'icon': 'fa-gear',
        'i18n': 'Suivi De Connexion',
        connected: true,
        'submenu': []
      }*/
    ];
    if (this.user && this.user['resource_access'] && this.user['resource_access']['nego_client']
      && this.user['resource_access']['nego_client']['roles'] && this.user['resource_access']['nego_client']['roles'].length
      && this.user['resource_access']['nego_client']['roles'].includes('NEGO_ADMIN')) {
      this.topMenu.push({
        'title': 'Gestion des questionnaires',
        'action': 'questions-admin',
        'icon': 'fa-gear',
        'i18n': 'Gestion des questionnaires',
        'disabled': false,
        'submenu': []
      });
    }
    this.applogo = 'environment.appLogo';
    if (!this.user) {
      this.user = {};
    }
    // this.user.avatar = 'this.user.avatar' || 'environment.defaultAvatar';
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => {
    this.isSidebarFolded = window.innerWidth <= 768;

    this.toggleSidebar.emit(this.isSidebarFolded);
  };

  public logout() {
    this.toggleDropdown();
    Utils.showPopUpNotif('Á bientot');
    setTimeout(res => {
      location.replace(environment.keycloak.issuer + '/realms/' + environment.keycloak.realm + '/protocol/openid-connect/logout');
    }, 1200);
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  doToggleSidebar() {
    this.isSidebarFolded = !this.isSidebarFolded;
    this.toggleSidebar.emit(this.isSidebarFolded);

  }

}
