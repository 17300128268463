<div class="page">
  <!--<app-stats></app-stats>-->
  <app-stats [isForDemandeNvxActifs]="true" [currentYear]="currentYear"
             [currentFournisseur]="currentFournisseur"></app-stats>
  <main>
    <div class="panel">
      <div class="table-panel countries">
        <div class="panel-title">Demande Nvx Actifs</div>
        <div class="panel-body">
          <div style="display: flex;overflow: scroll;">
            <div style="overflow: scroll;width: 100%;height: 63vh">
              <table class="table-responsive tableFixHead">
                <thead>
                <tr>
                  <th style="border-radius: 2rem 0 0;width: 4.5rem" class="thTableStyle">
                    ID
                  </th>
                  <th class="thTableStyle">
                    Actif
                  </th>
                  <th class="thTableStyle">
                    Nom Contrepartie
                  </th>
                  <th class="thTableStyle" style="width: 25rem">
                    Detail Contrepartie
                  </th>
                  <th class="thTableStyle" style="width: 8rem">
                    Cible
                  </th>
                  <th class="thTableStyle" style="width: 10rem">
                    Demande TX
                  </th>
                  <th class="thTableStyle" style="width: 8rem">
                    Demande acheteur
                  </th>
                  <th class="thTableStyle" style="border-radius: 0 2rem 0 0;width: 18rem">
                    Argument
                  </th>
                </tr>
                <tr>
                  <td style="width: 4.5rem" class="thTableStyle">
                  </td>
                  <td class="thTableStyle">
                    <select class="form-control" [(ngModel)]="actifSelected" (change)="actifSelectedChanged()">
                      <option value="">Selectionner un actif</option>
                      <option *ngFor="let actif of actifs" value="{{actif}}">{{actif}}</option>
                    </select>
                  </td>
                  <td class="thTableStyle">
                    <select class="form-control" [(ngModel)]="contrePartieSelected" (change)="contrePartieSelectedChanged()">
                      <option value="">Selectionner un contrepartie</option>
                      <option *ngFor="let contrePartie of contreParties" value="{{contrePartie}}">{{contrePartie}}</option>
                    </select>
                  </td>
                  <td class="thTableStyle" style="width: 25rem">
                  </td>
                  <td class="thTableStyle" style="width: 8rem">
                  </td>
                  <td class="thTableStyle" style="width: 5rem">
                  </td>
                  <td class="thTableStyle" style="width: 8rem">
                  </td>
                  <td class="thTableStyle" style="width: 18rem">
                  </td>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let demande of demandes;index as i">
                    <tr *ngIf="canShowActif(demande)" class="heightTdSameControls">
                        <td class="normalTextTable">
                            <input type="text" class="form-control" value="{{demande.id}}" disabled>
                        </td>
                        <td class="normalTextTable">
                            <input type="text" class="form-control" value="{{demande.actif}}" disabled>
                        </td>
                        <td class="normalTextTable">
                            <input type="text" class="form-control" value="{{demande.contrepartie}}" disabled>
                        </td>
                        <td class="normalTextTable">
                            <input type="text" class="form-control detailDemande" value="{{demande.detailContrepartie}}" disabled>
                        </td>
                        <td class="normalTextTable">
                            <input type="text" class="form-control" value="{{demande.cible}}" disabled>
                        </td>
                        <td class="normalTextTable">
                            <div class="form-check form-switch center-div-inside-td">
                                <input class="form-check-input" type="checkbox"
                                       (click)="changeDemande(demande)"
                                       [checked]="demande.demande=='x'">
                                <label class="form-check-label"></label>
                            </div>
                        </td>
                        <td class="normalTextTable">
                            <input type="text" class="form-control textCommentRose" [(ngModel)]="demande.cibleD" [disabled]="demande.demande!='x'" (change)="demande.changed=true">
                        </td>
                        <td class="normalTextTable">
                            <div class="cursor-pointer" [class.textCommentRose]="true"
                                 (click)="showEditDialogPosture(demande)">
                                {{demande.argument}}
                            </div>
                        </td>
                    </tr>

                  <!--<td>
                    <div class="borderDivIcon" style="background-color: #0bc326"
                         [class.disabledIcon]="yearControl<currentYear">
                      <i class='fas fa fa-edit iconInsideDiv'
                         (click)="showEditDialog(question)"></i>
                    </div>
                  </td>-->
                </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <button class="btn btn-primary flexAutoMarge"
                  style="color: white;background-color: #b22a2e;height: 3.3rem;float: right" (click)="saveDemandes()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </main>
</div>
