import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AttachmentService} from '../../shared/services/attachment.service';
import Swal from 'sweetalert2';
import {Utils} from '../../core/utils/Utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gestion-chef-dept',
  templateUrl: './gestion-chef-dept.component.html',
  styleUrls: ['./gestion-chef-dept.component.scss']
})
export class GestionChefDeptComponent implements OnInit {

  countries = [];
  years = [];
  osVersions = [];
  isAccepted: any = false;
  yearControl;
  rayonControl: any = '0';
  deptControl: any = '0';
  rayons = [];
  roles = [];
  depts = [];
  fournisseurControl: any = '0';
  fournisseurs = [];
  sousDept = [];
  questions = [];
  sQuestions = [];
  users = [];
  currentYear: number;
  username: string;
  searchFilter = '';
  userControl: any;
  user2Control: any;
  roleControl: any;
  isEdit = false;
  tryCount = 3;
  leads = [];

  constructor(private http: HttpClient,
              private router: Router,
              private attachmentService: AttachmentService) {
    if (!attachmentService.canConnect) {
      this.router.navigate(['access-denied']);
    }
    this.testRole();
  }

  testRole() {
    if (this.attachmentService.respReturned) {
      if (this.attachmentService.role != 'ADMIN') {
        this.router.navigate(['access-denied']);
      }
    } else {
      setTimeout(() => this.testRole(), 500);
    }
  }

  ngOnInit() {
    //this.user = JSON.parse(localStorage.getItem('userObject'));
    this.username = localStorage.getItem('username');
    this.chargeYears();
    //this.getFornisseurs();
    //this.getAllDeparts();
    this.getsousDept();
    this.getAllUsers();
    this.getlesRoles();
  }

  getFornisseurs() {
    this.attachmentService.getFornisseurs(this.username).subscribe(response => {
      setTimeout(() => this.getAllUsers(), 500);
      response.forEach(fournisseur => {
        this.fournisseurs.push(fournisseur.fournisseur);
      });
      this.fournisseurs.sort(frn => frn);
    }, error => {
      if (this.tryCount > 0) {
        this.tryCount--;
        this.getFornisseurs();
      }
    });
  }

  getsousDept(){
    this.sousDept.push('FOOD');
    this.sousDept.push('ALCOOL & LIQUIDE');
    this.sousDept.push('DPH');
    this.sousDept.push('APLS');
    this.sousDept.push('FLEG/TRAITE');
    this.sousDept.push('BOUCHERIE');
    this.sousDept.push('EPCS');
    this.sousDept.push('TEXTILE');
    this.sousDept.push('BAZAR');
  }

  getAllDeparts() {
    this.attachmentService.getAllDepts().subscribe(response => {
      setTimeout(() => this.getAllUsers(), 500);
      response.forEach(fournisseur => {
        this.fournisseurs.push(fournisseur);
      });
      this.fournisseurs.sort(frn => frn);
    });
  }

  isFormulaireValid() {
    return this.userControl && this.userControl != '' &&
      this.fournisseurControl &&
      this.yearControl;
  }

  yearChanged() {
    console.log(this.yearControl);
  }

  chargeYears() {
    this.currentYear = new Date().getFullYear();
    this.years.push('Niveau N+1');
    this.years.push('Niveau N+2');
    this.years.push('Niveau N+3');
  }

  isRayonValid() {
    return this.rayonControl != '0';
  }

  isFournisValid() {
    return this.fournisseurControl != '0';
  }

  isdeptValid() {
    return this.deptControl != '0';
  }

  rayonChanged() {
    this.questions = [];
    //this.getFornisseurs();
  }

  getQuestions() {
    /*this.questions.push({
      partie: 'partie1',
      question: 'question 1',
      reponse: 'response de se question',
      lastEdit: 'user1'
    });
    this.questions.push({
      partie: 'partie2',
      question: 'question 2',
      reponse: 'response de se question',
      lastEdit: 'user2'
    });
    this.questions.push({partie: 'partie3', question: 'question 3', reponse: '', lastEdit: ''});
    this.questions.push({partie: 'partie4', question: 'question 4', reponse: '', lastEdit: ''});*/
    this.questions = [];
    this.attachmentService.getComments(this.fournisseurControl).subscribe(response => {
      response.forEach(comment => {
        this.questions.push(comment);
      });
    });
  }

  getAllRayon(isAdmin) {
    this.rayonControl = [];
    this.rayons = [];
    this.attachmentService.getAllRayons().subscribe(response => {
      response.forEach(comment => {
        let rayon = {};
        rayon['label'] = comment;
        this.rayons.push(rayon);
      });
      if (isAdmin) {
        this.rayonControl = this.rayons;
      }
    });
  }

  getAllUsers() {
    this.attachmentService.getAllUsers().subscribe(response => {
      this.getAllLeads();
      if (response && response.length) {
        this.users = response.map(res => res.user);
      }
    });
  }

  getAllLeads() {
    this.attachmentService.getAllChefDepts().subscribe(response => {
      if (response && response.length) {
        this.leads = response;
        this.leads.forEach(lead => {
          lead.deptsTitle = lead.depts.slice(9, lead.depts.length);
          lead.achteursTitle = lead.achteurs.slice(9, lead.achteurs.length);
        });
      }
    });
  }

  addUser() {
    let user = {
      user: this.userControl,
      achteurs: this.user2Control,
      depts: this.fournisseurControl,
      niveau_validation: this.yearControl
    };
    this.attachmentService.addChefDept(user).subscribe(response => {
      this.getAllLeads();
      this.userControl = null;
      this.user2Control = null;
      this.fournisseurControl = null;
      this.yearControl = null;
      Utils.showPopUpNotif('Lead a été Ajouté.');
    });
  }

  editUser() {
    let user = {
      user: this.userControl,
      achteurs: this.user2Control,
      depts: this.fournisseurControl,
      niveau_validation: this.yearControl
    };
    this.attachmentService.addChefDept(user).subscribe(response => {
      this.getAllLeads();
      this.isEdit = false;
      this.userControl = null;
      this.user2Control = null;
      this.fournisseurControl = null;
      this.yearControl = null;
      Utils.showPopUpNotif('Lead a été Modifié.');
    });
  }

  getLeadFiltered() {
    if (this.searchFilter && this.searchFilter != '') {
      return this.leads.filter(usr => usr.user.toUpperCase().includes(this.searchFilter.toUpperCase())
        || usr.fournisseurs.map(fr => fr.toUpperCase()).join('').includes(this.searchFilter.toUpperCase()));
    } else {
      return this.leads;
    }
  }

  editDisable(user: any) {
    if (user.disable == 'oui') {
      user.disable = 'non';
    } else {
      user.disable = 'oui';
    }
    this.attachmentService.editUserDisable(user.user, user.disable).subscribe(response => {
      Utils.showPopUpNotif('Édité avec succès');
    });
  }

  private getlesRoles() {
    let roles = [];
    let role = {};
    role['label'] = 'ADMIN';
    roles.push(role);
    role = {};
    role['label'] = 'ACHAT';
    roles.push(role);
    this.roles = roles;
  }

  roleChanged() {
    if (this.roleControl && this.roleControl.label && this.roleControl.label != '') {
      if (this.roleControl.label == 'ADMIN') {
        this.getAllRayon(true);
      } else if (this.roleControl.label == 'ACHAT') {
        this.getAllRayon(false);
      }
    }
  }

  chargeUser(user: any) {
    this.userControl = user.user;
    this.fournisseurControl = user.depts;
    this.user2Control = user.achteurs;
    this.yearControl = user.niveau_validation;
  }

  getTitle(fournisseurs: any) {
    return fournisseurs.splice(0, 9).join();
  }
}
