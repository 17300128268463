<div class="page">
    <!--<app-stats></app-stats>-->
    <app-stats [isForQuestionAdmin]="true"></app-stats>
    <main>
        <div class="panel" style="display: flex;">
            <div class="table-panel countries">
                <div style="display: flex;justify-content: space-between;">
                    <div class="panel-title">Les parties</div>
                    <div class="borderDivIcon" style="background-color: #1eabff;margin-right: 1rem;">
                        <i class='fas fa fa-plus iconInsideDiv'
                           (click)="addPartieClicked()"></i>
                    </div>
                </div>
                <div class="panel-body">
                    <table>
                        <thead>
                        <tr>
                            <th style="text-align: center">
                                Partie
                            </th>
                            <th style="text-align: center">
                                Dernière modification
                            </th>
                            <th style="text-align: center">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let partie of parties" (click)="selectPartie(partie)" class="cursor-pointer"
                            [class.colorTr]="partie.selected">
                            <td>
                                {{partie.partie}}
                            </td>
                            <td width="30%" style="text-align: center">
                                {{partie.lastEdit}}
                            </td>
                            <td style="text-align: center;display: flex;justify-content: center;">
                                <div class="borderDivIcon" style="background-color: #20aa34">
                                    <i class='fas fa fa-edit iconInsideDiv' (click)="editPartieClicked(partie)"></i>
                                </div>
                                <div class="borderDivIcon" style="background-color: #b22a2e">
                                    <i class='fas fa fa-remove iconInsideDiv' (click)="deletePartieClicked(partie)"></i>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="table-panel countries">
                <div style="display: flex;justify-content: space-between;">
                    <div class="panel-title">Les questionnaires</div>
                    <div class="borderDivIcon" style="background-color: #1eabff;margin-right: 1rem;"
                         [class.disableAdd]="!isPartieSelected()">
                        <i class='fas fa fa-plus iconInsideDiv'
                           (click)="addQuestionnaireClicked()"></i>
                    </div>
                </div>
                <div class="panel-body">
                    <table>
                        <thead>
                        <tr>
                            <th style="text-align: center">
                                Questionnaire
                            </th>
                            <th style="text-align: center">
                                Dernière modification
                            </th>
                            <th style="text-align: center">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let question of questions">
                            <td>
                                {{question.question}}
                            </td>
                            <td width="30%" style="text-align: center">
                                {{question.lastEdit}}
                            </td>
                            <td style="text-align: center;display: flex;justify-content: center;">
                                <div class="borderDivIcon" style="background-color: #20aa34">
                                    <i class='fas fa fa-edit iconInsideDiv'
                                       (click)="editQuestionnaireClicked(question)"></i>
                                </div>
                                <div class="borderDivIcon" style="background-color: #b22a2e">
                                    <i class='fas fa fa-remove iconInsideDiv'
                                       (click)="deleteQuestionnaireClicked(question)"></i>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!--<div class="table-panel os-versions">
              <div class="panel-title">Sous questions</div>
              <div class="panel-body">
                <table>
                  <thead>
                  <tr>
                    <th>
                      #
                    </th>
                    <th>
                      Sous question
                    </th>
                    <th>
                      Reponse
                    </th>
                    <th>
                      dernier edit
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let sQuestion of sQuestions">
                    <td>#</td>
                    <td><span>{{sQuestion.question}}</span></td>
                    <td>{{sQuestion.reponse}}</td>
                    <td>{{sQuestion.lastEdit}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>-->
        </div>
    </main>
</div>
