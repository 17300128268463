<style>
    body {
        text-align: center;
        padding: 150px;
    }

    h1 {
        font-size: 50px;
    }

    body {
        font: 20px Helvetica, sans-serif;
        color: #333;
    }

    article {
        display: block;
        text-align: left;
        width: 650px;
        margin: 0 auto;
    }

    a {
        color: #dc8100;
        text-decoration: none;
    }

    a:hover {
        color: #333;
        text-decoration: none;
    }
</style>
<div style="height: 100%;display: flex;">
    <article style="align-self: center;">
        <h1>We&rsquo;ll be back soon!</h1>
        <div>
            <p>Désolé pour le dérangement mais nous effectuons une maintenance en ce moment. Si vous avez besoin, vous
                pouvez toujours nous contacter, sinon nous serons de retour en ligne sous peu!</p>
            <p>&mdash; The Team</p>
        </div>
    </article>
</div>

