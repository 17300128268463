import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AttachmentService} from '../../shared/services/attachment.service';
import Swal from 'sweetalert2';
import {Utils} from '../../core/utils/Utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gestion-liste-playbook',
  templateUrl: './gestion-liste-playbook.component.html',
  styleUrls: ['./gestion-liste-playbook.component.scss']
})
export class GestionListePlaybookComponent implements OnInit {

  countries = [];
  years = [];
  osVersions = [];
  isAccepted: any = false;
  yearControl;
  rayonControl: any = '0';
  deptControl: any = '0';
  rayons = [];
  roles = [];
  depts = [];
  fournisseurControl;
  fournisseurs = [];
  questions = [];
  sQuestions = [];
  users = [];
  currentYear: number;
  username: string;
  searchFilter = '';
  userControl: any;
  roleControl: any;
  isEdit = false;
  tryCount = 3;
  leads = [];
  dateFinFinalisation: any;
  dateFinValidation: any;
  tierControl: any;
  tiers: any = [];
  niveaux = [];
  niveauControl: any;

  constructor(private http: HttpClient,
              private router: Router,
              private attachmentService: AttachmentService) {
    if (!attachmentService.canConnect) {
      this.router.navigate(['access-denied']);
    }
    this.testRole();
  }

  testRole() {
    if (this.attachmentService.respReturned) {
      if (this.attachmentService.role != 'ADMIN') {
        this.router.navigate(['access-denied']);
      }
    } else {
      setTimeout(() => this.testRole(), 500);
    }
  }

  ngOnInit() {
    //this.user = JSON.parse(localStorage.getItem('userObject'));
    this.username = localStorage.getItem('username');
    this.chargeYears();
    this.chargeTiers();
    this.chargeNiveaux();
    this.getFornisseurs();
    //this.getAllDeparts();
    this.getlesRoles();
  }

  getFornisseurs() {
    this.attachmentService.getFornisseurs(this.username).subscribe(response => {
      setTimeout(() => this.getAllUsers(), 500);
      response.forEach(fournisseur => {
        this.fournisseurs.push(fournisseur.fournisseur);
      });
      this.fournisseurs.sort(frn => frn);
    }, error => {
      if (this.tryCount > 0) {
        this.tryCount--;
        this.getFornisseurs();
      }
    });
  }

  getAllDeparts() {
    this.attachmentService.getAllDepts().subscribe(response => {
      setTimeout(() => this.getAllUsers(), 500);
      response.forEach(fournisseur => {
        this.fournisseurs.push(fournisseur);
      });
      this.fournisseurs.sort(frn => frn);
    });
  }

  isFormulaireValid() {
    return this.userControl && this.userControl != '' &&
      this.fournisseurControl &&
      this.yearControl;
  }

  yearChanged() {
    console.log(this.yearControl);
  }

  chargeYears() {
    this.currentYear = new Date().getFullYear();
    for (let i = 2021; i < this.currentYear + 2; i++) {
      this.years.push(i);
    }
  }

  chargeNiveaux() {
    this.niveaux.push('Niveau N+1');
    this.niveaux.push('Niveau N+2');
    this.niveaux.push('Niveau N+3');
  }

  chargeTiers() {
    this.tiers.push('Tier 1');
    this.tiers.push('Tier 2');
    this.tiers.push('Tier 3');
  }

  getAllRayon(isAdmin) {
    this.rayonControl = [];
    this.rayons = [];
    this.attachmentService.getAllRayons().subscribe(response => {
      response.forEach(comment => {
        let rayon = {};
        rayon['label'] = comment;
        this.rayons.push(rayon);
      });
      if (isAdmin) {
        this.rayonControl = this.rayons;
      }
    });
  }

  getAllUsers() {
    this.attachmentService.getAllUsers().subscribe(response => {
      this.getAllLeads();
      if (response && response.length) {
        this.users = response.map(res => res.user);
      }
    });
  }

  getAllLeads() {
    this.attachmentService.getAllListePlaybook().subscribe(response => {
      if (response && response.length) {
        this.leads = response;
        this.leads.forEach(lead => {
          lead.sous_deptsTitle = lead.sous_depts.slice(9, lead.sous_depts.length);
          lead.achteursTitle = lead.achteurs.slice(9, lead.achteurs.length);
        });
      }
    });
  }

  addUser() {
    this.addMonths();
    let user = {
      fournisseur: this.fournisseurControl,
      annee: this.yearControl,
      responsable: this.userControl,
      date_fin_finalisation: this.dateFinFinalisation,
      date_fin_validation: this.dateFinValidation,
      tier: this.tierControl,
      niveau_validation: this.niveauControl
    };
    this.attachmentService.addListePlaybook(user).subscribe(response => {
      this.getAllLeads();
      this.userControl = null;
      this.fournisseurControl = null;
      this.niveauControl = null;
      this.dateFinFinalisation = null;
      this.dateFinValidation = null;
      this.tierControl = null;
      Utils.showPopUpNotif('Lead a été Ajouté.');
    });
  }

  editUser() {
    this.addMonths();
    let user = {
      fournisseur: this.fournisseurControl,
      annee: this.yearControl,
      responsable: this.userControl,
      date_fin_finalisation: this.dateFinFinalisation,
      date_fin_validation: this.dateFinValidation,
      tier: this.tierControl,
      niveau_validation: this.niveauControl
    };
    this.attachmentService.addListePlaybook(user).subscribe(response => {
      this.getAllLeads();
      this.isEdit = false;
      this.userControl = null;
      this.fournisseurControl = null;
      this.niveauControl = null;
      this.dateFinFinalisation = null;
      this.dateFinValidation = null;
      this.tierControl = null;
      Utils.showPopUpNotif('Lead a été Modifié.');
    });
  }

  private addMonths() {
    let dateNow = new Date();
    if (!this.dateFinFinalisation) {
      dateNow.setMonth(dateNow.getMonth() + 2);
      this.dateFinFinalisation = this.getDateFormated(dateNow);
    }
    if (!this.dateFinValidation) {
      dateNow.setMonth(dateNow.getMonth() + 3);
      this.dateFinValidation = this.getDateFormated(dateNow);
    }
  }

  private getDateFormated(dateNow: Date) {
    return dateNow.getFullYear() + '-' +
      ((dateNow.getMonth() + 1).toString().length == 2 ? (dateNow.getMonth() + 1) : '0' + (dateNow.getMonth() + 1)) + '-' +
      (dateNow.getDate().toString().length == 2 ? dateNow.getDate() : '0' + dateNow.getDate());
  }

  getLeadFiltered() {
    if (this.searchFilter && this.searchFilter != '') {
      return this.leads.filter(usr => usr.user.toUpperCase().includes(this.searchFilter.toUpperCase())
        || usr.fournisseurs.map(fr => fr.toUpperCase()).join('').includes(this.searchFilter.toUpperCase()));
    } else {
      return this.leads;
    }
  }

  editDisable(user: any) {
    if (user.disable == 'oui') {
      user.disable = 'non';
    } else {
      user.disable = 'oui';
    }
    this.attachmentService.editUserDisable(user.user, user.disable).subscribe(response => {
      Utils.showPopUpNotif('Édité avec succès');
    });
  }

  private getlesRoles() {
    let roles = [];
    let role = {};
    role['label'] = 'ADMIN';
    roles.push(role);
    role = {};
    role['label'] = 'ACHAT';
    roles.push(role);
    this.roles = roles;
  }

  roleChanged() {
    if (this.roleControl && this.roleControl.label && this.roleControl.label != '') {
      if (this.roleControl.label == 'ADMIN') {
        this.getAllRayon(true);
      } else if (this.roleControl.label == 'ACHAT') {
        this.getAllRayon(false);
      }
    }
  }

  chargeUser(user: any) {
    this.fournisseurControl = user.fournisseur;
    this.yearControl = user.annee;
    this.userControl = user.responsable;
    this.dateFinFinalisation = user.date_fin_finalisation;
    this.dateFinValidation = user.date_fin_validation;
    this.tierControl = user.tier;
    this.niveauControl = user.niveau_validation;
  }

  getTitle(fournisseurs: any) {
    return fournisseurs.splice(0, 9).join();
  }

  ifCanAddMargin(achteurs, i: number) {
    return achteurs[i] < achteurs[i - 1];
  }
}
