<div class="page">
  <!--<app-stats></app-stats>-->
  <app-stats [isForDemandePerformance]="true" [currentYear]="currentYear"
             [currentFournisseur]="currentFournisseur"></app-stats>
  <main>
    <div class="panel">
      <div class="table-panel countries">
        <div style="display: flex">
          <div class="panel-title" style="width: 80%">Demande de performance</div>
          <div class="form-check form-switch center-div-inside-td" style="width: 13rem"
               *ngIf="demandes&&demandes.length">
            <input type="checkbox" class="form-check-input" [(ngModel)]="isfamille" (click)="clickFamille()">
            <label class="form-check-label">Famille</label>
          </div>
          <div class="form-check form-switch center-div-inside-td" style="width: 13rem"
               *ngIf="demandes&&demandes.length">
            <input type="checkbox" class="form-check-input" [(ngModel)]="issfamille" (click)="clickSFamille()">
            <label class="form-check-label">Sous-Famille</label>
          </div>

        </div>
        <div class="panel-body">
          <div style="display: flex;overflow: auto;max-height: 60vh;width: 200%" class="widthDivResp21">
            <div>
              <table class="table-responsive tableFixHead">
                <thead>
                <tr>
                  <th style="border-radius: 2rem 0 0;" class="thTableStyle">
                    Departement
                  </th>
                  <th class="thTableStyle">
                    Rayon
                  </th>
                  <th class="thTableStyle" *ngIf="isfamille">
                    Famille
                  </th>
                  <th class="thTableStyle" *ngIf="issfamille">
                    Sous-Famille
                  </th>
                  <th class="thTableStyle" style="width: 144px">
                    Demande croissance<br> TX
                  </th>
                  <th class="thTableStyle" style="width: 140px">
                    Demande Marge<br> TX
                  </th>
                  <th class="thTableStyle" style="border-radius: 0 2rem 0 0;width: 9rem;">
                    Argument
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let demande of demandes" class="heightTdSameControls">
                  <td class="normalTextTable">
                    <input type="text" disabled class="form-control" value="{{demande.departemant}}">
                  </td>
                  <td class="normalTextTable">
                    <input type="text" disabled class="form-control" value="{{demande.rayon}}">
                  </td>
                  <td class="normalTextTable" *ngIf="isfamille">
                    <input type="text" disabled class="form-control" value="{{demande.famille}}">
                  </td>
                  <td class="normalTextTable" *ngIf="issfamille">
                    <input type="text" disabled class="form-control" value="{{demande.sousFamille}}">
                  </td>
                  <td class="normalTextTable">
                    <input #dctControl type="number" min="0" class="form-control textCommentRose text-center"
                           [(ngModel)]="demande.dct"
                           (ngModelChange)="changeDCT(demande)"
                           (focusout)="dctControl.type='text'"
                           (focusin)="dctControl.type='number'"
                           (change)="demandeChanged(demande)">
                  </td>
                  <td class="normalTextTable">
                    <input type="number" min="0" class="form-control textCommentRose text-center"
                           [(ngModel)]="demande.dmt"
                           (ngModelChange)="changeDMT(demande)"
                           (change)="demandeChanged(demande)">
                  </td>
                  <td class="normalTextTable">
                    <div class="cursor-pointer" style="min-height: 2.2rem;font-size: 14px;font-family: auto;font-weight: 400;width: 200px" [class.textCommentRose]="true"
                         (click)="showEditDialogPosture(demande)">
                      {{demande.argument}}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="normalTextTable heightTdSameControls colorTd"></td>
                  <td class="normalTextTable heightTdSameControls colorTd"></td>
                  <td class="normalTextTable heightTdSameControls colorTd" *ngIf="isfamille"></td>
                  <td class="normalTextTable heightTdSameControls colorTd" *ngIf="issfamille"></td>
                  <td class="normalTextTable">
                      <input type="text" min="0" disabled class="form-control textCommentRose text-center"
                             [value]="getTwoNumbersAfterVirgule(totalDCT)">
                  </td>
                  <td class="normalTextTable">
                      <input type="text" min="0" disabled class="form-control textCommentRose text-center"
                             [value]="getTwoNumbersAfterVirgule(totalDMT)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd"></td>
                </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table class="table-responsive tableFixHead" style="margin-left: 1rem">
                <thead>
                <tr>
                  <th style="border-radius: 2rem 0 0;width: 10rem" class="thTableStyle">
                    CA Achat
                  </th>
                  <!--<th class="thTableStyle">
                    TX Marge Arr<br> (Historique)
                  </th>-->
                  <th class="thTableStyle">
                    Demande croissance<br> en valeur
                  </th>
                  <th class="thTableStyle">
                    Demande marge<br> en valeur
                  </th>
                  <th class="thTableStyle">
                    Demande totale<br> en valeur
                  </th>
                  <th class="thTableStyle" style="border-radius: 0 2rem 0 0;">
                    Demande totale<br> en taux
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let demande of demandes">
                  <td class="normalTextTable">
                    <input *ngIf="demande.controlCA" type="number" min="0" class="form-control" [(ngModel)]="demande.ca" (focusout)="demande.controlCA=false" disabled
                           (ngModelChange)="changeCA(demande)" (change)="demandeChanged(demande)">
                    <input *ngIf="!demande.controlCA" type="text" class="form-control" [value]="getTwoNumbersAfterVirguleRemoveTwoZero(demande.ca)" (focusin)="demande.controlCA=true" disabled>
                  </td>
                 <!-- <td class="normalTextTable">
                    <input type="number" min="0" class="form-control" [(ngModel)]="demande.tm"
                           (change)="demandeChanged(demande)">
                  </td>-->
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersReal(demande.dcv)">
                    <!--<div class="input-group presuffix">
                      <span class="input-group-addon prefix">Prefix</span>
                      <input class="inputPS" type="text" name="input">
                      <span class="input-group-addon suffix">Suffix</span>
                    </div>-->
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersReal(demande.dmv)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersReal(demande.dtv)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersAfterVirgule(demande.dtt)">
                  </td>
                </tr>
                <tr>
                  <td class="normalTextTable">
                      <input type="text" min="0" disabled class="form-control"
                             [value]="getTwoNumbersReal(totalCA)">
                  </td>
                  <!--<td class="normalTextTable">
                      <input type="text" min="0" disabled class="form-control"
                             [value]="getTwoNumbersAfterVirgule(totalTM)">
                  </td>-->
                  <td class="normalTextTable heightTdSameControls colorTd">
                      <input type="text" min="0" disabled class="form-control"
                             [value]="getTwoNumbersReal(totalDCV)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                      <input type="text" min="0" disabled class="form-control"
                             [value]="getTwoNumbersReal(totalDMV)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                      <input type="text" min="0" disabled class="form-control"
                             [value]="getTwoNumbersReal(totalDTV)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                      <input type="text" min="0" disabled class="form-control"
                             [value]="getTwoNumbersAfterVirgule(totalDTT)">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <button class="btn btn-primary flexAutoMarge"
                  style="color: white;background-color: #b22a2e;height: 3.3rem;float: right" (click)="saveDemandes()">
            Enregistrer
          </button>
          <!--<div class="mt-1" style="display: flex;flex-direction: row-reverse;align-items: center">
            <div style="width: 40%">
              <table class="table-responsive" style="margin-left: 1rem">
                <thead>
                <tr>
                  <th style="border-radius: 2rem 0 0;" class="thTableStyle">
                    Demande croissance en valeur
                  </th>
                  <th class="thTableStyle">
                    Demande marge en valeur
                  </th>
                  <th class="thTableStyle">
                    Demande totale en valeur
                  </th>
                  <th class="thTableStyle" style="border-radius: 0 2rem 0 0;">
                    Demande totale en taux
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <div style="border-right: 1px solid black;">{{'hhhhh'}}</div>
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <div style="border-right: 1px solid black;">{{'dsafisdhg'}}</div>
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <div style="border-right: 1px solid black;">{{'hhkjioqfnoqwehhh'}}</div>
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <div
                      style="background-color: #f7eeef;margin: 1rem;padding: 0.2rem;border-radius: 6px;">{{'15.1%'}}</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div style="margin-right: 2rem">
              <table class="table-responsive" style="margin-left: 1rem">
                <thead>
                <tr>
                  <th style="border-radius: 2rem 2rem 0 0;width: 10rem" class="thTableStyle">
                    CA Achat
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    {{'ffff'}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>-->
        </div>
        <div class="panel-body familleDemandes" *ngIf="issfamille">
          <div class="panel-title" style="width: 80%;font-weight: 400">Vue Famille</div>
          <div style="display: flex;overflow: auto;max-height: 60vh;width: 200%;" class="widthDivResp21">
            <div>
              <table class="table-responsive tableFixHead" style="pointer-events: none">
                <thead>
                <tr>
                  <th style="border-radius: 2rem 0 0;" class="thTableStyle">
                    Departement
                  </th>
                  <th class="thTableStyle">
                    Rayon
                  </th>
                  <th class="thTableStyle">
                    Famille
                  </th>
                  <th class="thTableStyle" *ngIf="issfamille">
                    Sous-Famille
                  </th>
                  <th class="thTableStyle" style="width: 144px">
                    Demande croissance<br> TX
                  </th>
                  <th class="thTableStyle" style="width: 140px">
                    Demande Marge<br> TX
                  </th>
                  <th class="thTableStyle" style="border-radius: 0 2rem 0 0;width: 9rem;">
                    Argument
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let demande of demandesFamille" class="heightTdSameControls">
                  <td class="normalTextTable">
                    <input type="text" disabled class="form-control" value="{{demande.departemant}}">
                  </td>
                  <td class="normalTextTable">
                    <input type="text" disabled class="form-control" value="{{demande.rayon}}">
                  </td>
                  <td class="normalTextTable">
                    <input type="text" disabled class="form-control" value="{{demande.famille}}">
                  </td>
                  <td class="normalTextTable" *ngIf="issfamille">
                    <input type="text" disabled class="form-control moreGray" value="{{demande.sousFamille}}">
                  </td>
                  <td class="normalTextTable">
                    <input type="number" min="0" disabled class="form-control" [(ngModel)]="demande.dct"
                           (ngModelChange)="changeDCT(demande)">
                  </td>
                  <td class="normalTextTable">
                    <input type="number" min="0" disabled class="form-control" [(ngModel)]="demande.dmt"
                           (ngModelChange)="changeDMT(demande)">
                  </td>
                  <td class="normalTextTable">
                    <div class="cursor-pointer" style="min-height: 2.2rem;font-size: 14px;font-family: auto;font-weight: 400;width: 200px" [class.textCommentRose]="true"
                         (click)="showEditDialogPosture(demande)">
                      {{demande.argument}}
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table class="table-responsive tableFixHead" style="margin-left: 1rem;pointer-events: none">
                <thead>
                <tr>
                  <th style="border-radius: 2rem 0 0;width: 10rem" class="thTableStyle">
                    CA Achat
                  </th>
                  <!--<th class="thTableStyle">
                    TX Marge Arr<br> (Historique)
                  </th>-->
                  <th class="thTableStyle">
                    Demande croissance<br> en valeur
                  </th>
                  <th class="thTableStyle">
                    Demande marge<br> en valeur
                  </th>
                  <th class="thTableStyle">
                    Demande totale<br> en valeur
                  </th>
                  <th class="thTableStyle" style="border-radius: 0 2rem 0 0;">
                    Demande totale<br> en taux
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let demande of demandesFamille">
                  <td class="normalTextTable">
                    <input type="number" min="0" disabled class="form-control" [(ngModel)]="demande.ca"
                           (ngModelChange)="changeCA(demande)">
                  </td>
                  <!--<td class="normalTextTable">
                    <input type="number" min="0" disabled class="form-control" [(ngModel)]="demande.tm">
                  </td>-->
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersReal(demande.dcv)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersReal(demande.dmv)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersReal(demande.dtv)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersAfterVirgule(demande.dtt)">
                  </td>
                </tr>
                <!--<tr>
                  <td class="normalTextTable">
                    <div style="background-color: #f7eeef;border-radius: 1rem 0 0 1rem;padding: 1rem 0;">
                      <input type="number" disabled class="form-control m-auto" value="{{totalCA}}">
                    </div>
                  </td>
                  <td class="normalTextTable">
                    <div style="background-color: #f7eeef;padding: 1rem 0;">
                      <input type="number" disabled class="form-control m-auto" value="{{totalTM}}">
                    </div>
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <div style="background-color: #f7eeef;padding: 1rem 0;">
                      <input type="number" disabled class="form-control m-auto" value="{{totalDCV}}">
                    </div>
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <div style="background-color: #f7eeef;padding: 1rem 0;">
                      <input type="number" disabled class="form-control m-auto" value="{{totalDMV}}">
                    </div>
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <div style="background-color: #f7eeef;padding: 1rem 0;">
                      <input type="number" disabled class="form-control m-auto" value="{{totalDTV}}">
                    </div>
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <div style="background-color: #f7eeef;border-radius: 0 1rem 1rem 0;padding: 1rem 0;">
                      <input type="number" disabled class="form-control m-auto" value="{{totalDTT}}">
                    </div>
                  </td>
                </tr>-->
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="panel-body rayonsDemandes" *ngIf="isfamille">
          <div class="panel-title" style="width: 80%;font-weight: 400">Vue Rayon</div>
          <div style="display: flex;overflow: auto;max-height: 60vh;width: 200%" class="widthDivResp21">
            <div>
              <table class="table-responsive tableFixHead" style="pointer-events: none">
                <thead>
                <tr>
                  <th style="border-radius: 2rem 0 0;" class="thTableStyle">
                    Departement
                  </th>
                  <th class="thTableStyle">
                    Rayon
                  </th>
                  <th class="thTableStyle" *ngIf="isfamille">
                    Famille
                  </th>
                  <th class="thTableStyle" *ngIf="issfamille">
                    Sous-Famille
                  </th>
                  <th class="thTableStyle" style="width: 144px">
                    Demande croissance<br> TX
                  </th>
                  <th class="thTableStyle" style="width: 140px">
                    Demande Marge<br> TX
                  </th>
                  <th class="thTableStyle" style="border-radius: 0 2rem 0 0;width: 9rem;">
                    Argument
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let demande of demandesRayon" class="heightTdSameControls">
                  <td class="normalTextTable">
                    <input type="text" disabled class="form-control" value="{{demande.departemant}}">
                  </td>
                  <td class="normalTextTable">
                    <input type="text" disabled class="form-control" value="{{demande.rayon}}">
                  </td>
                  <td class="normalTextTable" *ngIf="isfamille">
                    <input type="text" disabled class="form-control moreGray" value="{{demande.famille}}">
                  </td>
                  <td class="normalTextTable" *ngIf="issfamille">
                    <input type="text" disabled class="form-control moreGray" value="{{demande.sousFamille}}">
                  </td>
                  <td class="normalTextTable">
                    <input type="number" min="0" disabled class="form-control" [(ngModel)]="demande.dct"
                           (ngModelChange)="changeDCT(demande)">
                  </td>
                  <td class="normalTextTable">
                    <input type="number" min="0" disabled class="form-control" [(ngModel)]="demande.dmt"
                           (ngModelChange)="changeDMT(demande)">
                  </td>
                  <td class="normalTextTable">
                    <div class="cursor-pointer" style="min-height: 2.2rem;font-size: 14px;font-family: auto;font-weight: 400;width: 200px" [class.textCommentRose]="true"
                         (click)="showEditDialogPosture(demande)">
                      {{demande.argument}}
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div>
              <table class="table-responsive tableFixHead" style="margin-left: 1rem;pointer-events: none">
                <thead>
                <tr>
                  <th style="border-radius: 2rem 0 0;width: 10rem" class="thTableStyle">
                    CA Achat
                  </th>
                  <!--<th class="thTableStyle">
                    TX Marge Arr<br> (Historique)
                  </th>-->
                  <th class="thTableStyle">
                    Demande croissance<br> en valeur
                  </th>
                  <th class="thTableStyle">
                    Demande marge<br> en valeur
                  </th>
                  <th class="thTableStyle">
                    Demande totale<br> en valeur
                  </th>
                  <th class="thTableStyle" style="border-radius: 0 2rem 0 0;">
                    Demande totale<br> en taux
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let demande of demandesRayon">
                  <td class="normalTextTable">
                    <input type="number" min="0" disabled class="form-control" [(ngModel)]="demande.ca"
                           (ngModelChange)="changeCA(demande)">
                  </td>
                  <!--<td class="normalTextTable">
                    <input type="number" min="0" disabled class="form-control" [(ngModel)]="demande.tm">
                  </td>-->
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersReal(demande.dcv)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersReal(demande.dmv)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersReal(demande.dtv)">
                  </td>
                  <td class="normalTextTable heightTdSameControls colorTd">
                    <input type="text" disabled class="form-control" [value]="getTwoNumbersAfterVirgule(demande.dtt)">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
