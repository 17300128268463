import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AttachmentService} from '../../shared/services/attachment.service';
import Swal from 'sweetalert2';
import {DemandeModel} from '../../core/models/demande.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-actif-physique',
  templateUrl: './actif-physique.component.html',
  styleUrls: ['./actif-physique.component.scss']
})
export class ActifPhysiqueComponent implements OnInit {

  countries = [];
  years = [];
  osVersions = [];
  isAccepted: any = false;
  yearControl: any = '0';
  rayonControl: any = '0';
  deptControl: any = '0';
  rayons = [];
  depts = [];
  fournisseurControl: any = '0';
  fournisseurs = [];
  questions = [];
  demandes: DemandeModel[] = [];
  sQuestions = [];
  user: any;
  currentYear: string;
  currentFournisseur: string;
  cible: any;
  ratio: any;
  demande: any;
  types: any = [];
  moments: any = [];
  clusters: any = [];
  matriceCalcul: any[] = [];
  currentDept: string;
  currentRayon: string;
  nbMagasin: any[] = [];
  hideCible: boolean = false;
  hideDemande: boolean = false;
  hideRatio: boolean = false;
  username: string;

  constructor(private http: HttpClient,
              private router: Router,
              private attachmentService: AttachmentService) {
    if (!attachmentService.canConnect) {
      this.router.navigate(['access-denied']);
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userObject'));
    this.username = localStorage.getItem('username');
    this.currentYear = localStorage.getItem('year');
    this.currentFournisseur = localStorage.getItem('fournisseur');
    this.currentDept = localStorage.getItem('dept');
    this.currentRayon = localStorage.getItem('rayon');
    setTimeout(res => this.attachmentService.getDemandeActifPhysique(this.currentFournisseur, this.currentYear).subscribe(response => {
      this.cible = response.cible;
      this.demande = response.demande;
      this.ratio = response.ratio;
      this.getDepts();

    }), 1000);
    /*this.fetchCountries();
    this.fetchOsVersions();*/
  }

  getDepts() {
    this.depts = [];
    this.attachmentService.getDepts(this.currentFournisseur, this.username).subscribe(response => {
      this.getTypes();

      response.forEach(dept => {
        this.depts.push(dept);
      });
    });
  }

  getTypes() {
    this.attachmentService.getTypes().subscribe(response => {
      this.getMoments();

      this.types = response;
    });
  }

  getMoments() {
    this.attachmentService.getMoments().subscribe(response => {
      this.getClusters();

      this.moments = response;
    });
  }

  getClusters() {
    this.attachmentService.getClusters().subscribe(response => {
      this.clusters = response;
      this.getNbMagasin();
    });
  }

  getMatriceCalcul() {
    this.attachmentService.getMatriceCalcul(this.currentYear).subscribe(response => {
      this.matriceCalcul = response;
      this.getDemandes();
    });
  }

  getNbMagasin() {
    this.attachmentService.getNbMagasin(this.currentYear).subscribe(response => {
      this.nbMagasin = response;
      this.getMatriceCalcul();
    }, error => {
      this.getNbMagasin();
    });
  }

  fetchCountries() {
    this.http.get<any>('../../../../assets/data/mock/countries.json').subscribe(res => {
      this.countries = res;
    });
  }

  getFornisseurs() {
    this.attachmentService.getFornisseurs(this.rayonControl).subscribe(response => {
      response.forEach(fournisseur => {
        this.fournisseurs.push(fournisseur.fournisseur);
      });
    });
  }

  fetchOsVersions() {
    this.http.get<any>('../../../../assets/data/mock/os-versions.json').subscribe(res => {
      this.osVersions = res;
    });
  }

  isFormulaireValid() {
    if (!this.isRayonValid()) {
      return false;
    } else if (this.yearControl == '0') {
      return false;
    } else {
      return this.fournisseurControl != '0';
    }
  }

  isRayonValid() {
    return this.rayonControl != '0';
  }

  isFournisValid() {
    return this.fournisseurControl != '0';
  }

  isdeptValid(demande: any) {
    return demande.dept && demande.dept != '0';
  }

  rayonChanged() {
    //this.getFornisseurs();
  }

  getQuestions() {
    this.questions = [];
    this.attachmentService.getComments(this.fournisseurControl).subscribe(response => {
      response.forEach(comment => {
        this.questions.push(comment);
      });
    });
  }

  getDemandes() {
    let data = {
      annee: this.currentYear,
      fournisseur: this.currentFournisseur
    };
    this.demandes = [];
    this.attachmentService.getActifPhysiques(data, this.username).subscribe(response => {
      if (response && response.length) {
        response.forEach(rs => {
          if (!rs.dept) {
            rs.dept = this.currentDept;
          }
          this.getRayons(rs);
          if (!rs.rayon) {
            rs.rayon = this.currentRayon;
          }
          if (!rs.type) {
            rs.type = '0';
          }
          if (!rs.moment) {
            rs.moment = '0';
          }
          if (!rs.cluster) {
            rs.cluster = '0';
          }
          if (!rs.pup) {
            rs.pup = 0;
          }
          if (!rs.pus) {
            rs.pus = 0;
          }
          if (!rs.pua) {
            rs.pua = 0;
          }
          if (!rs.demande) {
            rs.demande = 0;
          }
        });
        this.demandes = response;
        this.depliantChanged(this.demandes[0]);
      } else {
        this.addNewPlanDec(0);
      }

    });
  }

  showEditDialog(question: any) {
    Swal.fire({
      title: '<strong></strong>',
      html:
        '<label for="swal2-input" class="swal2-input-label">Commentaire Année ' + (this.yearControl - 1) + '</label>' +
        '<textarea class="swal2-textarea disabled" style="display: flex;width: 88%;">' + 'Commentaire année précedente' + '</textarea>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: question.reponse,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d5d5efde',
      focusConfirm: false,
      inputValidator: result => {
        if (!result) {
          return 'Il faut écrire un texte!';
        }
      },
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      cancelButtonText:
        '<i class="fa fa-ban" style="color: black">Annuller</i>',
      cancelButtonAriaLabel: 'Annuller'
    }).then(response => {
      console.log(response);
      if (response.isConfirmed) {
        Swal.fire({
          title: 'Modifié!', text: 'Votre questionnaire a été Modifié.', icon: 'success', timer: 1500,
          timerProgressBar: true, showConfirmButton: false
        });
      }
    });
  }

  controlChangedFournisseur() {
  }

  controlChangedYear() {
    this.questions = [];
  }

  showEditDialogPosture(question: any) {
    Swal.fire({
      title: '<strong></strong>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: question.argument,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d5d5efde',
      focusConfirm: false,
      /*inputValidator: result => {
        if (!result)
          return 'Il faut écrire un texte!';
      },*/
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      cancelButtonText:
        '<i class="fa fa-ban" style="color: black">Annuller</i>',
      cancelButtonAriaLabel: 'Annuller'
    }).then(response => {
      console.log(response);
      if (response.isConfirmed) {
        /*let index = this.demandes.findIndex(qst => qst.argument == question.argument);
        this.demandes[index].argument = response.value;*/
        question.argument = response.value;
      }
    });
  }

  saveDemandes() {
    this.demandes.forEach(dmnd => {
      dmnd.fournisseur = this.currentFournisseur;
      dmnd.annee = this.currentYear;
    });
    this.attachmentService.saveActifPhysique(this.demandes, this.username).subscribe(response => {
      Swal.fire({
        title: 'Modifié!', text: 'Votre Actifs physique a été Modifié.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
    });
    let demandeActifPhysique = {
      fournisseur: this.currentFournisseur,
      annee: this.currentYear,
      demande: this.demande ? this.demande : 0,
      cible: this.cible ? this.cible : 0,
      ratio: this.ratio ? this.ratio : 0
    };
    this.attachmentService.saveDemandeActifPhysique(demandeActifPhysique).subscribe(response => {
      Swal.fire({
        title: 'Modifié!', text: 'Votre Actifs physique a été Modifié.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
    });
  }

  deptChanged(demande) {
    this.getRayons(demande);
  }

  getRayons(demande) {
    demande.rayons = [];
    this.attachmentService.getRayons(this.currentFournisseur, demande.dept, this.username).subscribe(response => {
      if (!response || !response.length) {
      } else {
        response.forEach(rayon => {
          demande.rayons.push(rayon);
        });
      }
    });
  }

  addNewPlanDec(i: any) {
    this.demandes.splice(i + 1, 0, new DemandeModel(this.currentDept, this.currentRayon, '0', '0', '0'));
    this.getRayons(this.demandes[i + 1] ? this.demandes[i + 1] : this.demandes[i]);
  }

  removePlanDec(i: any) {
    this.demandes.splice(i, 1);
    if (this.demandes.length == 0) {
      this.addNewPlanDec(0);
    }
  }

  getNb_magasin_premium(demande: any) {
    if (!demande.type.includes('EPCS')) {
      if (demande.cluster == 'Hypermarchés Premiums' || demande.cluster == 'Tous les HM') {
        return this.nbMagasin.filter(nm => nm.magasin == 'hp')[0].pgc;
      }
      if (demande.cluster == 'Supermarchés Premiums' || demande.cluster == 'Tous les SM') {
        return this.nbMagasin.filter(nm => nm.magasin == 'sp')[0].pgc;
      }
    } else {
      if (demande.cluster == 'Hypermarchés Premiums' || demande.cluster == 'Tous les HM') {
        return this.nbMagasin.filter(nm => nm.magasin == 'hp')[0].epcs;
      }
    }
    return 0;
  }

  getMagasinNamesStandar(magasin) {
    if (magasin == 'Hypermarchés Standards') {
      return 'hs';
    } else if (magasin == 'Supermarchés Standards') {
      return 'ss';
    } else if (magasin == 'Tous les HM') {
      return 'hs';
    } else if (magasin == 'Tous les SM') {
      return 'ss';
    }
    return magasin;
  }

  getMagasinNamesPremium(magasin) {
    if (magasin == 'Hypermarchés Premiums') {
      return 'hp';
    } else if (magasin == 'Supermarchés Premiums') {
      return 'sp';
    } else if (magasin == 'Tous les HM') {
      return 'hp';
    } else if (magasin == 'Tous les SM') {
      return 'sp';
    }
    return magasin;
  }

  getPrixUnitPremium(demande) {
    let valeur = this.matriceCalcul.filter(mc => mc.type == demande.type)[0][this.getMagasinNamesPremium(demande.cluster) + '_' + this.getEventNames(demande.moment)];
    console.log('valeur', valeur);
    if (!valeur) {
      valeur = 0;
    }
    demande.pup = valeur * demande.nb_actif * demande.nb_magasin_premium * demande.nb_depliant;
    console.log('pup', demande.pup);
  }

  getPrixUnitStandar(demande) {
    let valeur = this.matriceCalcul.filter(mc => mc.type == demande.type)[0][this.getMagasinNamesStandar(demande.cluster) + '_' + this.getEventNames(demande.moment)];
    console.log('valeur', valeur);
    if (!valeur) {
      valeur = 0;
    }
    demande.pus = valeur * demande.nb_actif * demande.nb_magasin_standar * demande.nb_depliant;
    console.log('pus', demande.pus);
  }

  getPrixUnitActif(demande) {
    demande.pua = demande.pup + demande.pus;
    console.log('pua', demande.pua);
  }

  setCible() {
    this.cible = 0;
    this.demandes.forEach(dmnd => this.cible += dmnd.pua);
    this.demandeChanged();
  }


  getEventNames(eventName) {
    if (eventName == 'Permanent') {
      return 'p';
    } else if (eventName == 'Promo hors évènement') {
      return 'phe';
    } else if (eventName == 'Evènement Min') {
      return 'emin';
    } else if (eventName == 'Evènement Max') {
      return 'emax';
    }
    return eventName;
  }

  getNb_magasin_standar(demande: any) {
    if (!demande.type.includes('EPCS')) {
      if (demande.cluster == 'Hypermarchés Standards' || demande.cluster == 'Tous les HM') {
        return this.nbMagasin.filter(nm => nm.magasin == 'hs')[0].pgc;
      }
      if (demande.cluster == 'Supermarchés Standards' || demande.cluster == 'Tous les SM') {
        return this.nbMagasin.filter(nm => nm.magasin == 'ss')[0].pgc;
      }
    } else {
      if (demande.cluster == 'Hypermarchés Standards' || demande.cluster == 'Tous les HM') {
        return this.nbMagasin.filter(nm => nm.magasin == 'hs')[0].epcs;
      }
    }
    return 0;
  }

  clusterChanged(demande: any) {
    demande.nb_magasin_premium = this.getNb_magasin_premium(demande);
    demande.nb_magasin_standar = this.getNb_magasin_standar(demande);
    this.depliantChanged(demande);
  }

  typeChanged(demande: any) {
    this.clusterChanged(demande);
  }

  demandeChanged() {
    if (this.cible && Number(this.cible) != 0) {
      this.ratio = Number(this.demande) / Number(this.cible) * 100;
    } else {
      this.ratio = 0;
    }
  }

  depliantChanged(demande: any) {
    this.getPrixUnitPremium(demande);
    this.getPrixUnitStandar(demande);
    this.getPrixUnitActif(demande);
    this.setCible();
  }

  demandeDemandeChanged() {
    this.demande = this.demandes.map(t1l => Number(t1l.demande)).reduce((a, b) => a + b, 0);
    this.demandeChanged();
  }

  getTwoNumbersAfterVirgule(number) {
    var num = Number(number);
    return this.numberWithSpaces(num.toFixed(2));
  }

  numberWithSpaces(number) {
    let result = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.00', '');
    return result == 'NaN' ? '' : result;
  }
}
