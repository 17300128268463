/*
Created by AYYOUB MIFTAH
*/
import Swal from 'sweetalert2';

export abstract class Utils {
    static showPopUpNotif(titre) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            customClass: 'popUpNotif',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true/*,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }*/
        });

        Toast.fire({
            icon: 'success',
            title: titre
        });
    }

    static createTag() {
        var tag = document.createElement('script');

        tag.src = 'https://www.youtube.com/iframe_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
}
