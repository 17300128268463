// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {AuthScheme} from '../app/core/models/auth-scheme.enum';

const server = 'https://playbook-backend.lbv-negociation-prod.label-factory.ma/api';

export const environment = {
  appName: 'VTS Frontend Application',
  appLogo: './assets/images/logo.png',
  defaultAvatar: './assets/media/images/blank-user.webp',
  // tslint:disable-next-line:max-line-length
  defaultApplicationLogo: './assets/images/logo.png',
  production: true,
  serverUrl: '/api',
  envName: 'prod',
  buildVersion: '0.0.1-SNAPSHOT',
  buildTimestamp: new Date().toISOString(),
  defaultLanguage: 'fr',
  defaultDateFormat: 'DD-MM-YYYY HH:mm',
  defaultDateFormatNoTime: 'DD-MM-YYYY',
  // tslint:disable-next-line:max-line-length
  // emailPattern: '^([a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$',
  // mobilePattern: '^((06)|(07)|(08)|(05))[0-9]{8}$',
  // tslint:disable-next-line:max-line-length
  emailRegExp: /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/,
  authToken: localStorage.getItem('authToken'),
  apiConfig: {
    apiEnv: 'prod',
    timeExpired: 1200,
    apiUrls: [
      {id: 'About_SERVICE_URL', url: server + '/', requireAuthBefore: false},
      {id: 'OAUTH_SERVICE_URL', url: server + '/auth', requireAuthBefore: false},
      {id: 'RESET_PASSWORD_SERVICE_URL', url: server + '/auth/reset-password', requireAuthBefore: false},
      {id: 'RECOVER_PASSWORD_SERVICE_URL', url: server + '/auth/recover-password', requireAuthBefore: false},
    ],
    apiUrl: server,
    apiUploads: server + '/attachments/upload',
    authScheme: AuthScheme.BEARER,
  },
  keycloak: {
    issuer: 'https://keycloak-tg.lbv-security-prod.label-factory.ma',
    realm: 'nego',
    clientId: 'nego_client',
  },
};

