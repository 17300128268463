import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AttachmentService} from '../../shared/services/attachment.service';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Component({
    selector: 'app-admin-questions',
    templateUrl: './admin-questions.component.html',
    styleUrls: ['./admin-questions.component.scss']
})
export class AdminQuestionsComponent implements OnInit {

    countries = [];
    years = [];
    osVersions = [];
    isAccepted: any = false;
    yearControl: any = '0';
    rayonControl: any = '0';
    rayons = [];
    fournisseurControl: any = '0';
    fournisseurs = [];
    parties = [];
    questions = [];
    user: any;

    constructor(private http: HttpClient,
                private router: Router,
                private attachmentService: AttachmentService) {
        if (!attachmentService.canConnect) {
            this.router.navigate(['access-denied']);
        }
        this.testRole();
    }

    testRole() {
        if (this.attachmentService.respReturned) {
            if (this.attachmentService.role != 'ADMIN') {
                this.router.navigate(['access-denied']);
            }
        } else {
            setTimeout(() => this.testRole(), 500);
        }
    }

    ngOnInit() {
        this.chargeYears();
        this.user = JSON.parse(localStorage.getItem('userObject'));
        this.getParties();
        /*this.fetchCountries();
        this.fetchOsVersions();*/
    }

    fetchCountries() {
        this.http.get<any>('../../../../assets/data/mock/countries.json').subscribe(res => {
            this.countries = res;
        });
    }

    getFornisseurs() {
        this.attachmentService.getFornisseurs(this.rayonControl).subscribe(response => {
            response.forEach(fournisseur => {
                this.fournisseurs.push(fournisseur.fournisseur);
            });
        });
    }

    fetchOsVersions() {
        this.http.get<any>('../../../../assets/data/mock/os-versions.json').subscribe(res => {
            this.osVersions = res;
        });
    }

    isFormulaireValid() {
        if (!this.isRayonValid()) {
            return false;
        } else if (this.yearControl == '0') {
            return false;
        } else {
            return this.fournisseurControl != '0';
        }
    }

    yearChanged() {
        console.log(this.yearControl);
    }

    chargeYears() {
        let year = new Date().getFullYear();
        for (let i = 2021; i < year + 2; i++) {
            this.years.push(i);
        }
    }

    isRayonValid() {
        return this.rayonControl != '0';
    }

    rayonChanged() {
        this.getFornisseurs();
    }

    chargeRayons() {
        this.rayons.push('Rayon 1');
        this.rayons.push('Rayon 2');
        this.rayons.push('Rayon 3');
    }

    getQuestions(partie: any) {
        this.questions = [];
        this.attachmentService.getQuestions(partie).subscribe(response => {
            response.forEach(question => {
                this.questions.push(question);
            });
        });
    }

    getParties() {
        this.parties = [];
        this.attachmentService.getParties(this.rayonControl).subscribe(response => {
            response.forEach(partie => {
                this.parties.push(partie);
            });
        });
        /*this.parties.push({
          partie: 'partie1',
          question: 'question 1',
          reponse: 'response de se question',
          lastEdit: 'user1'
        });
        this.parties.push({
          partie: 'partie2',
          question: 'question 2',
          reponse: 'response de se question',
          lastEdit: 'user2'
        });
        this.parties.push({partie: 'partie3', question: 'question 3', reponse: '', lastEdit: ''});
        this.parties.push({partie: 'partie4', question: 'question 4', reponse: '', lastEdit: ''});*/
    }

    editPartieClicked(partie: any) {
        Swal.fire({
            title: '<strong></strong>',
            html:
                '',
            input: 'textarea',
            inputLabel: 'Partie',
            inputValue: partie.partie,
            inputPlaceholder: 'Tapez votre partie ici...',
            inputAttributes: {
                'aria-label': 'Tapez votre partie ici...'
            },
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d5d5efde',
            focusConfirm: false,
            confirmButtonText:
                '<i class="fa fa-edit"></i> Modifier!',
            confirmButtonAriaLabel: 'Modifier',
            cancelButtonText:
                '<i class="fa fa-ban" style="color: black">Annuller</i>',
            cancelButtonAriaLabel: 'Annuller'
        }).then(response => {
            if (response.isConfirmed) {
                this.attachmentService.editPartie(response.value, partie.partie).subscribe(response => {
                    Swal.fire({
                        title: 'Modifié!', text: 'Votre partie a été Modifié.', icon: 'success', timer: 2000,
                        timerProgressBar: true
                    });
                    this.getParties();
                });
            }
        });
    }

    addPartieClicked() {
        Swal.fire({
            title: '<strong></strong>',
            html:
                '',
            input: 'textarea',
            inputLabel: 'Partie',
            inputValue: '',
            inputPlaceholder: 'Tapez votre partie ici...',
            inputAttributes: {
                'aria-label': 'Tapez votre partie ici...'
            },
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d5d5efde',
            focusConfirm: false,
            confirmButtonText:
                '<i class="fa fa-edit"></i> Ajouter!',
            confirmButtonAriaLabel: 'Ajouter',
            cancelButtonText:
                '<i class="fa fa-ban" style="color: black">Annuler</i>',
            cancelButtonAriaLabel: 'Annuler'
        }).then(response => {
            if (response.isConfirmed) {
                this.attachmentService.addPartie(response.value).subscribe(response => {
                    Swal.fire({
                        title: 'Ajouté!', text: 'Votre partie a été Ajouté.', icon: 'success', timer: 2000,
                        timerProgressBar: true
                    });
                    this.getParties();
                });
            }
        });
    }

    deletePartieClicked(partie: any) {
        Swal.fire({
            title: 'êtes-vous sûr?',
            text: 'Tu ne pourras pas revenir en arrière!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#3085d6',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimez-le!',
            cancelButtonText: 'No, Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                this.attachmentService.deletePartie(partie.partie).subscribe(response => {
                    Swal.fire({
                        title: 'Supprimé!', text: 'Votre partie a été supprimée.', icon: 'success', timer: 2000,
                        timerProgressBar: true, showConfirmButton: false
                    });
                    this.getParties();
                });
            }
        });
    }

    editQuestionnaireClicked(partie: any) {
        Swal.fire({
            title: '<strong></strong>',
            html:
                '',
            input: 'textarea',
            inputLabel: 'Question',
            inputValue: partie.question,
            inputPlaceholder: 'Tapez votre question ici...',
            inputAttributes: {
                'aria-label': 'Tapez votre question ici...'
            },
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d5d5efde',
            focusConfirm: false,
            confirmButtonText:
                '<i class="fa fa-edit"></i> Modifier!',
            confirmButtonAriaLabel: 'Modifier',
            cancelButtonText:
                '<i class="fa fa-ban" style="color: black">Annuller</i>',
            cancelButtonAriaLabel: 'Annuller'
        }).then(response => {
            if (response.isConfirmed) {
                this.attachmentService.editQuestion(response.value,
                    partie.question).subscribe(response => {
                    Swal.fire({
                        title: 'Modifié!', text: 'Votre questionnaire a été Modifié.', icon: 'success', timer: 2000,
                        timerProgressBar: true
                    });
                    this.getQuestions(this.parties.find(prt => prt.selected).partie);
                });
            }
        });
    }

    addQuestionnaireClicked() {
        Swal.fire({
            title: '<strong></strong>',
            html:
                '',
            input: 'textarea',
            inputLabel: 'Question',
            inputValue: '',
            inputPlaceholder: 'Tapez votre question ici...',
            inputAttributes: {
                'aria-label': 'Tapez votre question ici...'
            },
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d5d5efde',
            focusConfirm: false,
            confirmButtonText:
                '<i class="fa fa-edit"></i> Ajouter!',
            confirmButtonAriaLabel: 'Ajouter',
            cancelButtonText:
                '<i class="fa fa-ban" style="color: black">Annuler</i>',
            cancelButtonAriaLabel: 'Annuler'
        }).then(response => {
            if (response.isConfirmed) {
                this.attachmentService.addQuestion(response.value,
                    this.parties.find(prt => prt.selected).partie,
                    this.questions.length == 0).subscribe(response => {
                    Swal.fire({
                        title: 'Ajouté!', text: 'Votre questionnaire a été Ajouté.', icon: 'success', timer: 2000,
                        timerProgressBar: true
                    });
                    this.getQuestions(this.parties.find(prt => prt.selected).partie);
                });
            }
        });
    }

    deleteQuestionnaireClicked(partie: any) {
        Swal.fire({
            title: 'êtes-vous sûr?',
            text: 'Tu ne pourras pas revenir en arrière!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#3085d6',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Oui, supprimez-le!',
            cancelButtonText: 'No, Annuler'
        }).then((result) => {
            if (result.isConfirmed) {
                this.attachmentService.deleteQuestion(partie.question, this.questions.length == 1).subscribe(response => {
                    Swal.fire({
                        title: 'Supprimé!', text: 'Votre Questionnaire a été supprimée.', icon: 'success', timer: 2000,
                        timerProgressBar: true, showConfirmButton: false
                    });
                    this.getQuestions(this.parties.find(prt => prt.selected).partie);
                });
            }
        });
    }

    selectPartie(partie: any) {
        if (!partie.selected) {
            this.getQuestions(partie.partie);
        }
        this.parties.forEach(part => part.selected = (part == partie));
    }

    isPartieSelected() {
        return this.parties.find(part => part.selected);
    }
}
