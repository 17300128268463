<!--<div style="margin-left: 20rem">
  <button routerLink="dashboard">Manager Page</button>
  <button routerLink="admin">Admin Page</button>
  <button (click)="logout()">logout</button>
</div>-->
<!--
<router-outlet></router-outlet>
-->
<ng-container *ngIf="respReturned">
  <app-navbar (toggleSidebar)="onToggleSidebar($event)" [user]="user" [username]="username"></app-navbar>
  <div class="wrapper bodyApp" style="float: right;">
    <aside class="sidebar">
    </aside>
    <aside class="block">
      <ngx-ui-loader [fgsType]="'three-strings'" [overlayColor]="'rgba(40,40,40,0.3)'" [fgsSize]="150"
                     [pbThickness]="10" [pbColor]="'#b22a2e'"
                     [fgsColor]="'#b22a2e'"></ngx-ui-loader>
      <router-outlet></router-outlet>
    </aside>
  </div>
  <app-sidebar *ngIf="menu && menu.length" [user]="user" [menu]="menu" [isFolded]="isSidebarFolded"></app-sidebar>
</ng-container>
