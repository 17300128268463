/*
Created by AYYOUB MIFTAH
*/
export class DecroissanceModel {
  constructor(impact: string, etape) {
    this.impact = impact;
    this.etape = etape;
  }

  etape: string;
  impact: string;
  action: string;
  listAction: string;
  description: string;
  datedebut: string;
  duree: string;
  nvxl: string;
  vall: string;
  descl: string;
  nvxf: string;
  valf: string;
  descf: string;
  plan: string;
  annee: string;
  fournisseur: string;
}
