import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  @Input() isForTelegram = false;
  @Input() isForQuestion = false;
  @Input() isForDemandePerformance = false;
  @Input() isForDemandeNvxActifs = false;
  @Input() isForActifPhysique = false;
  @Input() isForVueFournisseur = false;
  @Input() isSuiviConnexion = false;
  @Input() isGestionListePlaybook = false;
  @Input() currentYear = '2022';
  @Input() currentFournisseur = 'fournisseur';
  @Input() currentUser = 'user';
  @Input() isForQuestionAdmin = false;
  @Input() isForOther = false;

  constructor() {
  }

  ngOnInit() {
  }

}
