<div class="page">
  <!--<app-stats></app-stats>-->
  <app-stats [isGestionListePlaybook]="true" [currentUser]="username"></app-stats>
  <main>
    <div class="panel">
      <div class="table-panel users">
        <div class="panel-title">
          Liste des playbooks
        </div>
        <input type="text" class="form-control" [(ngModel)]="searchFilter" placeholder="Recherche"
               style="width: 90%;margin-left: auto;margin-right: auto">
        <div class="panel-body">
          <table>
            <thead>
            <tr>
              <th style="width: 10%;text-align: center">
                Playbook
              </th>
              <th style="width: 5%;text-align: center">
                Annee
              </th>
              <th style="width: 15%;text-align: center">
                Sous département
              </th>
              <th style="width: 12%;text-align: center">
                Date fin finalisation
              </th>
              <th style="width: 12%;text-align: center">
                Date fin validation
              </th>
              <th style="width: 5%;text-align: center">
                Tier
              </th>
              <th style="width: 6%;text-align: center">
                Statut
              </th>
              <th style="width: 20%;text-align: center">
                Niveau de validation
              </th>
              <th style="width: 25%;text-align: center">
                Actions
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of getLeadFiltered()">
              <td style="text-align: center;cursor: pointer" (click)="goToLeadPage(user.fournisseur,user.annee)">{{ user.fournisseur }}</td>
              <td style="text-align: center">{{ user.annee }}</td>
              <td style="text-align: center">{{ user.sous_dept }}</td>
              <td style="text-align: center">{{ user.date_fin_finalisation }}</td>
              <td style="text-align: center">{{ user.date_fin_validation }}</td>
              <td style="text-align: center">{{ user.tier }}</td>
              <td style="text-align: center"><span [style.background-color]="user.status=='CREATED'?'#57ef57':'#01ab01'"
                                                   style="color: white;border-radius: 50px;padding: 0.4rem;">{{ user.status }}</span>
              </td>
              <td style="text-align: center">{{ user.niveau_validation }}</td>
              <td style="text-align: center">
                <input type="button" class="form-control btn-success" value="Valider" *ngIf="showValiderButton(user)"
                       (click)="validePlaybookN1(user)"
                       style="width: 40%; float: initial; background-color: #30bd70; margin: auto;">
                <input type="button" class="form-control btn-success" value="Valider" *ngIf="showValiderButtonN2(user)"
                       (click)="validePlaybookN2(user)"
                       style="width: 40%; float: initial; background-color: #30bd70; margin: auto;">
                <input type="button" class="form-control btn-success" value="Valider" *ngIf="showValiderButtonN3(user)"
                       (click)="validePlaybookN3(user)"
                       style="width: 40%; float: initial; background-color: #30bd70; margin: auto;">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</div>
