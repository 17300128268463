import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {initializer} from 'src/utils/app-inits';
import {AccessDeniedComponent} from './access-denied/access-denied.component';
import {AdminComponent} from './admin/admin.component';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ManagerComponent} from './manager/manager.component';
import {DashboardComponent} from './admin/dashboard/dashboard.component';
import {DemandePerformanceComponent} from './admin/demande-performance/demande-performance.component';
import {LeadBnComponent} from './admin/lead-bn/lead-bn.component';
import {DemandeNvxActifsComponent} from './admin/demande-nvx-actifs/demande-nvx-actifs.component';
import {ActifPhysiqueComponent} from './admin/actif-physique/actif-physique.component';
import {VueFournisseurComponent} from './admin/vue-founisseur/vue-fournisseur.component';
import {SuiviConnexionComponent} from './admin/suivi-connexion/suivi-connexion.component';
import {AdminQuestionsComponent} from './admin/admin-questions/admin-questions.component';
import {JointComponent} from './admin/joint/joint.component';
import {StatsComponent} from './admin/stats/stats.component';
import {SidebarComponent} from './shared/components/sidebar/sidebar.component';
import {NavbarComponent} from './shared/components/navbar/navbar.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TokenInterceptor} from './core/interceptors/token.interceptor';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {NgSelectModule} from '@ng-select/ng-select';
import {ServerDownComponent} from './server-down/server-down.component';
import {GestionLeadComponent} from './admin/gestion-lead/gestion-lead.component';
import {GestionChefDeptComponent} from './admin/gestion-chef-dept/gestion-chef-dept.component';
import {GestionListePlaybookComponent} from './admin/gestion-liste-playbook/gestion-liste-playbook.component';
import {ListeValidationComponent} from './admin/liste-validation/liste-validation.component';
import {RapportTiersComponent} from './admin/rapport-tiers/rapport-tiers.component';

@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    ServerDownComponent,
    ManagerComponent,
    AdminComponent,
    DashboardComponent,
    DemandePerformanceComponent,
    LeadBnComponent,
    DemandeNvxActifsComponent,
    ActifPhysiqueComponent,
    VueFournisseurComponent,
    SuiviConnexionComponent,
    GestionLeadComponent,
    GestionChefDeptComponent,
    GestionListePlaybookComponent,
    ListeValidationComponent,
    RapportTiersComponent,
    AdminQuestionsComponent,
    JointComponent,
    StatsComponent,
    SidebarComponent,
    NavbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxUiLoaderModule,
    NgSelectModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
