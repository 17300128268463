import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AttachmentService} from '../../shared/services/attachment.service';
import Swal from 'sweetalert2';
import {DemandeModel} from '../../core/models/demande.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-demande-nvx-actifs',
  templateUrl: './demande-nvx-actifs.component.html',
  styleUrls: ['./demande-nvx-actifs.component.scss']
})
export class DemandeNvxActifsComponent implements OnInit {

  countries = [];
  years = [];
  osVersions = [];
  isAccepted: any = false;
  yearControl: any = '0';
  rayonControl: any = '0';
  deptControl: any = '0';
  rayons = [];
  depts = [];
  fournisseurControl: any = '0';
  fournisseurs = [];
  questions = [];
  demandes: DemandeModel[] = [];
  sQuestions = [];
  user: any;
  currentYear: string;
  currentFournisseur: string;
  username: string;
  actifSelected: any;
  actifs = [];
  contrePartieSelected: any;
  contreParties = [];

  constructor(private http: HttpClient,
              private router: Router,
              private attachmentService: AttachmentService) {
    if (!attachmentService.canConnect) {
      this.router.navigate(['access-denied']);
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userObject'));
    this.username = localStorage.getItem('username');
    this.getDemandes();
    /*this.fetchCountries();
    this.fetchOsVersions();*/
  }

  fetchCountries() {
    this.http.get<any>('../../../../assets/data/mock/countries.json').subscribe(res => {
      this.countries = res;
    });
  }

  getFornisseurs() {
    this.attachmentService.getFornisseurs(this.rayonControl).subscribe(response => {
      response.forEach(fournisseur => {
        this.fournisseurs.push(fournisseur.fournisseur);
      });
    });
  }

  fetchOsVersions() {
    this.http.get<any>('../../../../assets/data/mock/os-versions.json').subscribe(res => {
      this.osVersions = res;
    });
  }

  isFormulaireValid() {
    if (!this.isRayonValid()) {
      return false;
    } else if (this.yearControl == '0') {
      return false;
    } else {
      return this.fournisseurControl != '0';
    }
  }

  isRayonValid() {
    return this.rayonControl != '0';
  }

  isFournisValid() {
    return this.fournisseurControl != '0';
  }

  isdeptValid() {
    return this.deptControl != '0';
  }

  rayonChanged() {
    //this.getFornisseurs();
  }

  getQuestions() {
    this.questions = [];
    this.attachmentService.getComments(this.fournisseurControl).subscribe(response => {
      response.forEach(comment => {
        this.questions.push(comment);
      });
    });
  }

  getDemandes() {
    this.currentYear = localStorage.getItem('year');
    this.currentFournisseur = localStorage.getItem('fournisseur');
    let data = {
      annee: this.currentYear,
      fournisseur: this.currentFournisseur
    };
    this.demandes = [];
    this.attachmentService.getNvxActifs(data).subscribe(response => {
      this.demandes = response.sort((a, b) => (a.id - b.id));
      this.actifs = this.demandes.map(dmd => dmd['actif']).filter(this.onlyUnique);
      this.contreParties = this.demandes.map(dmd => dmd['contrepartie']).filter(this.onlyUnique);
    });
  }

  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  showEditDialog(question: any) {
    Swal.fire({
      title: '<strong></strong>',
      html:
        '<label for="swal2-input" class="swal2-input-label">Commentaire Année ' + (this.yearControl - 1) + '</label>' +
        '<textarea class="swal2-textarea disabled" style="display: flex;width: 88%;">' + 'Commentaire année précedente' + '</textarea>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: question.reponse,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d5d5efde',
      focusConfirm: false,
      inputValidator: result => {
        if (!result) {
          return 'Il faut écrire un texte!';
        }
      },
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      cancelButtonText:
        '<i class="fa fa-ban" style="color: black">Annuller</i>',
      cancelButtonAriaLabel: 'Annuller'
    }).then(response => {
      console.log(response);
      if (response.isConfirmed) {
        Swal.fire({
          title: 'Modifié!', text: 'Votre questionnaire a été Modifié.', icon: 'success', timer: 1500,
          timerProgressBar: true, showConfirmButton: false
        });
      }
    });
  }

  controlChangedFournisseur() {
  }

  controlChangedYear() {
    this.questions = [];
  }

  showEditDialogPosture(question: any) {
    Swal.fire({
      title: '<strong></strong>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: question.argument,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d5d5efde',
      focusConfirm: false,
      /*inputValidator: result => {
        if (!result)
          return 'Il faut écrire un texte!';
      },*/
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      cancelButtonText:
        '<i class="fa fa-ban" style="color: black">Annuller</i>',
      cancelButtonAriaLabel: 'Annuller'
    }).then(response => {
      console.log(response);
      if (response.isConfirmed) {
        /*let index = this.demandes.findIndex(qst => qst.argument == question.argument);
        this.demandes[index].argument = response.value;*/
        question.argument = response.value;
        question.changed = true;
      }
    });
  }

  saveDemandes() {
    this.demandes.forEach(dmd => dmd['cibleD'] = Number(dmd['cibleD'].toString().replace(',', '.')));
    let objList = this.demandes.filter(dmd => dmd.argument || dmd['demande']);
    if (objList.length == 0) {
      objList.push(this.demandes[0]);
    }
    this.attachmentService.saveNvxActifs(objList).subscribe(response => {
      Swal.fire({
        title: 'Modifié!', text: 'Votre Nvx Actifs a été Modifié.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
    });
  }

  changeDemande(demande: DemandeModel) {
    if (demande['demande'] == 'x') {
      demande['demande'] = '';
      demande['cibleD'] = '';
    } else {
      demande['demande'] = 'x';
      demande['cibleD'] = demande['cible'];
    }
    demande.changed = true;
  }

  actifSelectedChanged() {
    if (this.actifSelected) {
      this.contreParties = this.demandes.filter(dmd => dmd['actif'] == this.actifSelected).map(dmd => dmd['contrepartie']).filter(this.onlyUnique);
      this.contrePartieSelected = '';
    }
  }

  contrePartieSelectedChanged() {
    debugger;
  }

  canShowActif(demande: DemandeModel) {
    return (!this.actifSelected
        || (this.actifSelected && this.actifSelected != '' && this.actifSelected == demande['actif']))
      && (!this.contrePartieSelected
        || (this.contrePartieSelected && this.contrePartieSelected != '' && this.contrePartieSelected == demande['contrepartie']));
  }
}
