import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-joint',
  templateUrl: './joint.component.html',
  styleUrls: ['./joint.component.scss']
})
export class JointComponent implements OnInit {

  countries = [];
  osVersions = [];
  isAccepted: any = true;
  constructor(private http: HttpClient) { }

  ngOnInit() {
    /*this.fetchCountries();
    this.fetchOsVersions();*/
  }

  fetchCountries(){
    this.http.get<any>("../../../../assets/data/mock/countries.json").subscribe(res =>{
      this.countries = res;
    })
  }

  fetchOsVersions(){
    this.http.get<any>("../../../../assets/data/mock/os-versions.json").subscribe(res =>{
      this.osVersions = res;
    })
  }

}
