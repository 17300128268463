import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpStatusService {
  private requestCountSubject = 0;

  incrementRequestCount(): void {
    this.requestCountSubject=this.requestCountSubject+ 1;
  }

  decrementRequestCount(): void {
    this.requestCountSubject=this.requestCountSubject- 1;
  }

  isRequestNotPending(){
    return this.requestCountSubject==0;
  }
}
