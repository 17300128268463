import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  public user: any;
  public menu: any[];
  isSidebarFolded = false;

  constructor() {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userObject'));
    /*if (localStorage.getItem(AuthHelper.USER_ID)) {

      this.user = JSON.parse(localStorage.getItem(AuthHelper.USER_ID));
      this.user.avatar = this.user.avatar || environment.defaultAvatar;
      this.menu = [
        {
          'title': 'Dashboard',
          'action': 'dashboard',
          'icon': 'fa-tachometer',
          'i18n': 'menu.dashboard',
          'disabled': false,
          'submenu': []
        },
        {
          'title': 'Bots',
          'action': 'bots',
          'icon': 'fa-desktop',
          'i18n': 'Bots',
          'disabled': false,
          'submenu': [{
            'title': 'Bots',
            'action': 'bots',
            'icon': 'fa-desktop',
            'i18n': 'Bots',
            'disabled': false,
            'submenu': []
          },]
        }
      ];
    } else {*/
    this.menu = [
      {
        'title': 'Brief De Nego',
        'action': 'dashboard',
        'icon': 'fa-tachometer',
        'i18n': 'Brief de négo',
        'disabled': false,
        'submenu': []
      }, {
        'title': 'Demande De Performance',
        'action': 'demande-performance',
        'icon': 'fa-table',
        'i18n': 'Demande de Performance',
        'disabled': false,
        'submenu': []
      }, {
        'title': 'Demande Nvx Actifs',
        'action': 'demande-nvx-actifs',
        'icon': 'fa-table',
        'i18n': 'Demande Nvx Actifs',
        'disabled': false,
        'submenu': []
      }, {
        'title': 'Demande Actifs Physiques',
        'action': 'actif-physique',
        'icon': 'fa-table',
        'i18n': 'Demande Actifs Physiques',
        'disabled': false,
        'submenu': []
      }, {
        'title': 'Chiffres Clés',
        'action': 'joint',
        'icon': 'fa-table',
        'i18n': 'Chiffres Clés',
        'disabled': false,
        'gris': true,
        'submenu': []
      }, {
        'title': 'Plan Commercial',
        'action': 'vue-fournisseur',
        'icon': 'fa-table',
        'i18n': 'Plan Commercial',
        'disabled': false,
        'submenu': []
      }
    ];
    /*this.router.navigate(['login']);*/
    /*}*/

  }

  onToggleSidebar(isFolded: boolean) {
    this.isSidebarFolded = isFolded;
  }
}
