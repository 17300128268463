import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Response} from '../../core/models/response.model';


let headers = new HttpHeaders();
headers = headers.set('Content-Type', 'application/json; charset=utf-8');

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  private urlApi: string;
  canConnect: boolean = true;
  role = '';
  respReturned = false;

  constructor(private httpClient: HttpClient) {
    this.urlApi = environment.apiConfig.apiUrl;
  }

  downloadAttachments(selectedAttachments) {
    const attachmentsDownloaded = [];
    const len = selectedAttachments ? selectedAttachments.length : 0;
    let index = 0;
    selectedAttachments.map((attachment) => {
      const timeout = setTimeout(() => {
        this.getAttachment(attachment.id).subscribe((attachmentInfo) => {
          if (attachmentInfo && attachmentInfo.data) {
            const myAttachment = attachmentInfo.data;
            this.attachmentDownload(attachment.id).subscribe((response) => {
              if (response && response['body']) {
                attachmentsDownloaded.push(
                  {
                    base64: this._arrayBufferToBase64(response['body']),
                    title: myAttachment.name
                  }
                );
                index++;
                clearTimeout(timeout);
                if (index === len) { // last index
                  this.downloadAll(attachmentsDownloaded);
                }
              }
            });
          }
        });
      }, 1000);
    });
  }

  downloadAll(urls) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.style.display = 'none';
    document.body.appendChild(link);
    for (let i = 0; i < urls.length; i++) {
      link.setAttribute('download', urls[i].title);
      // link.setAttribute('href', 'data:application/octet-stream;charset=utf-8;base64,' + encodeURIComponent(urls[i].base64));

      const byteCharacters = atob(urls[i].base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let j = 0; j < byteCharacters.length; j++) {
        byteNumbers[j] = byteCharacters.charCodeAt(j);
      }
      const byteArray = new Uint8Array(byteNumbers);

      link.setAttribute('href', URL.createObjectURL(new Blob([byteArray])));

      link.click();
    }
    document.body.removeChild(link);
  }

  _arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  attachmentDownload(id: number) {
    const url = `${this.urlApi}/attachments/download/${id}`;
    const req = new HttpRequest('GET', url, {
      responseType: 'arraybuffer',
    });
    return this.httpClient.request(req);
  }

  getAttachment(id: number): Observable<Response<any>> {
    return this.httpClient.get<Response<any>>(`${this.urlApi}/attachments/${id}`);
  }

  login(user): Observable<Response<any>> {
    return this.httpClient.post<Response<any>>(`${this.urlApi}/login`, user);
  }

  getRayons(atr, atr2, username): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getRayons?fournisseur=${atr}&dept=${atr2}`);
  }

  getDepts(atr, username): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getDepts?fournisseur=${atr}`);
  }

  getAllDepts(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getAllDepts`);
  }

  getAllFournisseurMonoRayon(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getAllFournisseurMonoRayon`);
  }

  validatePlaybookChef(playbook, annee, dept): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/validatePlaybookChef?playbook=${playbook}&annee=${annee}&dept=${dept}`);
  }

  validatePlaybookN2(playbook, annee): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/validatePlaybookN2?playbook=${playbook}&annee=${annee}`);
  }

  validatePlaybookN3(playbook, annee): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/validatePlaybookN3?playbook=${playbook}&annee=${annee}`);
  }

  getFL(username): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getFL`);
  }

  getDemandeActifPhysique(atr, atr2): Observable<any> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getDemandeActifPhysique?fournisseur=${atr}&annee=${atr2}`);
  }

  getTypes(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getTypes`);
  }

  getMoments(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getMoments`);
  }

  getClusters(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getClusters`);
  }

  getMatriceCalcul(atr): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getMatriceCalcul?annee=${atr}`);
  }

  getNbMagasin(atr): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getNbMagasin?annee=${atr}`);
  }

  getFornisseurs(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getFournisseurs`, {}, {headers: {}});
  }

  getParties(atr): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getParties`);
  }

  addPartie(atr): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/addPartie?partie=${atr}`);
  }

  ifUserExist(obj): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/ifUserExist`, obj);
  }

  ifUserChefDept(): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.urlApi}/ifUserChefDept`);
  }

  deletePartie(atr): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/deletePartie?partie=${atr}`);
  }

  deleteQuestion(atr, last): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/deleteQuestion?question=${atr}&last=${last}`);
  }

  addQuestion(atr, atr2, first): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/addQuestion?question=${atr}&partie=${atr2}&first=${first}`);
  }

  editQuestion(atr, atr2): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/editQuestion?question=${atr}&lastquestion=${atr2}`);
  }

  editPartie(atr, atr2): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/editPartie?partie=${atr}&lastpartie=${atr2}`);
  }

  getQuestions(atr): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getQuestions?partie=${atr}`);
  }

  getComments(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getComments`, atr);
  }

  getAllRayons(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getAllRayons`);
  }

  getAllUsers(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getAllUsers`);
  }

  getAllLeads(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getAllLeads`);
  }

  getAllChefDepts(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getAllChefDepts`);
  }

  getAllListePlaybook(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getAllListePlaybook`);
  }

  getRapportTiers(annee): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getRapportTiers?annee=${annee}`);
  }

  getRapportTierst2t(annee): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getRapportTierst2t?annee=${annee}`);
  }

  getRapportSousDept(tier, annee, niv3): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getRapportSousDept?tier=${tier}&annee=${annee}&niv3=${niv3}`);
  }

  getRapportSousDeptT2T(tier, annee): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getRapportSousDeptT2T?tier=${tier}&annee=${annee}`);
  }

  getRapportSousDeptAchteur(tier, annee): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getRapportSousDeptAchteur?tier=${tier}&annee=${annee}`);
  }

  getListePlaybookChef(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getListePlaybookChef`);
  }

  getUserWorkflow(): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/getUserWorkflow`);
  }

  finalisePlaybookLead(playbook, annee): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/finalisePlaybookLead?playbook=${playbook}&annee=${annee}`);
  }

  canFinaliseLead(playbook, annee): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.urlApi}/canFinaliseLead?playbook=${playbook}&annee=${annee}`);
  }

  finalisePlaybookAchteur(playbook, annee): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/finalisePlaybookAchteur?playbook=${playbook}&annee=${annee}`);
  }

  sendPlaybookAchteur(playbook, annee): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/sendPlaybookAchteur?playbook=${playbook}&annee=${annee}`);
  }

  addUser(user): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/addUser`, user);
  }

  addLead(user): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/addLead`, user);
  }

  addChefDept(user): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/addChefDept`, user);
  }

  addListePlaybook(user): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/addListePlaybook`, user);
  }

  editUser(user): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/editUser`, user);
  }

  editUserDisable(username, disable): Observable<Array<any>> {
    return this.httpClient.get<Array<any>>(`${this.urlApi}/editUserDisable?username=${username}&disable=${disable}`);
  }

  getCommentsArchives(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getCommentsArchives`, atr);
  }

  getSyntheseLeadArchive(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getSyntheseLeadArchive`, atr);
  }

  getPosturesArchive(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getPosturesArchive`, atr);
  }

  getDemandeArchive(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getDemandeArchive`, atr);
  }

  getCommentsLead(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getCommentsLead`, atr);
  }

  getSyntheseLead(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getSyntheseLead`, atr);
  }

  getPostures(atr, username): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getPostures`, atr);
  }

  getCommentlastyear(atr): Observable<any> {
    return this.httpClient.post<any>(`${this.urlApi}/getCommentlastyear`, atr);
  }

  editComment(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/editComment`, atr);
  }

  editSynthese(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/editSynthese`, atr);
  }

  editPosture(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/editPosture`, atr);
  }

  editPostureStatut(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/editPostureStatut`, atr);
  }

  getDemandes(atr, username): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getDemandes`, atr);
  }

  getPlanCroissance(atr, username): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getPlanCroissance`, atr);
  }

  getl2l3(atr, username): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getl2l3`, atr);
  }

  getRanking(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getRanking`, atr);
  }

  getPlanDecroissance(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getPlanDecroissance`, atr);
  }

  getNvxActifs(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getNvxActifs`, atr);
  }

  getActifPhysiques(atr, username): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/getActifPhysiques`, atr);
  }

  saveDemandes(atr, username): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/saveDemandes`, atr);
  }

  updateCommentDemande(atr, username): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/updateCommentDemande`, atr);
  }

  saveRanking(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/saveRanking`, atr);
  }

  savePlanDecroissance(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/savePlanDecroissance`, atr);
  }

  savePlanCroissance(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/savePlanCroissance`, atr);
  }

  savel2l3(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/savel2l3`, atr);
  }

  saveNvxActifs(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/saveNvxActifs`, atr);
  }

  saveActifPhysique(atr, username): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/saveActifPhysique`, atr);
  }

  saveDemandeActifPhysique(atr): Observable<Array<any>> {
    return this.httpClient.post<Array<any>>(`${this.urlApi}/saveDemandeActifPhysique`, atr);
  }

  /* getAttachments(attachmentsIds: Array<number>): Observable<Response<any>> {
     return this.httpClient.post<Response<AttachmentModel>>(`${this.urlApi}/attachments-in-ids`, attachmentsIds, ({headers: headers}));
   }

   saveAttachment(attachment: Attachment): Observable<Response<Attachment>> {
     return this.httpClient.post<Response<Attachment>>(`${this.urlApi}/attachments/wiki/save`, attachment, ({headers: headers}));
   }

   deleteAttachment(attachmentId: number) {
     return this.httpClient.delete<Response<Attachment>>(`${this.urlApi}/attachments/wiki/delete/${attachmentId}`, ({headers: headers}));
   }*/
}
