<div class="page">
  <!--<app-stats></app-stats>-->
  <app-stats [isSuiviConnexion]="true" [currentUser]="username"></app-stats>
  <main>
    <div class="panel">
      <div class="table-panel users">
        <div class="panel-title">
          Gestion lead
        </div>
        <div class="panel-body" style="display: flex;align-items: center;justify-content: space-around;">
          <div class="form-group" style="width: 25%;padding: 0 0.5rem;">
            <label class="form-label">Lead :</label>
            <ng-select *ngIf="users&&users.length" [disabled]="isEdit"
                       [items]="users"
                       placeholder="Selectionner un utilisateur"
                       [(ngModel)]="userControl">
            </ng-select>
          </div>

          <div class="form-group" style="width: 25%;padding: 0 0.5rem;">
            <label class="form-label">Fournisseur :</label>
            <ng-select *ngIf="fournisseurs&&fournisseurs.length"
                       [items]="fournisseurs"
                       [multiple]="true"
                       [hideSelected]="true"
                       [closeOnSelect]="false"
                       [searchable]="true"
                       placeholder="Selectionner les fournisseurs"
                       [(ngModel)]="fournisseurControl">
            </ng-select>
          </div>
          <div class="form-group" style="width: 25%;padding: 0 0.5rem;">
            <label class="form-label">Annee :</label>
            <ng-select *ngIf="years&&years.length"
                       [items]="years"
                       [multiple]="true"
                       [closeOnSelect]="false"
                       placeholder="Selectionner des annees"
                       [(ngModel)]="yearControl">
            </ng-select>
          </div>
          <div class="form-group" style="width: 25%">
            <div style="width: 60%;margin-left: auto;margin-right: auto;">
              <button class="btn btn-primary flexAutoMarge" *ngIf="!isEdit"
                      style="color: white;background-color: #b22a2e;height: 3.3rem;width: 100%;margin: 0.5rem"
                      [disabled]="!isFormulaireValid()" (click)="addUser()">Ajouter
              </button>
              <button class="btn btn-primary flexAutoMarge" *ngIf="isEdit"
                      style="color: white;background-color: #b22a2e;height: 3.3rem;width: 100%;margin: 0.5rem"
                      [disabled]="!isFormulaireValid()" (click)="editUser()">Modifier
              </button>
              <button class="btn btn-light flexAutoMarge"
                      style="height: 3.3rem;width: 100%;margin: 0.5rem"
                      (click)="this.isEdit = false;this.userControl = '';this.fournisseurControl = [];this.yearControl = [];">
                Annuler
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="panel">
      <div class="table-panel users">
        <div class="panel-title">
          Liste des utilisateurs
        </div>
        <input type="text" class="form-control" [(ngModel)]="searchFilter" placeholder="Recherche"
               style="width: 90%;margin-left: auto;margin-right: auto">
        <div class="panel-body">
          <table>
            <thead>
            <tr>
              <th style="width: 25%;text-align: center">
                Lead
              </th>
              <th style="width: 46%;text-align: center">
                Fournisseur
              </th>
              <th style="width: 14%;text-align: center">
                Annee
              </th>
              <th style="width: 25%;text-align: center">
                Actions
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of getLeadFiltered()">
              <td style="text-align: center">{{ user.user }}</td>
              <td style="text-align: center">
                <div class="divWithTickets">
                  <div class="row">
                                        <pre class="col-lg-3 col-md-5 col-sm-6" [style.border]="fournis.toString().toUpperCase().includes(searchFilter.toUpperCase())&&searchFilter!=''?'1px black solid':'0px black solid'"
                                             style="overflow: hidden;color:white;background-color: rgba(54,153,255,0.71);border-radius: 1rem;padding: 0.2rem;margin: 0.1rem"
                                             *ngFor="let fournis of user.fournisseurs">{{ fournis }}</pre>
                  </div>
                </div>
              </td>
              <td style="text-align: center">
                <div class="divWithTickets">
                  <div class="row">
                                        <pre class="col-lg-3 col-md-5 col-sm-6"
                                             style="color:white;background-color: rgba(54,153,255,0.71);border-radius: 1rem;padding: 0.2rem;margin: 0.1rem"
                                             *ngFor="let annee of user.annees.slice(0,9)">{{ annee }}</pre>
                    <pre class="col-lg-3 col-md-5 col-sm-6"
                         style="color:white;background-color: rgba(54,153,255,0.71);border-radius: 1rem;padding: 0.2rem;margin: 0.1rem"
                         *ngIf="user.annees.length>9" [title]="user.anneesTitle">...</pre>
                  </div>
                </div>
              </td>
              <td style="text-align: center"><i class="fa fas fa-edit cursor-pointer"
                                                (click)="isEdit=true;chargeUser(user)"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</div>
