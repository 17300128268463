import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AttachmentService} from '../../shared/services/attachment.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lead-bn',
  templateUrl: './lead-bn.component.html',
  styleUrls: ['./lead-bn.component.scss']
})
export class LeadBnComponent implements OnInit {

  countries = [];
  years = [];
  osVersions = [];
  isAccepted: any = false;
  yearControl: any = '0';
  rayonControl: any = '0';
  deptControl: any = '0';
  rayons = [];
  depts = [];
  fournisseurControl: any = '0';
  fournisseurs = [];
  questions = [];
  questionsGrouped = [];
  questionsGroupedQuestions = [];
  postures = [];
  user: any;
  currentYear: number;
  issfamille = false;
  username: string;
  responseArchives = [];
  userWorkflow = [];
  canFinaliseVariable = false;
  allFournisseurMonoRayon = [];
  listePlaybookChef = [];
  playbookChef: any;

  constructor(private http: HttpClient,
              private attachmentService: AttachmentService) {
  }

  ngOnInit() {
    this.chargeYears();
    this.user = JSON.parse(localStorage.getItem('userObject'));
    this.username = localStorage.getItem('username');
    this.yearControl = localStorage.getItem('year');
    this.fournisseurControl = localStorage.getItem('fournisseur');
    this.deptControl = localStorage.getItem('dept');
    this.rayonControl = localStorage.getItem('rayon');
    setTimeout(() => this.getQuestions(), 500);
    this.getUserWorkflow();
    this.getAllFournisseurMonoRayon();
    /*this.fetchCountries();
    this.fetchOsVersions();*/
  }

  fetchCountries() {
    this.http.get<any>('../../../../assets/data/mock/countries.json').subscribe(res => {
      this.countries = res;
    });
  }

  getRayons() {
    this.rayons = [];
    this.attachmentService.getRayons(this.fournisseurControl, this.deptControl, this.username).subscribe(response => {
      if (!response || !response.length) {
      } else {
        response.forEach(rayon => {
          this.rayons.push(rayon);
        });
      }
    });
  }

  getDepts() {
    this.depts = [];
    this.attachmentService.getDepts(this.fournisseurControl, this.username).subscribe(response => {
      response.forEach(dept => {
        this.depts.push(dept);
      });
    });
  }

  getFornisseurs() {
    this.attachmentService.getFornisseurs(this.username).subscribe(response => {
      response.forEach(fournisseur => {
        this.fournisseurs.push(fournisseur.fournisseur);
      });
      this.fournisseurs.sort(frn => frn);
    });
  }

  fetchOsVersions() {
    this.http.get<any>('../../../../assets/data/mock/os-versions.json').subscribe(res => {
      this.osVersions = res;
    });
  }

  isFormulaireValid() {
    if (!this.isRayonValid()) {
      return false;
    } else if (this.yearControl == '0') {
      return false;
    } else {
      return this.fournisseurControl != '0';
    }
  }

  yearChanged() {
    console.log(this.yearControl);
  }

  chargeYears() {
    this.currentYear = new Date().getFullYear();
    for (let i = 2021; i < this.currentYear + 2; i++) {
      this.years.push(i);
    }
  }

  isRayonValid() {
    return this.rayonControl != '0';
  }

  isFournisValid() {
    return this.fournisseurControl != '0';
  }

  isdeptValid() {
    return this.deptControl != '0';
  }

  rayonChanged() {
    localStorage.setItem('rayon', this.rayonControl);
    this.questions = [];
    //this.getFornisseurs();
  }

  getQuestions() {
    this.questions = [];
    this.questionsGrouped = [];
    this.questionsGroupedQuestions = [];
    let obData = {
      fournisseur: this.fournisseurControl,
      rayon: this.rayonControl,
      annee: this.yearControl,
    };
    this.attachmentService.getCommentsLead(obData).subscribe(response => {
      response.forEach(comment => {
        comment.type = 'croissance';
        this.questions.push(comment);
      });
      while (this.questions.length > 0) {
        this.questionsGrouped.push({
          title: this.questions[0].partie,
          list: this.questions.filter(qs => qs.partie == this.questions[0].partie)
        });
        /*for (let i = 0; i < this.questionsGrouped[this.questionsGrouped.length - 1].list.length; i++) {*/
        /*}*/
        this.removeItemAll(this.questions, this.questions[0].partie);
        /*console.log('questionsGrouped', this.questionsGrouped);
        console.log('questions', this.questions);*/
      }
      /*console.log('FinalQuestionsGrouped', this.questionsGrouped);
      console.log('FinalQuestions', this.questions);*/
      this.forEachAfterSubscribe(0, '', 0);
      // this.groupToQuestionGroupedQuestions();
      /*let indexPostureNego = this.questionsGrouped.findIndex(qs => qs.title == 'Posture de négociation');
      let postureNegoObject = this.questionsGrouped[indexPostureNego];
      this.questionsGrouped.splice(indexPostureNego, 1);
      this.questionsGrouped.push(postureNegoObject);*/
    });
  }

  private forEachAfterSubscribe(index: number, tempQ: string, indexPartie) {
    if (index < this.questionsGrouped[indexPartie].list.length) {
      let lqg = this.questionsGrouped[indexPartie].list[index];
      if (tempQ != lqg.question) {
        tempQ = lqg.question;
        lqg.isConclused = true;
        lqg.conclusion = this.questionsGrouped[indexPartie].list.filter(qg => qg.question == lqg.question).map(qg => qg.reponse).join('\n');
        let obSynthese = {
          fournisseur: this.fournisseurControl,
          annee: this.yearControl,
          question: lqg.question,
          partie: this.questionsGrouped[indexPartie].title
        };
        this.getSyntheseLeadFunction(obSynthese, lqg, index, tempQ, indexPartie);
      } else {
        index++;
        this.forEachAfterSubscribe(index, tempQ, indexPartie);
      }
    } else {
      indexPartie++;
      if (indexPartie < this.questionsGrouped.length) {
        this.forEachAfterSubscribe(0, '', indexPartie);
      }
    }
  }

  getSyntheseLeadFunction(obSynthese, lqg, index, tempQ, indexPartie) {
    this.attachmentService.getSyntheseLead(obSynthese).subscribe(res => {
      index++;
      this.forEachAfterSubscribe(index, tempQ, indexPartie);
      if (res && res.length && res[0].reponse != null) {
        lqg.conclusion = res[0].reponse;
      }
    });
  }

  groupToQuestionGroupedQuestions() {
    this.questionsGrouped.forEach(qg => {
      this.questionsGroupedQuestions = [];
      while (qg.list.length > 0) {
        this.questionsGroupedQuestions.push({
          title: qg.list[0].question,
          list: qg.list.filter(qs => qs.question == qg.list[0].question)
        });
        this.removeItemAllQuestions(qg.list, qg.list[0].question);
      }
      qg.list.push(this.questionsGroupedQuestions);
    });
    console.log('questionsGroupedQuestions', this.questionsGroupedQuestions);
    console.log('questionsGrouped', this.questionsGrouped);
  }

  getPostures() {
    this.postures = [];
    let obData = {
      fournisseur: this.fournisseurControl,
      annee: this.yearControl,
      issfamille: this.issfamille
    };
    this.attachmentService.getPostures(obData, this.username).subscribe(response => {
      response.forEach(comment => {
        this.postures.push(comment);
      });
    });
  }

  showEditDialog(question: any) {
    let obData = {
      fournisseur: this.fournisseurControl,
      rayon: this.rayonControl,
      annee: this.yearControl,
      partie: question.partie,
      question: question.question,
    };
    this.attachmentService.getCommentlastyear(obData).subscribe(response => {
      Swal.fire({
        title: '<strong></strong>',
        html:
          '<label for="swal2-input" class="swal2-input-label">Commentaire Année ' + (this.yearControl - 1) + '</label>' +
          '<textarea class="swal2-textarea disabled" style="display: flex;width: 88%;">' + ((response && response.reponse) ? response.reponse : 'Commentaire année précedente') + '</textarea>',
        input: 'textarea',
        inputLabel: 'Commentaire',
        inputValue: question.reponse,
        inputPlaceholder: 'Tapez votre commentaire ici...',
        inputAttributes: {
          'aria-label': 'Tapez votre commentaire ici...'
        },
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonColor: '#d5d5efde',
        focusConfirm: false,
        /*inputValidator: result => {
          if (!result)
            return 'Il faut écrire un texte!';
        },*/
        confirmButtonText:
          '<i class="fa fa-edit"></i> Modifier!',
        confirmButtonAriaLabel: 'Modifier',
        cancelButtonText:
          '<i class="fa fa-ban" style="color: black">Annuller</i>',
        cancelButtonAriaLabel: 'Annuller'
      }).then(response => {
        console.log(response);
        if (response.isConfirmed) {
          let obData = {
            fournisseur: this.fournisseurControl,
            rayon: this.rayonControl,
            annee: this.yearControl,
            partie: question.partie,
            question: question.question,
            reponse: response.value,
            lastUserEdit: this.user.name
          };
          this.attachmentService.editComment(obData).subscribe(response => {
            Swal.fire({
              title: 'Modifié!', text: 'Votre questionnaire a été Modifié.', icon: 'success', timer: 1500,
              timerProgressBar: true, showConfirmButton: false
            });
            this.getQuestions();
          });
        }
      });
    });
  }

  showEditDialogConclus(question: any) {
    Swal.fire({
      title: '<strong></strong>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: question.conclusion,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      showDenyButton: true,
      denyButtonColor: '#d5d5efde',
      cancelButtonColor: '#f0f8ff',
      focusConfirm: false,
      /*inputValidator: result => {
        if (!result)
          return 'Il faut écrire un texte!';
      },*/
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      denyButtonAriaLabel: 'Annuller',
      denyButtonText:
        '<i class="fa fa-ban" style="color: black"> Annuller</i>',
      cancelButtonText:
        '<i class="fa fa-info" style="color: black"> Detail historique</i>',
      cancelButtonAriaLabel: 'Detail'
    }).then(res => {
      console.log(res);
      if (res.isConfirmed) {
        let obData = {
          fournisseur: this.fournisseurControl,
          annee: this.yearControl,
          partie: question.partie,
          question: question.question,
          reponse: res.value,
          lastUserEdit: this.username
        };
        this.attachmentService.editSynthese(obData).subscribe(res2 => {
          Swal.fire({
            title: 'Modifié!', text: 'Votre questionnaire a été Modifié.', icon: 'success', timer: 1500,
            timerProgressBar: true, showConfirmButton: false
          });
          this.getQuestions();
        });
      } else if (res.isDismissed && res.dismiss == Swal.DismissReason.cancel) {
        let obSynthese = {
          fournisseur: this.fournisseurControl,
          annee: this.yearControl,
          question: question.question,
          partie: question.partie
        };
        this.attachmentService.getSyntheseLeadArchive(obSynthese).subscribe(result => {
          this.responseArchives = result;
          Swal.fire({
            title: '<strong></strong>',
            width: 1000,
            html: (this.responseArchives && this.responseArchives.length) ? ('<table>' +
              '            <thead>' +
              '            <tr>' +
              '                <th class="normalTextTableTh" style="width: 44rem">' +
              '                    Commentaire historique' +
              '                </th>' +
              '                <th class="normalTextTableTh" style="width: 20rem">' +
              '                    Dernière modification (user)' +
              '                </th>' +
              '                <th class="normalTextTableTh" style="width: 20rem">' +
              '                    Dernière date modification' +
              '                </th>' +
              '            </tr>' +
              '            </thead>' +
              '            <tbody><tr><td style="color: transparent">_</td><td></td><td></td></tr>' + this.getTrsArchives() +
              '            </tbody>' +
              '        </table>') : ('<label class="swal2-input-label"> Aucun Commentaire historique </label>'),
          }).then(res2 => {
          });
        });
        /*let elementById = document.getElementById('parentDivToSwal');
        document.body.appendChild(elementById);*/

      }
    });
  }

  getTrsArchives() {
    let result = '';
    this.responseArchives.forEach(resArchiv => {
      result += '            <tr>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.reponse ? resArchiv.reponse : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.lastUserEdit ? resArchiv.lastUserEdit : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.lastTimeEdit ? resArchiv.lastTimeEdit : '_') +
        '                </td>' +
        '            </tr>';
    });
    return result;
  }

  showEditDialogPosture(question: any) {
    Swal.fire({
      title: '<strong></strong>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: question.reponse,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d5d5efde',
      focusConfirm: false,
      /*inputValidator: result => {
        if (!result)
          return 'Il faut écrire un texte!';
      },*/
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      cancelButtonText:
        '<i class="fa fa-ban" style="color: black">Annuller</i>',
      cancelButtonAriaLabel: 'Annuller'
    }).then(response => {
      console.log(response);
      if (response.isConfirmed) {
        let obData = {
          fournisseur: this.fournisseurControl,
          departement: question.departement,
          rayon: question.rayon,
          famille: question.famille,
          sfamille: question.sfamille,
          reponse: response.value,
          annee: this.yearControl,
          issfamille: this.issfamille
        };
        this.attachmentService.editPosture(obData).subscribe(response => {
          Swal.fire({
            title: 'Modifié!', text: 'Votre posture a été Modifié.', icon: 'success', timer: 1500,
            timerProgressBar: true, showConfirmButton: false
          });
          this.getPostures();
        });
      }
    });
  }

  controlChangedFournisseur() {
    this.deptControl = '0';
    this.rayonControl = '0';
    this.questions = [];
    this.postures = [];
    localStorage.setItem('fournisseur', this.fournisseurControl);
    this.getDepts();
  }

  controlChangedYear() {
    localStorage.setItem('year', this.yearControl);
    this.questions = [];
    this.postures = [];
  }

  deptChanged() {
    this.rayonControl = '0';
    localStorage.setItem('dept', this.deptControl);
    this.getRayons();
  }

  editPosture(question: any, maintien: string) {
    let obData = {
      fournisseur: this.fournisseurControl,
      departement: question.departement,
      rayon: question.rayon,
      famille: question.famille,
      sfamille: question.sfamille,
      status: (question.status == maintien) ? null : maintien,
      annee: this.yearControl,
      issfamille: this.issfamille
    };
    this.attachmentService.editPostureStatut(obData).subscribe(response => {
      Swal.fire({
        title: 'Modifié!', text: 'Votre posture a été Modifié.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
      this.getPostures();
    });
  }

  removeIndexAfterListed(indes) {
    let index = this.questions.findIndex(qs => qs.partie);
    if (index > -1) { // only splice array when item is found
      this.questions.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i].partie === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  removeItemAllQuestions(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i].question === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  get getNumber() {
    return Math.random();
  }

  getConclusedLength(list, i: number) {
    let result = 1;
    for (let j = i + 1; j < list.length; j++) {
      if (list[j].question == list[i].question) {
        result++;
      }
    }
    return result;
  }

  getUserWorkflow() {
    this.attachmentService.getUserWorkflow().subscribe(response => {
      this.userWorkflow = response;
      this.getCanFinaliseVariable();
    });
  }

  getCanFinaliseVariable() {
    this.attachmentService.canFinaliseLead(this.fournisseurControl, this.yearControl).subscribe(response => {
      this.canFinaliseVariable = response;
    });
  }

  canFinalise() {

    return this.canFinaliseVariable;
  }

  finalisePlaybook() {
    this.attachmentService.finalisePlaybookLead(this.fournisseurControl, this.yearControl).subscribe(response => {
      Swal.fire({
        title: 'Finalisé!', text: 'Ce playbook a été finalisé.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
      this.ngOnInit();
    });
  }

  canValideChef() {
    this.playbookChef = this.listePlaybookChef.find(lpc => lpc.fournisseur == this.fournisseurControl);
    return this.listePlaybookChef && this.listePlaybookChef.length;
  }

  getAllFournisseurMonoRayon() {
    this.attachmentService.getAllFournisseurMonoRayon().subscribe(response => {
      if (response && response.length) {
        this.allFournisseurMonoRayon = response;
        this.getListePlaybookChef();
      }
    });
  }

  showValiderButton() {
    if (this.playbookChef.isn1 == 'oui') {
      if (this.allFournisseurMonoRayon.includes(this.fournisseurControl)) {
        return this.playbookChef.status == 'FINALISED';
      } else {
        return this.playbookChef.status == 'FINALISEDLEAD';
      }
    }
  }

  showValiderButtonN2() {
    return this.playbookChef.isn2 == 'oui' && this.listePlaybookChef.every(lpc => lpc.fournisseur == this.fournisseurControl && lpc.status == 'VALIDATIONN+1');
  }

  showValiderButtonN3() {
    return this.playbookChef.isn3 == 'oui' && this.listePlaybookChef.every(lpc => lpc.fournisseur == this.fournisseurControl && lpc.status == 'VALIDATIONN+2');
  }

  getListePlaybookChef() {
    this.attachmentService.getListePlaybookChef().subscribe(response => {
      if (response && response.length) {
        this.listePlaybookChef = response;
      }
    });
  }

  validePlaybookN1() {
    this.attachmentService.validatePlaybookChef(this.fournisseurControl, this.yearControl, this.playbookChef.sous_dept).subscribe(response => {
      Swal.fire({
        title: 'Validé!', text: 'Ce playbook a été validé.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
      this.ngOnInit();
    });
  }

  validePlaybookN2() {
    this.attachmentService.validatePlaybookN2(this.fournisseurControl, this.yearControl).subscribe(response => {
      Swal.fire({
        title: 'Validé!', text: 'Ce playbook a été validé.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
      this.ngOnInit();
    });
  }

  validePlaybookN3() {
    this.attachmentService.validatePlaybookN3(this.fournisseurControl, this.yearControl).subscribe(response => {
      Swal.fire({
        title: 'Validé!', text: 'Ce playbook a été validé.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
      this.ngOnInit();
    });
  }
}
