<div class="page">
  <!--<app-stats></app-stats>-->
  <app-stats [isGestionListePlaybook]="true" [currentUser]="username"></app-stats>
  <main>
    <div class="panel">
      <div class="table-panel users">
        <div class="panel-title">
          Gestion Liste Playbook
        </div>
        <div class="panel-body" style="display: flex;align-items: center;justify-content: space-around;">
          <div class="row w-100">
            <div class="col-10">
              <div class="row">
                <div class="form-group col-4" style="padding: 0 0.5rem;">
                  <label class="form-label">Annee :</label>
                  <ng-select *ngIf="years&&years.length"
                             [items]="years"
                             placeholder="Selectionner l'annee"
                             [(ngModel)]="yearControl"
                             (ngModelChange)="getAllLeads();getRapportSousDept();getRapportSousDeptT2T();getRapportSousDeptAchteur()">
                  </ng-select>
                </div>
                <div class="form-group col-4" style="padding: 0 0.5rem;">
                  <label class="form-label">Tier :</label>
                  <ng-select *ngIf="tiers&&tiers.length"
                             [items]="tiers"
                             placeholder="Selectionner tier"
                             [(ngModel)]="tierControl"
                             (ngModelChange)="getRapportSousDept();getRapportSousDeptT2T();getRapportSousDeptAchteur()">
                  </ng-select>
                </div>
                <div class="form-check form-switch center-div-inside-td"
                     style="width: 13rem;position: relative;top: 2rem;left: 3rem;">
                  <input type="checkbox" class="form-check-input" [(ngModel)]="isNiveau3"
                         (ngModelChange)="getRapportSousDept()">
                  <label class="form-check-label">Niveau 3</label>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    <div class="panel">
      <div class="table-panel users">
        <div class="panel-title">
          Avancement global des négociations {{ yearControl }} à la
          date {{ currentDate.toLocaleDateString('en-GB') }}
        </div>
        <div class="panel-body" style="border: 1px solid black;border-radius: 2rem;padding: 1rem;">
          <table>
            <thead>
            <tr>
              <th style="width: 7%;font-size: larger">

              </th>
              <th style="width: 5%;text-align: center;font-size: larger">
                Tier 1
              </th>
              <th style="width: 15%;text-align: center;font-size: larger">
                Tier 2
              </th>
              <th style="width: 7%;text-align: center;font-size: larger">
                Tier 3
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of rapportTiers2">
              <td style="font-size: larger">{{ user.status }}</td>
              <td style="text-align: center;font-size: larger">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ getJustTwoNumbers(user.tier1) }}; --fill: #23bf30 ;">
                  {{getJustTwoNumbers(user.tier1)}} % <br>
                  soit {{ getJustNumber(user.tier1Real) }}/{{ getJustNumber(user.tier1Total) }}
                </div>
              </td>
              <td style="text-align: center;font-size: larger">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ getJustTwoNumbers(user.tier2) }}; --fill: #23bf30 ;">
                  {{getJustTwoNumbers(user.tier2)}} % <br>
                  soit {{ getJustNumber(user.tier2Real) }}/{{ getJustNumber(user.tier2Total) }}
                </div>
              </td>
              <td style="text-align: center;font-size: larger">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ getJustTwoNumbers(user.tier3) }}; --fill: #23bf30 ;">
                  {{getJustTwoNumbers(user.tier3)}} % <br>
                  soit {{ getJustNumber(user.tier3Real) }}/{{ getJustNumber(user.tier3Total) }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="panel-title" style="margin-top: 2rem">
          Avancement des négociations {{ yearControl }} par acheteur à la
          date {{ currentDate.toLocaleDateString('en-GB') }}
          {{ tierControl ? (' ,Vue ' + tierControl) : '' }}
        </div>
        <div class="panel-body" style="border: 1px solid black;border-radius: 2rem;padding: 1rem;">
          <table>
            <thead>

            <tr>
              <th style="width: 3%;text-align: center">
                Sous département
              </th>
              <th style="width: 3%;text-align: center">
                Acheteur
              </th>
              <th style="width: 6%;text-align: center;">
                Playbook finalisé
              </th>
              <th style="width: 6%;text-align: center;">
                Top2Top
              </th>
              <th style="width: 6%;text-align: center;">
                1er meeting
              </th>
              <th style="width: 6%;text-align: center;">
                Négociation
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of listRapportSousDeptAchteur;index as i">
              <td style="text-align: center;font-size: larger" *ngIf="getRowSpan(user,i)"
                  [rowSpan]="getRowSpan(user,i)">{{ user.sous_dept }}
              </td>
              <td style="text-align: center;font-size: larger">{{ user.achteur.split('@')[0] }}</td>
              <td style="text-align: center;font-size: larger;">
                <div class="DivFlexCustom" *ngIf="user.finalise_actual||user.finalise_prev">
                  <div [style.width]="(user.finalise_actual/user.finalise_prev*100)+'%'"
                       style="height: 2.2rem;background-color: #04d004;border-radius: 1rem 0 0 1rem;">
                  <span
                    style="color: black;position: relative;top: 0.3rem;">{{ user.finalise_actual}}</span>
                  </div>
                  <div [style.width]="((user.finalise_prev-user.finalise_actual)/user.finalise_prev*100)+'%'"
                       style="height: 2.2rem;background-color: #da0c38;border-radius: 0 1rem 1rem 0;">
                  <span
                    style="color: white;position: relative;top: 0.3rem;">{{ user.finalise_prev - user.finalise_actual}}</span>
                  </div>
                </div>
                <div class="DivFlexCustom" *ngIf="!user.finalise_actual&&!user.finalise_prev">
                  <div [style.width]="'100%'"
                       style="height: 2.2rem;background-color: white;border-radius: 1rem;">
                  <span
                    style="color: black;position: relative;top: 0.3rem;">{{ '0' }}</span>
                  </div>
                </div>
              </td>
              <td style="text-align: center;font-size: larger;">
                <div class="DivFlexCustom" *ngIf="user.t2t_prevu||user.t2t">
                  <div [style.width]="(user.t2t/user.t2t_prevu*100)+'%'"
                       style="height: 2.2rem;background-color: #04d004;border-radius: 1rem 0 0 1rem;">
                    <span style="color: black;position: relative;top: 0.3rem;">
                      {{ user.t2t}}</span>
                  </div>
                  <div [style.width]="((user.t2t_prevu-user.t2t)/user.t2t_prevu*100)+'%'"
                       style="height: 2.2rem;background-color: #da0c38;border-radius: 0 1rem 1rem 0;">
                  <span style="color: white;position: relative;top: 0.3rem;">
                    {{ user.t2t_prevu - user.t2t}}</span>
                  </div>
                </div>
                <div class="DivFlexCustom" *ngIf="!user.t2t_prevu&&!user.t2t">
                  <div [style.width]="'100%'"
                       style="height: 2.2rem;background-color: white;border-radius: 1rem;">
                  <span
                    style="color: black;position: relative;top: 0.3rem;">{{ '0' }}</span>
                  </div>
                </div>
              </td>
              <td style="text-align: center;font-size: larger;">
                <div class="DivFlexCustom" *ngIf="user.first_meeting_prevu||user.first_meeting">
                  <div [style.width]="(user.first_meeting/user.first_meeting_prevu*100)+'%'"
                       style="height: 2.2rem;background-color: #04d004;border-radius: 1rem 0 0 1rem;">
                  <span
                    style="color: black;position: relative;top: 0.3rem;">{{ user.first_meeting}}</span>
                  </div>
                  <div [style.width]="((user.first_meeting_prevu-user.first_meeting)/user.first_meeting_prevu*100)+'%'"
                       style="height: 2.2rem;background-color: #da0c38;border-radius: 0 1rem 1rem 0;">
                  <span
                    style="color: white;position: relative;top: 0.3rem;">{{ user.first_meeting_prevu - user.first_meeting}}</span>
                  </div>
                </div>
                <div class="DivFlexCustom" *ngIf="!user.first_meeting_prevu&&!user.first_meeting">
                  <div [style.width]="'100%'"
                       style="height: 2.2rem;background-color: white;border-radius: 1rem;">
                  <span
                    style="color: black;position: relative;top: 0.3rem;">{{ '0' }}</span>
                  </div>
                </div>
              </td>
              <td style="text-align: center;font-size: larger;">
                <div class="DivFlexCustom" *ngIf="user.fin_nego_prevu||user.fin_nego">
                  <div [style.width]="(user.fin_nego/user.fin_nego_prevu*100)+'%'"
                       style="height: 2.2rem;background-color: #04d004;border-radius: 1rem 0 0 1rem;">
                  <span
                    style="color: black;position: relative;top: 0.3rem;">{{ user.fin_nego}}</span>
                  </div>
                  <div [style.width]="((user.fin_nego_prevu-user.fin_nego)/user.fin_nego_prevu*100)+'%'"
                       style="height: 2.2rem;background-color: #da0c38;border-radius: 0 1rem 1rem 0;">
                  <span
                    style="color: white;position: relative;top: 0.3rem;">{{user.fin_nego_prevu - user.fin_nego}}</span>
                  </div>
                </div>
                <div class="DivFlexCustom" *ngIf="!user.fin_nego_prevu&&!user.fin_nego">
                  <div [style.width]="'100%'"
                       style="height: 2.2rem;background-color: white;border-radius: 1rem;">
                  <span
                    style="color: black;position: relative;top: 0.3rem;">{{ '0' }}</span>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="panel-title" style="margin-top: 2rem">
          Avancement T2T et 1er meeting {{ yearControl }} à la
          date {{ currentDate.toLocaleDateString('en-GB') }}
          {{ tierControl ? (' ,Vue ' + tierControl) : '' }}
        </div>
        <div class="panel-body" style="border: 1px solid black;border-radius: 2rem;padding: 1rem;">
          <table>
            <thead>

            <tr>
              <th style="width: 3%;text-align: center">
                Sous département
              </th>
              <th style="width: 6%;text-align: center;">
                Global
              </th>
              <th style="width: 6%;text-align: center;">
                Top2Top prevu á date
              </th>
              <th style="width: 6%;text-align: center;">
                Top2Top Finalisés
              </th>
              <th style="width: 10%;text-align: center">
                Top2Top Finalisés
              </th>
              <th style="width: 10%;text-align: center">
                Top2Top Retard
              </th>
              <th style="width: 4%;">

              </th>
              <th style="width: 6%;text-align: center">
                1er meeting
              </th>
              <th style="width: 6%;text-align: center">
                1er meeting Finalisés
              </th>
              <th style="width: 10%;text-align: center">
                1er meeting Finalisés
              </th>
              <th style="width: 10%;text-align: center">
                1er meeting Retard
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of listRapportSousDept2">
              <td style="text-align: center;font-size: larger">{{ user.sous_dept }}</td>
              <td style="text-align: center;font-size: larger"><span
                style="background-color: #c8bcff;padding: 0.5rem;border-radius: 1rem;width: 3rem;width: 3rem;width: 3rem;width: 3rem;width: 3rem;display: inline-block">{{ user.total }}</span>
              </td>
              <td style="text-align: center;font-size: larger"><span
                style="background-color: #c8bcff;padding: 0.5rem;border-radius: 1rem;    display: inline-block;    display: inline-block;    display: inline-block;    display: inline-block;width: 3rem;display: inline-block">{{ user.t2t_prevu }}</span>
              </td>
              <td style="text-align: center;font-size: larger"><span
                style="background-color: #c8bcff;padding: 0.5rem;border-radius: 1rem;width: 3rem;display: inline-block">{{ user.t2t }}
                /{{ user.total }}</span>
              </td>
              <td style="text-align: center;font-size: larger;display: flex;">
                <!--<div [style.width]="(user.finalise_actual/user.finalise_prev*100)+'%'"
                     style="height: 2.2rem;background-color: #04d004;border-radius: 1rem 0 0 1rem;">
                  <span
                    style="color: black;position: relative;top: 0.3rem;">{{ getJustNumber(user.finalise_actual / user.finalise_prev * 100) + '%' }}</span>
                </div>
                <div [style.width]="((user.finalise_prev-user.finalise_actual)/user.finalise_prev*100)+'%'"
                     style="height: 2.2rem;background-color: #da0c38;border-radius: 0 1rem 1rem 0;">
                  <span
                    style="color: white;position: relative;top: 0.3rem;">{{ getJustNumber((user.finalise_prev - user.finalise_actual) / user.finalise_prev * 100) + '%' }}</span>
                </div>-->
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ (user.t2t/user.total*100) }}; --fill: #23bf30 ;">
                  {{ getJustNumber(user.t2t / user.total * 100) }}%
                </div>
              </td>
              <td style="text-align: center;font-size: larger;">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ ((user.total-user.t2t)/user.total*100) }}; --fill: #d80e2a ;">
                  {{ getJustNumber((user.total - user.t2t) / user.total * 100) }}%
                </div>
              </td>
              <td style="border-right: 1px dotted"></td>
              <td style="text-align: center;font-size: larger"><span
                style="background-color: #c8bcff;padding: 0.5rem;border-radius: 1rem;width: 3rem;display: inline-block">{{ user.first_meeting_prevu }}</span>
              </td>
              <td style="text-align: center;font-size: larger"><span
                style="background-color: #c8bcff;padding: 0.5rem;border-radius: 1rem;width: 3rem;display: inline-block">{{ user.first_meeting }}
                /{{ user.first_meeting_prevu }}</span></td>
              <!--<td style="text-align: center;font-size: larger;display: flex;">
                <div *ngIf="(user.valide_actual/user.valide_prev*100)"
                     [style.width]="(user.valide_actual/user.valide_prev*100)+'%'"
                     style="height: 2.2rem;background-color: #04d004;border-radius: 1rem 0 0 1rem;">
                  <span
                    style="color: black;position: relative;top: 0.3rem;">{{ getJustNumber(user.valide_actual / user.valide_prev * 100) + '%' }}</span>
                </div>
                <div *ngIf="((user.valide_prev-user.valide_actual)/user.valide_prev*100)"
                     [style.width]="((user.valide_prev-user.valide_actual)/user.valide_prev*100)+'%'"
                     style="height: 2.2rem;background-color: #da0c38;border-radius: 0 1rem 1rem 0;">
                  <span
                    style="color: white;position: relative;top: 0.3rem;">{{ getJustNumber((user.valide_prev - user.valide_actual) / user.valide_prev * 100) + '%' }}</span>
                </div>
                <div
                  *ngIf="!(user.valide_actual/user.valide_prev*100)&&!((user.valide_prev-user.valide_actual)/user.valide_prev*100)"
                  style="background-color: white;width: 100%;border-radius: 50rem;height: 2.2rem;border: 0.01px solid;">
                  <span style="color: black;position: relative;top: 0.3rem;">0%</span>
                </div>
              </td>-->
              <td style="text-align: center;font-size: larger;display: flex;">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ (user.first_meeting/user.first_meeting_prevu*100) }}; --fill: #23bf30 ;">
                  {{ getJustNumber(user.first_meeting / user.first_meeting_prevu * 100) + '%' }}
                </div>
              </td>
              <td style="text-align: center;font-size: larger;">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ ((user.first_meeting_prevu-user.first_meeting)/user.first_meeting_prevu*100) }}; --fill: #d80e2a ;">
                  {{ getJustNumber((user.first_meeting_prevu - user.first_meeting) / user.first_meeting_prevu * 100) + '%' }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="panel-title" style="margin-top: 2rem;">
          Status playbook {{ yearControl }} par Tier à la date {{ currentDate.toLocaleDateString('en-GB') }}
        </div>
        <div class="panel-body" style="border: 1px solid black;border-radius: 2rem;padding: 1rem;">
          <table>
            <thead>
            <tr>
              <th style="width: 7%;font-size: larger">
              </th>
              <th style="width: 5%;text-align: center;font-size: larger">
                Tier 1
              </th>
              <th style="width: 15%;text-align: center;font-size: larger">
                Tier 2
              </th>
              <th style="width: 7%;text-align: center;font-size: larger">
                Tier 3
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of rapportTiers">
              <td style="font-size: larger">{{ user.status }}</td>
              <td style="text-align: center;font-size: larger">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ getJustTwoNumbers(user.tier1) }}; --fill: #23bf30 ;">
                  {{getJustTwoNumbers(user.tier1)}} % <br>
                  soit {{ getJustNumber(user.tier1Real) }}/{{ getJustNumber(user.tier1Total) }}
                </div>
              </td>
              <td style="text-align: center;font-size: larger">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ getJustTwoNumbers(user.tier2) }}; --fill: #23bf30 ;">
                  {{getJustTwoNumbers(user.tier2)}} % <br>
                  soit {{ getJustNumber(user.tier2Real) }}/{{ getJustNumber(user.tier2Total) }}
                </div>
              </td>
              <td style="text-align: center;font-size: larger">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ getJustTwoNumbers(user.tier3) }}; --fill: #23bf30 ;">
                  {{getJustTwoNumbers(user.tier3)}} % <br>
                  soit {{ getJustNumber(user.tier3Real) }}/{{ getJustNumber(user.tier3Total) }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="panel-title" style="margin-top: 2rem">
          Status playbook {{ yearControl }} par Sous département  à la
          date {{ currentDate.toLocaleDateString('en-GB') }}
          {{ tierControl ? (' ,Vue ' + tierControl) : '' }}{{ isNiveau3 ? ' ,Validé N+3' : '' }}
        </div>
        <div class="panel-body" style="border: 1px solid black;border-radius: 2rem;padding: 1rem;">
          <table>
            <thead>

            <tr>
              <th style="width: 3%;text-align: center">
              </th>
              <th style="width: 6%;text-align: center;">
                Global
              </th>
              <th style="width: 6%;text-align: center;">
                Á<br> finaliser
              </th>
              <th style="width: 6%;text-align: center;">
                Finalisés
              </th>
              <th style="width: 10%;text-align: center">
                Finalisés
              </th>
              <th style="width: 10%;text-align: center">
                Retard
              </th>
              <th style="width: 4%;">

              </th>
              <th style="width: 6%;text-align: center">
                Á<br> valider
              </th>
              <th style="width: 6%;text-align: center">
                Validés
              </th>
              <th style="width: 10%;text-align: center">
                Validés
              </th>
              <th style="width: 10%;text-align: center">
                Retard
              </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of listRapportSousDept">
              <td style="text-align: center;font-size: larger">{{ user.sous_dept }}</td>
              <td style="text-align: center;font-size: larger"><span
                style="background-color: #c8bcff;padding: 0.5rem;border-radius: 1rem;width: 3rem;width: 3rem;width: 3rem;width: 3rem;width: 3rem;display: inline-block">{{ user.total }}</span>
              </td>
              <td style="text-align: center;font-size: larger"><span
                style="background-color: #c8bcff;padding: 0.5rem;border-radius: 1rem;    display: inline-block;    display: inline-block;    display: inline-block;    display: inline-block;width: 3rem;display: inline-block">{{ user.finalise_prev }}</span>
              </td>
              <td style="text-align: center;font-size: larger"><span
                style="background-color: #c8bcff;padding: 0.5rem;border-radius: 1rem;width: 3rem;display: inline-block">{{ user.finalise_actual }}
                /{{ user.finalise_prev }}</span>
              </td>
              <td style="text-align: center;font-size: larger;display: flex;">
                <!--<div [style.width]="(user.finalise_actual/user.finalise_prev*100)+'%'"
                     style="height: 2.2rem;background-color: #04d004;border-radius: 1rem 0 0 1rem;">
                  <span
                    style="color: black;position: relative;top: 0.3rem;">{{ getJustNumber(user.finalise_actual / user.finalise_prev * 100) + '%' }}</span>
                </div>
                <div [style.width]="((user.finalise_prev-user.finalise_actual)/user.finalise_prev*100)+'%'"
                     style="height: 2.2rem;background-color: #da0c38;border-radius: 0 1rem 1rem 0;">
                  <span
                    style="color: white;position: relative;top: 0.3rem;">{{ getJustNumber((user.finalise_prev - user.finalise_actual) / user.finalise_prev * 100) + '%' }}</span>
                </div>-->
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ (user.finalise_actual/user.finalise_prev*100) }}; --fill: #23bf30 ;">
                  {{ (user.finalise_actual / user.finalise_prev * 100) }}%
                </div>
              </td>
              <td style="text-align: center;font-size: larger;">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ ((user.finalise_prev-user.finalise_actual)/user.finalise_prev*100) }}; --fill: #d80e2a ;">
                  {{ ((user.finalise_prev - user.finalise_actual) / user.finalise_prev * 100) }}%
                </div>
              </td>
              <td style="border-right: 1px dotted"></td>
              <td style="text-align: center;font-size: larger"><span
                style="background-color: #c8bcff;padding: 0.5rem;border-radius: 1rem;width: 3rem;display: inline-block">{{ user.valide_prev }}</span>
              </td>
              <td style="text-align: center;font-size: larger"><span
                style="background-color: #c8bcff;padding: 0.5rem;border-radius: 1rem;width: 3rem;display: inline-block">{{ user.valide_actual }}
                /{{ user.valide_prev }}</span></td>
              <!--<td style="text-align: center;font-size: larger;display: flex;">
                <div *ngIf="(user.valide_actual/user.valide_prev*100)"
                     [style.width]="(user.valide_actual/user.valide_prev*100)+'%'"
                     style="height: 2.2rem;background-color: #04d004;border-radius: 1rem 0 0 1rem;">
                  <span
                    style="color: black;position: relative;top: 0.3rem;">{{ getJustNumber(user.valide_actual / user.valide_prev * 100) + '%' }}</span>
                </div>
                <div *ngIf="((user.valide_prev-user.valide_actual)/user.valide_prev*100)"
                     [style.width]="((user.valide_prev-user.valide_actual)/user.valide_prev*100)+'%'"
                     style="height: 2.2rem;background-color: #da0c38;border-radius: 0 1rem 1rem 0;">
                  <span
                    style="color: white;position: relative;top: 0.3rem;">{{ getJustNumber((user.valide_prev - user.valide_actual) / user.valide_prev * 100) + '%' }}</span>
                </div>
                <div
                  *ngIf="!(user.valide_actual/user.valide_prev*100)&&!((user.valide_prev-user.valide_actual)/user.valide_prev*100)"
                  style="background-color: white;width: 100%;border-radius: 50rem;height: 2.2rem;border: 0.01px solid;">
                  <span style="color: black;position: relative;top: 0.3rem;">0%</span>
                </div>
              </td>-->
              <td style="text-align: center;font-size: larger;display: flex;">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ (user.valide_actual/user.valide_prev*100) }}; --fill: #23bf30 ;">
                  {{ getJustNumber(user.valide_actual / user.valide_prev * 100) + '%' }}
                </div>
              </td>
              <td style="text-align: center;font-size: larger;">
                <div class="semi-donut-model-2 margin"
                     style="--percentage : {{ ((user.valide_prev-user.valide_actual)/user.valide_prev*100) }}; --fill: #d80e2a ;">
                  {{ getJustNumber((user.valide_prev - user.valide_actual) / user.valide_prev * 100) + '%' }}
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</div>
