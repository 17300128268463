<!-- Sidebar Holder -->
<nav id="sidebar" class="doNotPrint" [ngClass]="{'folded': this.isFolded}">
  <div class="sidebar-header">
    <div class="content">
      <!--<div class="nav_profile" style="border: 1px solid #0482c2;border-radius: 2rem;padding: 0.5rem;">
        <div class="row" style="margin: auto">
          <a class="pull-left profile-thumb" style="width: 100%;text-align: center;">
            <img [src]="(user &&user.avatar)?user.avatar : './assets/media/images/nego.png'" class="rounded-circle" alt="User Image">
          </a>
        </div>
        <div class="row">
          <h4 class="media-heading" style="text-align:center; width: 100%;">
            &lt;!&ndash; {{user ? user?.firstname : 'Avast'}}{{" "}}{{user ? user?.lastname : 'Avast'}}&ndash;&gt;
            {{"title" | translate}}
            <a class="user-balance" style="border: 1px solid #0482c2;border-radius: 2rem;padding: 0.5rem;"><b>
              NÉGO </b>PLAYBOOK</a>
          </h4>
        </div>
        &lt;!&ndash; <p class="role"  style="margin-left: 26%">{{user?.profile?.title ? user?.profile?.title : 'unknown title'}}</p>&ndash;&gt;
      </div>-->
    </div>
  </div>
  <ul class="list-unstyled components">
    <li *ngFor="let x of menu" routerLinkActive="active" dropdown [class.disabledMenu]="x.gris"> <!-- isOpen="true"> -->
      <a *ngIf="x.submenu.length <= 0"
         routerLink="{{x.action}}"
         [class.disabled]="x.disabled">
        <i class="menu-icon fa fa-fw {{x.icon}}"></i>
        <span>{{x.i18n}}</span>
      </a>
      <a *ngIf="x.submenu.length > 0" dropdownToggle class="has-submenu" [class.disabled]="x.disabled">
        <i class="menu-icon fa fa-fw {{x.icon}}"></i>
        <span>{{x.i18n}}</span>
      </a>
      <ul *dropdownMenu class="sub-menu list-unstyled">
        <li *ngFor="let y of x.submenu" routerLinkActive="active">
          <a routerLink="{{y.action}}" [class.disabled]="x.disabled">
            <i class="menu-icon fa fa-fw {{y.icon}}"></i>
            <span>{{y.i18n}}</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
