<div class="page">
  <app-stats [isForTelegram]="true"></app-stats>
  <main>
    <div class="panels grid">
      <div class="table-panel countries">
        <div class="panel-title"></div>
        <div class="panel-body">
            <div class="form-group">
              <label>Title</label>
              <input type="email" class="form-control" aria-describedby="emailHelp"
                     placeholder="Title">
            </div>
            <div class="form-group">
              <label>Link</label>
              <input type="email" class="form-control"
                     placeholder="Link">
            </div>
            <div class="form-group">
              <label>Quantity</label>
              <input type="number" min="0" class="form-control" placeholder="Quantity">
              <small class="form-text text-muted">Min : 1000 - Max : 1000000</small>
            </div>
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="isAccepted">
              <label class="form-check-label" for="exampleCheck1">Accept</label>
            </div>
            <button class="btn btn-primary" style="width: 100%;color: white;background-color: #2fcae3" [disabled]="!isAccepted">Submit</button>
        </div>
      </div>
      <div class="table-panel users">
        <div class="panel-title">
          Control panel users
        </div>
        <div class="panel-body">
          <table>
            <thead>
            <tr>
              <th>
                Username
              </th>
              <th>
                Privilege
              </th>
              <th>
                Last login
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Admin</td>
              <td><span>Admin User</span></td>
              <td>2020-05-18 06:55:28</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</div>
