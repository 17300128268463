<div class="nav-container">
  <img class="logo-navbar" src="./assets/media/logo2.png"/>
  <div class="toggler-container" [ngClass]="{'open': !isSidebarFolded}" (click)="doToggleSidebar()">
    <div *ngIf="user" class="burger-icon">
      <span class="burger-layer"></span>
      <span class="burger-layer"></span>
      <span class="burger-layer"></span>
    </div>
  </div>
  <!-- <nav> </nav> -->

  <div class="user-actions-container">
    <p style="display: contents">{{this.username}}</p>
    <!--<div class="top-menu-container" [ngClass]="{'hide-top-menu-container':user || isSidebarFolded}">-->
    <div class="top-menu-container">
      <div *ngFor="let x of topMenu; last as isLast" routerLinkActive="activeFooter">
        <a class="menu text-nowrap" [ngClass]="{'login-menu': false && isLast}"
           routerLink="{{x.action}}">
          <span>{{x.i18n}}</span>
        </a>
      </div>
    </div>
    <div *ngIf="!user" class="toggle-menu user-actions" [ngClass]="{'open':dropdownOpen}">
      <button class="dropdown-toggler" (click)="toggleDropdown()">
        <i class="dropdown-icon fa fa-bars"></i>
      </button>
      <div class="actions-dropdown" [ngClass]="{'open': this.dropdownOpen}">
        <ul style="padding-right: 1rem;">
          <!--
                    <li *ngFor="let x of topMenu; let i = index;" ><a *ngIf="i!=0" class="li-toggle" [ngClass]="{'login-menu': i==topMenu.length-1}" routerLink="{{x.action}}"> {{x.i18n | translate}}</a></li>
          -->
        </ul>
      </div>
    </div>
    <div *ngIf="user" class="user-actions" [ngClass]="{'open':dropdownOpen}">
      <div class="user-icon" (click)="toggleDropdown()">
        <img [src]="(user&&user.avatar)? user?.avatar : './assets/media/favicon2.ico'" class="rounded-circle"
             alt="User Image">
      </div>
      <div class="actions-dropdown" [ngClass]="{'open': this.dropdownOpen}">
        <ul style="padding-right: 1rem;">
          <div class="user-card">
            <li><a class="user-id">
              <img [src]="(user&&user.avatar)? user?.avatar : './assets/media/favicon2.ico'"
                   class="rounded-circle"
                   alt="User Image">
              {{this.username}}
            </a></li>
          </div>
          <!-- <li class="li-toggle"><a href="#"><i style="font-size: 1.3rem;" class="fa fa-cogs"></i>{{"top-menu.settings" | translate}}</a></li>
           <li class="li-toggle"><a href="#"><i style="font-size: 1.3rem;" class="fa fa-lightbulb-o"></i>{{"top-menu.help" | translate}}</a></li>-->
          <li class="li-toggle"><a (click)="this.logout()"><i style="font-size: 1.3rem;color: #852c2ccf;"
                                                              class="fa fa-sign-out"></i>{{"Déconnecter"}}
          </a></li>
        </ul>
        <div class="toggle-footer">
          <span *ngFor="let x of topMenu; let i = index;" routerLinkActive="activeFooter"><a class="toggle-menu-footer"
                                                                                             routerLink="{{x.action}}">{{x.i18n}} </a><span
            *ngIf="i!=topMenu.length-1">|</span></span>
        </div>
      </div>
    </div>
  </div>
</div>
