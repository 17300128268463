<div class="page">
    <!--<app-stats></app-stats>-->
    <app-stats [isForVueFournisseur]="true" [currentYear]="currentYear"
               [currentFournisseur]="currentFournisseur"></app-stats>
    <main>
        <div class="panel">
            <div class="table-panel countries">
                <div style="display: flex">
                    <div class="panel-title" style="width: 80%">Initiatives Commerciales</div>

                </div>
                <div class="panel-body">
                    <div style="display: flex">
                        <div style="border-bottom: 1px solid #7a767682;margin-bottom: 1rem;padding-bottom: 1rem;width: 100%">
                            <div style="display: flex">
                                <div style="width: 50%;text-align: center">
                                    <span style="font-size: 12px">*Glisser et déplacer la ligne l'ordre d'importance du fournisseur</span>
                                    <table class="table-responsive">
                                        <tbody>
                                        <tr *ngFor="let rank of rakingList;index as i" (drop)="drop($event,rank,i)"
                                            style="cursor: grab"
                                            draggable="true" (dragstart)="drag($event,rank,i)"
                                            (dragover)="allowDrop($event)">
                                            <td class="text-center">
                                                <span style="color: #e0919a"><i class="fa fa-bars"></i></span>
                                            </td>
                                            <td class="normalTextTable">
                                                <input type="text" style="background: #f7eeef;" disabled
                                                       class="form-control controlGris" value="{{rank}}">
                                            </td>
                                            <td class="normalTextTable" style="width: 5rem">
                                                <input type="number" min="0" class="form-control" disabled
                                                       value="{{i+1}}">
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div style="width: 50%;text-align: center">
                                    <button class="btn btn-primary flexAutoMarge" *ngIf="!isShowRankingLastYear"
                                            style="color: white;background-color: #b22a2e;height: 3.3rem"
                                            (click)="showRankingLastYear()">
                                        Afficher l'année précedente
                                    </button>
                                    <span style="font-size: 12px" *ngIf="isShowRankingLastYear">*Initiatives Commerciales ({{lastYear}}
                                        )</span>
                                    <table class="table-responsive" *ngIf="isShowRankingLastYear">
                                        <tbody>
                                        <tr *ngFor="let rank of rakingLastYear;index as i">
                                            <td class="text-center">
                                                <span><i class="fa fa-bars"></i></span>
                                            </td>
                                            <td class="normalTextTable">
                                                <input type="text" disabled class="form-control controlGris"
                                                       value="{{rank}}">
                                            </td>
                                            <td class="normalTextTable" style="width: 5rem">
                                                <input type="number" min="0" class="form-control" disabled
                                                       value="{{i+1}}">
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="panel-title" style="width: 80%">Plan de croissance</div>
                    <div class="panel-title panel-title-cstm-rbb"
                         style="width: 80%;padding-bottom: 0;font-size: 0.9rem">Levier 1
                    </div>
                    <div class="panel-title panel-title-cstm">Blocs de construction du chiffre</div>
                    <div style="display: flex">
                        <div>
                            <table class="table-responsive">
                                <tbody>
                                <tr>
                                    <td></td>
                                    <td style="display: flex;justify-content: center;"><span class="titlePC2">Historique N-1</span>
                                    </td>
                                    <td class="normalTextTable">
                                        <label>CA en MDhs</label>
                                        <input type="number" min="0" class="form-control" [(ngModel)]="lastCa"
                                               (ngModelChange)="changeCa()">
                                    </td>
                                    <td class="normalTextTable">
                                    </td>
                                </tr>
                                <tr *ngFor="let demande of demandes; index as i">
                                    <td class="normalTextTable"><label *ngIf="i==0" style="margin-bottom: 0.5rem;">Rayon</label>
                                        <input type="text" disabled class="form-control" value="{{demande.rayon}}"></td>
                                    <td class="normalTextTable"><label *ngIf="i==0" style="margin-bottom: 0.5rem;">Famille</label>
                                        <input type="text" disabled class="form-control" value="{{demande.famille}}">
                                    </td>
                                    <td class="normalTextTable">
                                        <label *ngIf="i==0" style="margin-bottom: 0.5rem;"></label>
                                        <input type="number" min="0" class="form-control saisie_c"
                                               [(ngModel)]="demande.ca" (ngModelChange)="changeCa()">
                                    </td>
                                    <td class="normalTextTable" style="width: 36rem;">
                                        <label *ngIf="i==0" style="color: transparent;margin-bottom: 0.5rem">.</label>
                                        <div class="cursor-pointer" style="border: #dee2e6 1px solid;text-align: start;padding: 0.5rem;"
                                             [class.textCommentRose]="true"
                                             (click)="showEditDialogPosture(demande)">
                      <span style="line-height: 1.5;font-size: 14px;box-sizing: border-box;text-decoration: none;list-style: none">
                        {{demande.argument}}
                      </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td style="display: flex;justify-content: center;"><span
                                            class="titlePC2">Total</span></td>
                                    <td class="normalTextTable">
                                        <label></label>
                                        <input type="number" min="0" disabled class="form-control"
                                               [(ngModel)]="totalCA">
                                    </td>
                                    <td class="normalTextTable">
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style="display: flex;margin: 1rem 0;">
                        <div class="panel-title panel-title-cstm-rbb" style="width: 20%">Levier 2</div>
                        <div style="width: 100%">
                            <div class="cursor-pointer" style="min-height: 3rem;border: #dee2e6 1px solid;text-align: start;padding: 0.5rem;"
                                 [class.textCommentRose]="true"
                                 (click)="showEditJustCommentL2()">
                <span style="line-height: 1.5;font-size: 14px;box-sizing: border-box;text-decoration: none;list-style: none;">
                        {{commentL2}}
                      </span>
                            </div>
                        </div>
                    </div>

                    <div style="display: flex;margin: 1rem 0;">
                        <div class="panel-title panel-title-cstm-rbb" style="width: 20%">Levier 3</div>
                        <div style="width: 100%">
                            <div class="cursor-pointer" style="min-height: 3rem;border: #dee2e6 1px solid;text-align: start;padding: 0.5rem;"
                                 [class.textCommentRose]="true"
                                 (click)="showEditJustCommentL3()">
                <span style="line-height: 1.5;font-size: 14px;box-sizing: border-box;text-decoration: none;list-style: none;">
                        {{commentL3}}
                      </span>
                            </div>
                        </div>
                    </div>
                    <div style="width: 100%;overflow: scroll;margin: 3rem 0">
                        <div class="panel-title" style="width: 80%">Plan de Décroissance</div>
                        <div style="display: flex">

                        </div>
                        <div style="display: flex;width: 150%">
                            <div class="triangleCss"></div>
                            <table class="table-responsive" style="width: 70%">
                                <thead>
                                <tr>
                                    <td colspan="6" style="color: transparent">.</td>
                                </tr>
                                <tr>
                                    <td class="normalTextTable" style="width: 4rem">Etape</td>
                                    <td class="normalTextTable" style="width: 4rem">Impact</td>
                                    <td class="normalTextTable" style="width: 10rem">Action</td>
                                    <td class="normalTextTable" >Description</td>
                                    <td class="normalTextTable" style="width: 8rem;">Date de début</td>
                                    <td class="normalTextTable" style="width: 5rem">Durée (J)</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let demande of listDecroissance; index as i">
                                    <td style="text-align: center">{{demande.etape}}</td>
                                    <td style="text-align: center">{{demande.impact}}</td>
                                    <td class="normalTextTable">
                                        <!--<select class="form-control" [(ngModel)]="demande.listAction"
                                                (change)="listActionChanged(demande)">
                                            <option disabled value="0">Selectionner une action</option>
                                            <option *ngFor="let action of actions" value="{{action}}">{{action}}</option>
                                        </select>-->
                                        <ng-select [items]="actions" style="width: 90%;margin: auto;"
                                                   [addTag]="true"
                                                   [addTagText]="'Ajouter autre action'"
                                                   [(ngModel)]="demande.action">
                                        </ng-select>
                                        <!--<input *ngIf="false" type="text" class="form-control saisie_c" [(ngModel)]="demande.action">-->
                                    </td>
                                    <td class="normalTextTable" style="padding: 0 1rem;">
                                        <input type="text" class="form-control saisie_c"
                                               [(ngModel)]="demande.description">
                                    </td>
                                    <td class="normalTextTable">
                                        <input type="date" class="form-control" [(ngModel)]="demande.datedebut">
                                    </td>
                                    <td class="normalTextTable">
                                        <input type="number" min="0" class="form-control text-center"
                                               [(ngModel)]="demande.duree">
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <table class="table-responsive" style="border-left: 1px solid #7a7a7a87;width: 30%;">
                                <thead>
                                <tr>
                                    <td colspan="3" class="normalTextTable" style="width: 10rem">Impact estimé pour LBV</td>
                                </tr>
                                <tr>
                                    <td class="normalTextTable sousthead" style="width: 3rem">Nvx</td>
                                    <td class="normalTextTable sousthead" style="width: 4rem">Val en DHS</td>
                                    <td class="normalTextTable sousthead">Description</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let demande of listDecroissance; index as i">
                                    <td class="normalTextTable">
                                        <ng-select appearance="outline" [searchable]="false" [clearable]="false"
                                                   [(ngModel)]="demande.nvxl">
                                            <ng-option value="up">
                                                <img src="assets/up.png" width="20px" height="20px"/>
                                            </ng-option>
                                            <ng-option value="normal">
                                                <img src="assets/minus.png" width="20px" height="20px"/>
                                            </ng-option>
                                            <ng-option value="down">
                                                <img src="assets/down.png" width="20px" height="20px"/>
                                            </ng-option>
                                        </ng-select>
                                        <!--<select class="form-control" style="text-align: center"
                                                [(ngModel)]="demande.nvxl">
                                            <option value="up">⬆️&emsp;</option>
                                            <option value="normal">➖&emsp;</option>
                                            <option value="down">🔻&emsp;</option>
                                        </select>-->
                                    </td>
                                    <td class="normalTextTable" style="padding: 0 1rem;">
                                        <input type="number" class="form-control saisie_c" [(ngModel)]="demande.vall">
                                    </td>
                                    <td class="normalTextTable">
                                        <input type="text" class="form-control saisie_c" [(ngModel)]="demande.descl">
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <!--<table class="table-responsive" style="border-left: 1px solid #7a7a7a87;width: 50%;">
                                <thead>
                                <tr>
                                    <td colspan="3" class="normalTextTable">Impact estimé pour fournisseur</td>
                                </tr>
                                <tr>
                                    <td class="normalTextTable sousthead" style="width: 3rem">Nvx</td>
                                    <td class="normalTextTable sousthead">Val en DHS</td>
                                    <td class="normalTextTable sousthead">Description</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let demande of listDecroissance; index as i">
                                    <td class="normalTextTable">
                                        <select class="form-control" style="text-align: center"
                                                [(ngModel)]="demande.nvxf">
                                            <option value="up">⬆️&emsp;</option>
                                            <option value="normal">➖&emsp;</option>
                                            <option value="down">🔻&emsp;</option>
                                        </select>
                                    </td>
                                    <td class="normalTextTable">
                                        <input type="number" class="form-control saisie_c" [(ngModel)]="demande.valf">
                                    </td>
                                    <td class="normalTextTable">
                                        <input type="text" class="form-control saisie_c" [(ngModel)]="demande.descf">
                                    </td>
                                </tr>
                                </tbody>
                            </table>-->
                            <table class="table-responsive" style="border-left: 1px solid #7a7a7a87;width: 50%">
                                <thead>
                                <tr>
                                    <td colspan="6" style="color: transparent">.</td>
                                </tr>
                                <tr>
                                    <td class="normalTextTable">Plan de secours</td>
                                    <td class="normalTextTable"></td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let demande of listDecroissance; index as i">
                                    <td class="normalTextTable">
                                        <input type="text" class="form-control saisie_c" [(ngModel)]="demande.plan">
                                    </td>
                                    <td class="text-center" style="display: flex">
                                        <div class="divNewPlan" (click)="removePlanDec(i,demande.impact)">
                                            <span><i class="fa fa-minus"></i></span>
                                        </div>
                                        <div class="divNewPlan" (click)="addNewPlanDec(i,demande.impact)">
                                            <span><i class="fa fa-plus"></i></span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <button class="btn btn-primary flexAutoMarge"
                            style="color: white;background-color: #b22a2e;height: 3.3rem;float: right"
                            (click)="saveDemandes()">
                        Enregistrer
                    </button>
                </div>
            </div>
        </div>
    </main>
</div>
