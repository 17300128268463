/*
Created by AYYOUB MIFTAH
*/
export class DemandeModel {
  constructor(dept, rayon, type, moment, cluster) {
    this.dept = dept;
    this.rayon = rayon;
    this.type = type;
    this.moment = moment;
    this.cluster = cluster;
    this.pup = 0;
    this.pus = 0;
    this.pua = 0;
  }

  controlCA: boolean;
  changed: boolean;
  caDisabled: boolean;
  fournisseur: string;
  dept: string;
  type: string;
  moment: string;
  cluster: string;
  departemant: string;
  rayon: string;
  famille: string;
  sousFamille: string;
  annee: string;
  demande: any;
  ca: number = 0;
  tm: number = 0;
  dct: number = 0;
  dmt: number = 0;
  argument: string;
  dcv: number = 0;
  dmv: number = 0;
  dtv: number = 0;
  dtt: number = 0;
  pup: number = 0;
  pus: number = 0;
  pua: number = 0;
}
