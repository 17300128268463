import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AttachmentService} from '../../shared/services/attachment.service';
import Swal from 'sweetalert2';
import {Utils} from '../../core/utils/Utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-rapport-tiers',
  templateUrl: './rapport-tiers.component.html',
  styleUrls: ['./rapport-tiers.component.scss']
})
export class RapportTiersComponent implements OnInit {

  countries = [];
  years = [];
  osVersions = [];
  isAccepted: any = false;
  yearControl;
  rayonControl: any = '0';
  deptControl: any = '0';
  rayons = [];
  roles = [];
  depts = [];
  fournisseurControl;
  fournisseurs = [];
  questions = [];
  sQuestions = [];
  users = [];
  currentYear: number;
  currentDate = new Date();
  username: string;
  searchFilter = '';
  userControl: any;
  roleControl: any;
  isEdit = false;
  tryCount = 3;
  rapportTiers = [];
  dateFinFinalisation: any;
  dateFinValidation: any;
  tierControl: any;
  tiers: any = [];
  niveaux = [];
  niveauControl: any;
  listRapportSousDept = [];
  isNiveau3: any;
  rapportTiers2 = [];
  listRapportSousDept2 = [];
  listRapportSousDeptAchteur = [];

  constructor(private http: HttpClient,
              private router: Router,
              private attachmentService: AttachmentService) {
    if (!attachmentService.canConnect) {
      this.router.navigate(['access-denied']);
    }
    this.testRole();
  }

  testRole() {
    if (this.attachmentService.respReturned) {
      if (this.attachmentService.role != 'ADMIN') {
        this.router.navigate(['access-denied']);
      }
    } else {
      setTimeout(() => this.testRole(), 500);
    }
  }

  ngOnInit() {
    //this.user = JSON.parse(localStorage.getItem('userObject'));
    this.username = localStorage.getItem('username');
    this.yearControl = localStorage.getItem('year');
    this.tierControl = localStorage.getItem('tier') == 'null' ? null : localStorage.getItem('tier');
    this.chargeYears();
    this.chargeTiers();
    this.getAllLeads();
    this.getRapportSousDept();
    this.getRapportSousDeptT2T();
    this.getRapportSousDeptAchteur();
  }

  getFornisseurs() {
    this.attachmentService.getFornisseurs(this.username).subscribe(response => {
      setTimeout(() => this.getAllUsers(), 500);
      response.forEach(fournisseur => {
        this.fournisseurs.push(fournisseur.fournisseur);
      });
      this.fournisseurs.sort(frn => frn);
    }, error => {
      if (this.tryCount > 0) {
        this.tryCount--;
        this.getFornisseurs();
      }
    });
  }

  getAllDeparts() {
    this.attachmentService.getAllDepts().subscribe(response => {
      setTimeout(() => this.getAllUsers(), 500);
      response.forEach(fournisseur => {
        this.fournisseurs.push(fournisseur);
      });
      this.fournisseurs.sort(frn => frn);
    });
  }

  isFormulaireValid() {
    return this.userControl && this.userControl != '' &&
      this.fournisseurControl &&
      this.yearControl;
  }

  yearChanged() {
    console.log(this.yearControl);
  }

  chargeYears() {
    this.currentYear = new Date().getFullYear();
    for (let i = 2021; i < this.currentYear + 2; i++) {
      this.years.push(i);
    }
  }

  chargeNiveaux() {
    this.niveaux.push('Niveau N+1');
    this.niveaux.push('Niveau N+2');
    this.niveaux.push('Niveau N+3');
  }

  chargeTiers() {
    this.tiers.push('Tier 1');
    this.tiers.push('Tier 2');
    this.tiers.push('Tier 3');
  }

  getAllRayon(isAdmin) {
    this.rayonControl = [];
    this.rayons = [];
    this.attachmentService.getAllRayons().subscribe(response => {
      response.forEach(comment => {
        let rayon = {};
        rayon['label'] = comment;
        this.rayons.push(rayon);
      });
      if (isAdmin) {
        this.rayonControl = this.rayons;
      }
    });
  }

  getAllUsers() {
    this.attachmentService.getAllUsers().subscribe(response => {
      this.getAllLeads();
      if (response && response.length) {
        this.users = response.map(res => res.user);
      }
    });
  }

  getAllLeads() {
    this.rapportTiers = [];
    if (this.yearControl) {
      this.attachmentService.getRapportTiers(this.yearControl).subscribe(response => {
        if (response && response.length) {
          let status = response.map(rs => rs.status);
          this.getStatutFromStatus('Finalisé', ['FINALISED', 'FINALISEDLEAD', 'VALIDATIONN+1', 'VALIDATIONN+1VALIDATED', 'VALIDATIONN+2', 'VALIDATIONN+2VALIDATED', 'VALIDATIONN+3', 'VALIDATIONN+3VALIDATED'], response);
          this.getStatutFromStatus('Validé N+1', ['VALIDATIONN+1', 'VALIDATIONN+1VALIDATED', 'VALIDATIONN+2', 'VALIDATIONN+2VALIDATED', 'VALIDATIONN+3', 'VALIDATIONN+3VALIDATED'], response);
          this.getStatutFromStatus('Validé N+2', ['VALIDATIONN+2', 'VALIDATIONN+2VALIDATED', 'VALIDATIONN+3', 'VALIDATIONN+3VALIDATED'], response);
          this.rapportTiers2 = [];
          this.getStatutFromStatus2('Validation des playbooks', ['VALIDATIONN+1VALIDATED', 'VALIDATIONN+2VALIDATED', 'VALIDATIONN+3VALIDATED'], response);
          this.getRapportTierT2T();
          //this.getStatutFromStatus('Validé niveau 3', ['VALIDATIONN+3', 'VALIDATIONN+3VALIDATED'], response);
        }
      });
    }
  }

  getRapportTierT2T() {
    if (this.yearControl) {
      this.attachmentService.getRapportTierst2t(this.yearControl).subscribe(response => {
        if (response && response.length) {
          let tier1Values = response.find(rs => rs.tier == 'Tier 1');
          if (!tier1Values) {
            tier1Values = {t2t: 0, total: 0, first_meeting: 0, fin_nego: 0};
          }
          let tier2Values = response.find(rs => rs.tier == 'Tier 2');
          if (!tier2Values) {
            tier2Values = {t2t: 0, total: 0, first_meeting: 0, fin_nego: 0};
          }
          let tier3Values = response.find(rs => rs.tier == 'Tier 3');
          if (!tier3Values) {
            tier3Values = {t2t: 0, total: 0, first_meeting: 0, fin_nego: 0};
          }
          this.rapportTiers2.push({
            status: 'Top2Top',
            tier1: tier1Values.t2t / tier1Values.total * 100,
            tier1Total: tier1Values.total,
            tier1Real: tier1Values.t2t,
            tier2: tier2Values.t2t / tier2Values.total * 100,
            tier2Total: tier2Values.total,
            tier2Real: tier2Values.t2t,
            tier3: tier3Values.t2t / tier3Values.total * 100,
            tier3Total: tier3Values.total,
            tier3Real: tier3Values.t2t
          });
          this.rapportTiers2.push({
            status: '1er rencontre',
            tier1: tier1Values.first_meeting / tier1Values.total * 100,
            tier1Total: tier1Values.total,
            tier1Real: tier1Values.first_meeting,
            tier2: tier2Values.first_meeting / tier2Values.total * 100,
            tier2Total: tier2Values.total,
            tier2Real: tier2Values.first_meeting,
            tier3: tier3Values.first_meeting / tier3Values.total * 100,
            tier3Total: tier3Values.total,
            tier3Real: tier3Values.first_meeting
          });
          this.rapportTiers2.push({
            status: 'Négociations',
            tier1: tier1Values.fin_nego / tier1Values.total * 100,
            tier1Total: tier1Values.total,
            tier1Real: tier1Values.fin_nego,
            tier2: tier2Values.fin_nego / tier2Values.total * 100,
            tier2Total: tier2Values.total,
            tier2Real: tier2Values.fin_nego,
            tier3: tier3Values.fin_nego / tier3Values.total * 100,
            tier3Total: tier3Values.total,
            tier3Real: tier3Values.fin_nego
          });
        }
      });
    }
  }

  getRapportSousDept() {
    localStorage.setItem('tier', this.tierControl);
    this.listRapportSousDept = [];
    if (this.yearControl) {
      let niv3 = this.isNiveau3 ? 'oui' : 'non';
      this.attachmentService.getRapportSousDept(this.tierControl, this.yearControl, niv3).subscribe(response => {
        if (response && response.length) {
          this.listRapportSousDept = response;
        }
      });
    }
  }

  getRapportSousDeptT2T() {
    localStorage.setItem('tier', this.tierControl);
    this.listRapportSousDept2 = [];
    if (this.yearControl) {
      this.attachmentService.getRapportSousDeptT2T(this.tierControl, this.yearControl).subscribe(response => {
        if (response && response.length) {
          this.listRapportSousDept2 = response;
        }
      });
    }
  }

  getRapportSousDeptAchteur() {
    localStorage.setItem('tier', this.tierControl);
    this.listRapportSousDeptAchteur = [];
    if (this.yearControl) {
      this.attachmentService.getRapportSousDeptAchteur(this.tierControl, this.yearControl).subscribe(response => {
        if (response && response.length) {
          this.listRapportSousDeptAchteur = response;
        }
      });
    }
  }

  getStatutFromStatus(statut, status, response) {
    let tier1List = response.filter(rs => status.indexOf(rs.status) > -1 && rs.tier == 'Tier 1');
    let tier1Total = tier1List && tier1List.length ? new Array(tier1List[0]).map(t1l => t1l.total).reduce((a, b) => a + b, 0) : 0;
    let tier1Real = tier1List && tier1List.length ? new Array(tier1List[0]).map(t1l => t1l.pourcentage).reduce((a, b) => a + b, 0) : 0;
    let tier1 = tier1List && tier1List.length ? tier1List.map(t1l => t1l.pourcentage / t1l.total * 100).reduce((a, b) => a + b, 0) : 0;
    let tier2List = response.filter(rs => status.indexOf(rs.status) > -1 && rs.tier == 'Tier 2');
    let tier2Total = tier2List && tier2List.length ? new Array(tier2List[0]).map(t2l => t2l.total).reduce((a, b) => a + b, 0) : 0;
    let tier2Real = tier2List && tier2List.length ? new Array(tier2List[0]).map(t2l => t2l.pourcentage).reduce((a, b) => a + b, 0) : 0;
    let tier2 = tier2List && tier2List.length ? tier2List.map(t2l => t2l.pourcentage / t2l.total * 100).reduce((a, b) => a + b, 0) : 0;
    let tier3List = response.filter(rs => status.indexOf(rs.status) > -1 && rs.tier == 'Tier 3');
    let tier3Total = tier3List && tier3List.length ? new Array(tier3List[0]).map(t3l => t3l.total).reduce((a, b) => a + b, 0) : 0;
    let tier3Real = tier3List && tier3List.length ? new Array(tier3List[0]).map(t3l => t3l.pourcentage).reduce((a, b) => a + b, 0) : 0;
    let tier3 = tier3List && tier3List.length ? tier3List.map(t3l => t3l.pourcentage / t3l.total * 100).reduce((a, b) => a + b, 0) : 0;
    let createdStatut = response.find(rs => rs.status == 'CREATED');
    if (createdStatut && createdStatut.pourcentage == 100) {
      tier1 = tier1 / 4;
      tier2 = tier2 / 4;
      tier3 = tier3 / 4;
    }
    this.rapportTiers.push({
      status: statut,
      tier1: tier1,
      tier1Total: tier1Total,
      tier1Real: tier1Real,
      tier2: tier2,
      tier2Total: tier2Total,
      tier2Real: tier2Real,
      tier3: tier3,
      tier3Total: tier3Total,
      tier3Real: tier3Real
    });
  }

  getStatutFromStatus2(statut, status, response) {
    let tier1List = response.filter(rs => status.indexOf(rs.status) > -1 && rs.tier == 'Tier 1');
    let tier1Total = tier1List && tier1List.length ? new Array(tier1List[0]).map(t1l => t1l.total).reduce((a, b) => a + b, 0) : 0;
    let tier1Real = tier1List && tier1List.length ? new Array(tier1List[0]).map(t1l => t1l.pourcentage).reduce((a, b) => a + b, 0) : 0;
    let tier1 = tier1List && tier1List.length ? tier1List.map(t1l => t1l.pourcentage / t1l.total * 100).reduce((a, b) => a + b, 0) : 0;
    let tier2List = response.filter(rs => status.indexOf(rs.status) > -1 && rs.tier == 'Tier 2');
    let tier2Total = tier2List && tier2List.length ? new Array(tier2List[0]).map(t2l => t2l.total).reduce((a, b) => a + b, 0) : 0;
    let tier2Real = tier2List && tier2List.length ? new Array(tier2List[0]).map(t2l => t2l.pourcentage).reduce((a, b) => a + b, 0) : 0;
    let tier2 = tier2List && tier2List.length ? tier2List.map(t2l => t2l.pourcentage / t2l.total * 100).reduce((a, b) => a + b, 0) : 0;
    let tier3List = response.filter(rs => status.indexOf(rs.status) > -1 && rs.tier == 'Tier 3');
    let tier3Total = tier3List && tier3List.length ? new Array(tier3List[0]).map(t3l => t3l.total).reduce((a, b) => a + b, 0) : 0;
    let tier3Real = tier3List && tier3List.length ? new Array(tier3List[0]).map(t3l => t3l.pourcentage).reduce((a, b) => a + b, 0) : 0;
    let tier3 = tier3List && tier3List.length ? tier3List.map(t3l => t3l.pourcentage / t3l.total * 100).reduce((a, b) => a + b, 0) : 0;
    let createdStatut = response.find(rs => rs.status == 'CREATED');
    if (createdStatut && createdStatut.pourcentage == 100) {
      tier1 = tier1 / 4;
      tier2 = tier2 / 4;
      tier3 = tier3 / 4;
    }
    this.rapportTiers2.push({
      status: statut,
      tier1: tier1,
      tier1Total: tier1Total,
      tier1Real: tier1Real,
      tier2: tier2,
      tier2Total: tier2Total,
      tier2Real: tier2Real,
      tier3: tier3,
      tier3Total: tier3Total,
      tier3Real: tier3Real
    });
  }

  getLeadFiltered() {
    if (this.searchFilter && this.searchFilter != '') {
      return this.rapportTiers.filter(usr => usr.user.toUpperCase().includes(this.searchFilter.toUpperCase())
        || usr.fournisseurs.map(fr => fr.toUpperCase()).join('').includes(this.searchFilter.toUpperCase()));
    } else {
      return this.rapportTiers;
    }
  }

  private getlesRoles() {
    let roles = [];
    let role = {};
    role['label'] = 'ADMIN';
    roles.push(role);
    role = {};
    role['label'] = 'ACHAT';
    roles.push(role);
    this.roles = roles;
  }

  getJustTwoNumbers(num: any) {
    return num ? (Math.round(num * 100) / 100).toFixed(2) : '0.00';
  }

  getJustNumber(num: any) {
    return num ? Math.round(num) : 0;
  }

  clickNiveau3() {

  }

  getRowSpan(user: any, i: any) {
    let indexOfList = this.listRapportSousDeptAchteur.findIndex(x => x.sous_dept == user.sous_dept);
    if (indexOfList == i) {
      return this.listRapportSousDeptAchteur.filter(lsd => lsd.sous_dept == user.sous_dept).length;
    }
    return 0;
  }
}
