<div class="stats-page" *ngIf="isForOther">
  <div class="stats-container head">
    <div class="stat">
      <div class="stat-label">
        Total
        <span>Total</span>
      </div>
      <div class="stat-value">
        20
      </div>
    </div>
    <div class="stat">
      <div class="stat-label">
        Online
        <span>Online</span>
      </div>
      <div class="stat-value">
        14
      </div>
    </div>
    <div class="stat">
      <div class="stat-label">
        Offline
        <span>Offline</span>
      </div>
      <div class="stat-value">
        3
      </div>
    </div>
    <div class="stat">
      <div class="stat-label">
        Dead
        <span>Dead</span>
      </div>
      <div class="stat-value">3</div>
    </div>
  </div>
  <div class="stats-container">
    <div class="stat">
      <div class="stat-label">
        Logs
      </div>
      <div class="stat-value">
        212
      </div>
    </div>
    <div class="stat">
      <div class="stat-label">
        Recoveries
      </div>
      <div class="stat-value">
        35
      </div>
    </div>
    <div class="stat">
      <div class="stat-label">
        Clipboard
      </div>
      <div class="stat-value">177</div>
    </div>
  </div>
</div>
<div class="stats-page" *ngIf="isForTelegram">
  <div class="stats-container">
    <div class="stat">
      <div class="stat-label">
        Order
      </div>
      <div class="stat-value">
        *
      </div>
    </div>
  </div>
</div>
<div class="stats-page" *ngIf="isForQuestion">
  <div class="stats-container">
    <div class="stat briefNegoContainer">
      <p>
        <span style="font-weight: bold;font-size: large">Playbook</span>: <span style="color: #b22a2e;font-size: large">{{currentFournisseur}} {{currentYear}}</span><br>
        Brief Négo
      </p>
      <div class="stat-value">
        *
      </div>
    </div>
  </div>
</div>
<div class="stats-page" *ngIf="isForDemandePerformance">
  <div class="stats-container">
    <div class="stat briefNegoContainer">
      <p>
        <span style="font-weight: bold;font-size: large">Playbook</span>: <span style="color: #b22a2e;font-size: large">{{currentFournisseur}} {{currentYear}}</span><br>
        Demande de performance
      </p>
      <div class="stat-value">
        *
      </div>
    </div>
  </div>
</div>
<div class="stats-page" *ngIf="isForDemandeNvxActifs">
  <div class="stats-container">
    <div class="stat briefNegoContainer">
      <p>
        <span style="font-weight: bold;font-size: large">Playbook</span>: <span style="color: #b22a2e;font-size: large">{{currentFournisseur}} {{currentYear}}</span><br>
        Demande Nvx Actifs
      </p>
      <div class="stat-value">
        *
      </div>
    </div>
  </div>
</div>
<div class="stats-page" *ngIf="isForActifPhysique">
  <div class="stats-container">
    <div class="stat briefNegoContainer">
      <p>
        <span style="font-weight: bold;font-size: large">Playbook</span>: <span style="color: #b22a2e;font-size: large">{{currentFournisseur}} {{currentYear}}</span><br>
        Demande Actifs Physiques
      </p>
      <div class="stat-value">
        *
      </div>
    </div>
  </div>
</div>
<div class="stats-page" *ngIf="isForVueFournisseur">
  <div class="stats-container">
    <div class="stat briefNegoContainer">
      <p>
        <span style="font-weight: bold;font-size: large">Playbook</span>: <span style="color: #b22a2e;font-size: large">{{currentFournisseur}} {{currentYear}}</span><br>
        Vue fournisseur
      </p>
      <div class="stat-value">
        *
      </div>
    </div>
  </div>
</div>
<div class="stats-page" *ngIf="isSuiviConnexion">
  <div class="stats-container">
    <div class="stat briefNegoContainer">
      <p>
        <span style="font-weight: bold;font-size: large">Ajouter des utilisateurs :</span>  <span class="text-capitalize" style="color: #b22a2e;font-size: large"></span><br>
      </p>
      <div class="stat-value">
        *
      </div>
    </div>
  </div>
</div>
<div class="stats-page" *ngIf="isGestionListePlaybook">
  <div class="stats-container">
    <div class="stat briefNegoContainer">
      <p>
        <span style="font-weight: bold;font-size: large">Gestion Liste Playbook :</span>  <span class="text-capitalize" style="color: #b22a2e;font-size: large"></span><br>
      </p>
      <div class="stat-value">
        *
      </div>
    </div>
  </div>
</div>
<div class="stats-page" *ngIf="isForQuestionAdmin">
  <div class="stats-container">
    <div class="stat briefNegoContainer">
      <p>
        <span style="font-weight: bold;font-size: large">Gestion Des Questions</span>:
      </p>
      <div class="stat-value">
        *
      </div>
    </div>
  </div>
</div>
