import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AttachmentService} from '../../shared/services/attachment.service';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {Utils} from '../../core/utils/Utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  countries = [];
  years = [];
  osVersions = [];
  isAccepted: any = false;
  yearControl: any = '0';
  rayonControl: any = '0';
  deptControl: any = '0';
  rayons = [];
  depts = [];
  fournisseurControl: any = '';
  fournisseurs = [];
  questions = [];
  questionsGrouped = [];
  postures = [];
  responseArchives = [];
  user: any;
  currentYear: number;
  issfamille = false;
  username: string;
  fourLead = [];
  posturesTemp = [];
  posturesArchives = [];
  tryCount = 3;
  userWorkflow = [];
  allFournisseurMonoRayon = [];
  listePlaybookChef = [];

  constructor(private http: HttpClient,
              private router: Router,
              private attachmentService: AttachmentService) {
    if (!attachmentService.canConnect) {
      this.router.navigate(['access-denied']);
    }
  }

  ngOnInit() {
    this.chargeYears();
    this.user = JSON.parse(localStorage.getItem('userObject'));
    this.username = localStorage.getItem('username');
    setTimeout(() => this.getFornisseurs(), 500);
    this.yearControl = localStorage.getItem('year');
    this.getUserWorkflow();
    this.getAllFournisseurMonoRayon();
    /*this.fetchCountries();
    this.fetchOsVersions();*/
  }

  fetchCountries() {
    this.http.get<any>('../../../../assets/data/mock/countries.json').subscribe(res => {
      this.countries = res;
    });
  }

  getRayons() {
    this.rayons = [];
    this.attachmentService.getRayons(this.fournisseurControl, this.deptControl, this.username).subscribe(response => {
      this.getFL();
      if (!response || !response.length) {
      } else {
        response.forEach(rayon => {
          this.rayons.push(rayon);
        });
        if (this.rayons.includes(localStorage.getItem('rayon'))) {
          this.rayonControl = localStorage.getItem('rayon');
        }
      }
    });
  }

  getUserWorkflow() {
    this.attachmentService.getUserWorkflow().subscribe(response => {
      this.userWorkflow = response;
    });
  }

  getDepts() {
    this.depts = [];
    this.attachmentService.getDepts(this.fournisseurControl, this.username).subscribe(response => {
      response.forEach(dept => {
        this.depts.push(dept);
      });
      if (this.depts.includes(localStorage.getItem('dept'))) {
        this.deptControl = localStorage.getItem('dept');
      }
      if (this.deptControl) {
        this.getRayons();
      }
    });
  }

  getFL() {
    this.attachmentService.getFL(this.username).subscribe(response => {
      this.fourLead = response;
    });
  }

  getFornisseurs() {
    this.attachmentService.getFornisseurs(this.username).subscribe(response => {
      response.forEach(fournisseur => {
        this.fournisseurs.push(fournisseur.fournisseur);
      });
      this.fournisseurs.sort(frn => frn);
      if (this.fournisseurs.includes(localStorage.getItem('fournisseur'))) {
        this.fournisseurControl = localStorage.getItem('fournisseur');
      }
      if (this.fournisseurControl) {
        this.getDepts();
      }
    }, error => {
      if (this.tryCount) {
        this.tryCount--;
        this.getFornisseurs();
      }
    });
  }

  fetchOsVersions() {
    this.http.get<any>('../../../../assets/data/mock/os-versions.json').subscribe(res => {
      this.osVersions = res;
    });
  }

  isFormulaireValid() {
    if (!this.isRayonValid()) {
      return false;
    } else if (this.yearControl == '0') {
      return false;
    } else {
      return this.fournisseurControl != '';
    }
  }

  yearChanged() {
    console.log(this.yearControl);
  }

  chargeYears() {
    this.currentYear = new Date().getFullYear();
    for (let i = 2021; i < this.currentYear + 2; i++) {
      this.years.push(i);
    }
  }

  isRayonValid() {
    return this.rayonControl != '0';
  }

  isFournisValid() {
    return this.fournisseurControl != '';
  }

  isdeptValid() {
    return this.deptControl != '0';
  }

  rayonChanged() {
    localStorage.setItem('rayon', this.rayonControl);
    this.questions = [];
    this.questionsGrouped = [];
    //this.getFornisseurs();
  }

  getQuestions() {
    this.questions = [];
    this.questionsGrouped = [];
    let obData = {
      fournisseur: this.fournisseurControl,
      rayon: this.rayonControl,
      annee: this.yearControl,
    };
    this.attachmentService.getComments(obData).subscribe(response => {
      this.getPostures();
      response.forEach(comment => {
        comment.type = 'croissance';
        this.questions.push(comment);
      });
      while (this.questions.length > 0) {
        this.questionsGrouped.push({
          title: this.questions[0].partie,
          list: this.questions.filter(qs => qs.partie == this.questions[0].partie)
        });
        this.removeItemAll(this.questions, this.questions[0].partie);
        console.log('questionsGrouped', this.questionsGrouped);
        console.log('questions', this.questions);
      }
      /*let indexPostureNego = this.questionsGrouped.findIndex(qs => qs.title == 'Posture de négociation');
      let postureNegoObject = this.questionsGrouped[indexPostureNego];
      this.questionsGrouped.splice(indexPostureNego, 1);
      this.questionsGrouped.push(postureNegoObject);*/
    });
  }

  getPostures() {
    this.postures = [];
    let obData = {
      fournisseur: this.fournisseurControl,
      annee: this.yearControl,
      issfamille: this.issfamille
    };
    this.attachmentService.getPostures(obData, this.username).subscribe(response => {
      response.forEach(comment => {
        this.postures.push(comment);
      });
    });
  }

  async canShowListComments(question: any) {
    if (question.canChoose) {
      const {value: reponseChoose} = await Swal.fire({
        title: 'Selectioner une reponse',
        input: 'select',
        inputOptions: {
          'reponse 1': 'reponse 1',
          'reponse 2': 'reponse 2',
          'reponse 3': 'reponse 3',
          'reponse 4': 'reponse 4',
          'Autre': 'Autre',
        },
        inputPlaceholder: 'Selectioner une reponse',
        showCancelButton: true,
        cancelButtonColor: '#d5d5efde',
        confirmButtonText:
          '<i class="fa fa-edit"></i> Modifier!',
        confirmButtonAriaLabel: 'Modifier',
        cancelButtonAriaLabel: 'Annuller',
        cancelButtonText:
          '<i class="fa fa-ban" style="color: black"> Annuller</i>',
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value && value != '') {
              resolve();
            } else {
              resolve('Merci de choisir une reponse :)');
            }
          });
        }
      });

      if (reponseChoose) {
        if (reponseChoose == 'Autre') {
          this.showEditDialog(question);
        } else {
          let obData2 = {
            fournisseur: this.fournisseurControl,
            rayon: this.rayonControl,
            annee: this.yearControl,
            partie: question.partie,
            question: question.question,
            reponse: reponseChoose,
            lastUserEdit: this.username
          };
          this.attachmentService.editComment(obData2).subscribe(respo => {
            Utils.showPopUpNotif('Votre questionnaire a été Modifié.');
            this.getQuestions();
          });
        }
      }
    } else {
      this.showEditDialog(question);
    }
  }

  showEditDialog(question: any) {
    let obData = {
      fournisseur: this.fournisseurControl,
      rayon: this.rayonControl,
      annee: this.yearControl,
      partie: question.partie,
      question: question.question,
    };
    this.attachmentService.getCommentlastyear(obData).subscribe(response => {
      Swal.fire({
        title: '<strong></strong>',
        html:
          '<label for="swal2-input" class="swal2-input-label">Commentaire historique</label>' +
          '<div style="display: flex">' +
          '<p style="width: 8%;white-space: nowrap;">' + ((response && response.annee) ? response.annee : this.yearControl - 1) + '</p>' +
          '<textarea class="swal2-textarea disabled" style="display: flex;width: 88%;">' + ((response && response.reponse) ? response.reponse : 'Commentaire précedent') + '</textarea>' +
          '</div>',
        input: 'textarea',
        inputLabel: 'Commentaire',
        inputValue: question.reponse,
        inputPlaceholder: 'Tapez votre commentaire ici...',
        inputAttributes: {
          'aria-label': 'Tapez votre commentaire ici...'
        },
        showCloseButton: true,
        showCancelButton: true,
        showDenyButton: true,
        denyButtonColor: '#d5d5efde',
        cancelButtonColor: '#f0f8ff',
        focusConfirm: false,
        /*inputValidator: result => {
          if (!result)
            return 'Il faut écrire un texte!';
        },*/
        confirmButtonText:
          '<i class="fa fa-edit"></i> Modifier!',
        confirmButtonAriaLabel: 'Modifier',
        denyButtonAriaLabel: 'Annuller',
        denyButtonText:
          '<i class="fa fa-ban" style="color: black"> Annuller</i>',
        cancelButtonText:
          '<i class="fa fa-info" style="color: black"> Detail historique</i>',
        cancelButtonAriaLabel: 'Detail'
      }).then(res => {
        console.log(res);
        if (res.isConfirmed) {
          let obData2 = {
            fournisseur: this.fournisseurControl,
            rayon: this.rayonControl,
            annee: this.yearControl,
            partie: question.partie,
            question: question.question,
            reponse: res.value,
            lastUserEdit: this.username
          };
          this.attachmentService.editComment(obData2).subscribe(respo => {
            /*Swal.fire({
                title: 'Modifié!', text: 'Votre questionnaire a été Modifié.', icon: 'success', timer: 1500,
                timerProgressBar: true, showConfirmButton: false
            });*/
            Utils.showPopUpNotif('Votre questionnaire a été Modifié.');
            this.getQuestions();
          });
        } else if (res.isDismissed && res.dismiss == Swal.DismissReason.cancel) {
          this.attachmentService.getCommentsArchives(obData).subscribe(result => {
            this.responseArchives = result;
            Swal.fire({
              title: '<strong></strong>',
              width: 1000,
              html: (this.responseArchives && this.responseArchives.length) ? ('<table>' +
                '            <thead>' +
                '            <tr>' +
                '                <th class="normalTextTableTh" style="width: 44rem">' +
                '                    Commentaire historique' +
                '                </th>' +
                '                <th class="normalTextTableTh" style="width: 20rem">' +
                '                    Dernière modification (user)' +
                '                </th>' +
                '                <th class="normalTextTableTh" style="width: 20rem">' +
                '                    Dernière date modification' +
                '                </th>' +
                '            </tr>' +
                '            </thead>' +
                '            <tbody><tr><td style="color: transparent">_</td><td></td><td></td></tr>' + this.getTrsArchives() +
                '            </tbody>' +
                '        </table>') : ('<label class="swal2-input-label"> Aucun Commentaire historique </label>'),
            }).then(res2 => {
            });
          });
          /*let elementById = document.getElementById('parentDivToSwal');
          document.body.appendChild(elementById);*/

        }
      });
    });
  }

  showEditDialogPosture(question: any) {
    Swal.fire({
      title: '<strong></strong>',
      html:
        '<label for="swal2-input" class="swal2-input-label">Boutton historique : ' + (question.lastUserEdits ? question.lastUserEdits : '') + ' ' + (question.lastTimeEdits ? question.lastTimeEdits : '') + '</label>' +
        '<label for="swal2-input" class="swal2-input-label">Commentaire historique : ' + (question.lastUserEdit ? question.lastUserEdit : '') + ' ' + (question.lastTimeEdit ? question.lastTimeEdit : '') + '</label>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: question.reponse,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      showDenyButton: true,
      denyButtonColor: '#d5d5efde',
      cancelButtonColor: '#f0f8ff',
      focusConfirm: false,
      /*inputValidator: result => {
        if (!result)
          return 'Il faut écrire un texte!';
      },*/
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      denyButtonAriaLabel: 'Annuller',
      denyButtonText:
        '<i class="fa fa-ban" style="color: black"> Annuller</i>',
      cancelButtonText:
        '<i class="fa fa-info" style="color: black"> Detail historique</i>',
      cancelButtonAriaLabel: 'Detail'
    }).then(res => {
      console.log(res);
      let obData = {
        fournisseur: this.fournisseurControl,
        departement: question.departement,
        rayon: question.rayon,
        famille: question.famille,
        sfamille: question.sfamille,
        reponse: res.value,
        annee: this.yearControl,
        issfamille: this.issfamille,
        lastUserEdit: this.username
      };
      if (res.isConfirmed) {
        this.attachmentService.editPosture(obData).subscribe(response => {
          /*Swal.fire({
              title: 'Modifié!', text: 'Votre posture a été Modifié.', icon: 'success', timer: 1500,
              timerProgressBar: true, showConfirmButton: false
          });*/
          Utils.showPopUpNotif('Votre posture a été Modifié.');
          this.getPostures();
        });
      } else if (res.isDismissed && res.dismiss == Swal.DismissReason.cancel) {
        this.attachmentService.getPosturesArchive(obData).subscribe(result => {
          this.posturesArchives = result;
          Swal.fire({
            title: '<strong></strong>',
            width: 1000,
            html: (this.posturesArchives && this.posturesArchives.length) ? ('<table>' +
              '            <thead>' +
              '            <tr>' +
              '                <th class="normalTextTableTh" style="width: 18rem">' +
              '                    Statut historique' +
              '                </th>' +
              '                <th class="normalTextTableTh" style="width: 20rem">' +
              '                    Dernière modification (user/date)' +
              '                </th>' +
              '                <th class="normalTextTableTh" style="width: 40rem">' +
              '                    Commentaire historique' +
              '                </th>' +
              '                <th class="normalTextTableTh" style="width: 20rem">' +
              '                    Dernière modification (user/date)' +
              '                </th>' +
              '            </tr>' +
              '            </thead>' +
              '            <tbody><tr><td style="color: transparent">_</td><td></td><td></td></tr>' + this.getTrsPostureArchives() +
              '            </tbody>' +
              '        </table>') : ('<label class="swal2-input-label"> Aucun Commentaire historique </label>'),
          }).then(res2 => {
          });
        });
        /*let elementById = document.getElementById('parentDivToSwal');
        document.body.appendChild(elementById);*/

      }
    });
  }

  controlChangedFournisseur() {
    this.deptControl = '0';
    this.rayonControl = '0';
    this.questions = [];
    this.questionsGrouped = [];
    this.postures = [];
    localStorage.setItem('fournisseur', this.fournisseurControl);
    this.getDepts();
  }

  controlChangedYear() {
    localStorage.setItem('year', this.yearControl);
    this.questions = [];
    this.questionsGrouped = [];
    this.postures = [];
  }

  deptChanged() {
    this.questions = [];
    this.questionsGrouped = [];
    this.rayonControl = '0';
    localStorage.setItem('dept', this.deptControl);
    this.getRayons();
  }

  editPosture(question: any, maintien: string) {
    let obData = {
      fournisseur: this.fournisseurControl,
      departement: question.departement,
      rayon: question.rayon,
      famille: question.famille,
      sfamille: question.sfamille,
      status: (question.status == maintien) ? null : maintien,
      annee: this.yearControl,
      issfamille: this.issfamille,
      lastUserEdit: this.username
    };
    this.attachmentService.editPostureStatut(obData).subscribe(response => {
      /*Swal.fire({
          title: 'Modifié!', text: 'Votre posture a été Modifié.', icon: 'success', timer: 1500,
          timerProgressBar: true, showConfirmButton: false
      });*/
      Utils.showPopUpNotif('Votre posture a été Modifié.');
      this.getPostures();
    });
  }

  removeIndexAfterListed(indes) {
    let index = this.questions.findIndex(qs => qs.partie);
    if (index > -1) { // only splice array when item is found
      this.questions.splice(index, 1); // 2nd parameter means remove one item only
    }
  }

  removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i].partie === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  toLeadFunction() {
    this.router.navigate(['lead-bn']);
  }

  canClickLead() {
    return this.fourLead.includes(this.fournisseurControl);
  }

  isCroissance(question: any) {
    return this.posturesTemp.find(pt => pt.famille == question.famille) && this.posturesTemp.find(pt => pt.famille == question.famille).status == 'croissance';
  }

  isMaintien(question: any) {
    return this.posturesTemp.find(pt => pt.famille == question.famille) && this.posturesTemp.find(pt => pt.famille == question.famille).status == 'maintien';
  }

  isDecroissance(question: any) {
    return this.posturesTemp.find(pt => pt.famille == question.famille) && this.posturesTemp.find(pt => pt.famille == question.famille).status == 'decroissance';
  }

  getTrsArchives() {
    let result = '';
    this.responseArchives.forEach(resArchiv => {
      result += '            <tr>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.reponse ? resArchiv.reponse : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.lastUserEdit ? resArchiv.lastUserEdit : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.lastTimeEdit ? resArchiv.lastTimeEdit : '_') +
        '                </td>' +
        '            </tr>';
    });
    return result;
  }

  getTrsPostureArchives() {
    let result = '';
    this.posturesArchives.forEach(resArchiv => {
      result += '            <tr>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.status ? resArchiv.status : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.lastUserEdits ? (resArchiv.lastUserEdits + ' / ' + resArchiv.lastTimeEdits) : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.reponse ? resArchiv.reponse : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.lastUserEdit ? (resArchiv.lastUserEdit + ' / ' + resArchiv.lastTimeEdit) : '_') +
        '                </td>' +
        '            </tr>';
    });
    return result;
  }

  changeTempPosture() {
    if (this.issfamille) {
      this.posturesTemp = this.postures;
    } else {
      this.posturesTemp = [];
    }
  }

  canFinalise() {
    return this.userWorkflow.some(user => user.fournisseur == this.fournisseurControl
      && user.annee == this.yearControl
      && user.status == 'CREATED');
  }

  finalisePlaybook() {
    this.attachmentService.finalisePlaybookAchteur(this.fournisseurControl, this.yearControl).subscribe(response => {
      Swal.fire({
        title: 'Finalisé!', text: 'Ce playbook a été finalisé.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
      this.ngOnInit();
      if (this.userWorkflow.some(user => user.fournisseur == this.fournisseurControl
        && user.annee == this.yearControl
        && user.status == 'CREATED'
        && user.isLeadFounded == 'faux')) {
        this.attachmentService.sendPlaybookAchteur(this.fournisseurControl, this.yearControl).subscribe(resp => {
        });
      }
    });

  }

  canValideChef() {
    let playbookChef = this.listePlaybookChef.find(lpc => lpc.fournisseur == this.fournisseurControl);
    return this.listePlaybookChef && this.listePlaybookChef.length && (this.showValiderButton(playbookChef) || this.showValiderButtonN2(playbookChef) || this.showValiderButtonN3(playbookChef));
  }

  getAllFournisseurMonoRayon() {
    this.attachmentService.getAllFournisseurMonoRayon().subscribe(response => {
      if (response && response.length) {
        this.allFournisseurMonoRayon = response;
        this.getListePlaybookChef();
      }
    });
  }

  showValiderButton(playbookChef: any) {
    if (playbookChef.isn1 == 'oui') {
      if (this.allFournisseurMonoRayon.includes(this.fournisseurControl)) {
        return playbookChef.status == 'FINALISED';
      } else {
        return playbookChef.status == 'FINALISEDLEAD';
      }
    }
  }

  showValiderButtonN2(playbookChef: any) {
    return playbookChef.isn2 == 'oui' && playbookChef.status == 'VALIDATIONN+1';
  }

  showValiderButtonN3(playbookChef: any) {
    return playbookChef.isn3 == 'oui' && playbookChef.status == 'VALIDATIONN+2';
  }

  getListePlaybookChef() {
    this.attachmentService.getListePlaybookChef().subscribe(response => {
      if (response && response.length) {
        this.listePlaybookChef = response;
      }
    });
  }
}
