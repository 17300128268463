<body>
<section id="not-found">
    <div id="title"> &bull; 401 Unauthorized</div>
    <div class="circles">
        <p>401<br>
            <small>Utilisateur Non Autorisé, Contactez L’administrateur. </small>
        </p>
        <span class="circle big"></span>
        <span class="circle med"></span>
        <span class="circle small"></span>
    </div>
</section>
</body>
