<div class="page">
    <!--<app-stats></app-stats>-->
    <app-stats [isForQuestion]="true" [currentYear]="yearControl" [currentFournisseur]="fournisseurControl"></app-stats>
    <main>
        <div class="panel">
            <!--<div class="table-panel countries">
              <div class="panel-title" style="color: #b22a2e;text-transform: initial">Recherche avancée</div>
              <div class="panel-body">

                <div class="form-group fournControlClass" style="position:relative;">
                  <i class="fa fa-fw fa-caret-down"
                     style="position: absolute;right: 0.5rem;top: 2.5rem;font-size: large;"></i>
                  <label>Fournisseur</label>
                  <select class="form-control" [(ngModel)]="fournisseurControl"
                          (change)="controlChangedFournisseur()">
                    <option disabled value="0">Selectionner un fournisseur</option>
                    <option *ngFor="let fournisseur of fournisseurs" value="{{fournisseur}}">{{fournisseur}}</option>
                  </select>
                </div>
                <div style="display: flex;margin: 1rem 0;align-items: center">
                  <div class="form-group flexAutoMarge" style="position:relative;">
                    <i class="fa fa-fw fa-calendar"
                       style="position: absolute;right: 0.5rem;top: 2.5rem;font-size: large;"></i>
                    <label>Annee</label>
                    <select class="form-control" [(ngModel)]="yearControl" (change)="controlChangedYear()">
                      <option disabled value="0">Selectionner une annee</option>
                      <option *ngFor="let year of years" value="{{year}}">{{year}}</option>
                    </select>
                    <small style="visibility: hidden" class="form-text text-muted">Annee</small>
                  </div>
                  <div class="form-group flexAutoMarge" style="position:relative;">
                    <i class="fa fa-fw fa-caret-down"
                       style="position: absolute;right: 0.5rem;top: 2.5rem;font-size: large;"></i>
                    <label>Département</label>
                    <select class="form-control" [(ngModel)]="deptControl" (change)="deptChanged()"
                            [disabled]="!isFournisValid()">
                      <option disabled value="0">Selectionner un Département</option>
                      <option *ngFor="let dept of depts" value="{{dept}}">{{dept}}</option>
                    </select>
                    <small class="form-text text-muted">Choisir le fournisseur pour choisir département</small>
                  </div>
                  <div class="form-group flexAutoMarge" style="position:relative;">
                    <i class="fa fa-fw fa-caret-down"
                       style="position: absolute;right: 0.5rem;top: 2.5rem;font-size: large;"></i>
                    <label>Rayon</label>
                    <select class="form-control" [(ngModel)]="rayonControl" (change)="rayonChanged()"
                            [disabled]="!isdeptValid()">
                      <option disabled value="0">Selectionner un rayon</option>
                      <option *ngFor="let rayon of rayons" value="{{rayon}}">{{rayon}}</option>
                    </select>
                    <small class="form-text text-muted">Choisir département pour choisir le rayon</small>
                  </div>
                  <button class="btn btn-primary flexAutoMarge" style="color: white;background-color: #b22a2e;height: 3.3rem;"
                          [disabled]="!isFormulaireValid()" (click)="getQuestions();getPostures()">Rechercher
                  </button>
                </div>

                &lt;!&ndash;<div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="isAccepted">
                  <label class="form-check-label" for="exampleCheck1">Accept</label>
                </div>&ndash;&gt;
              </div>
            </div>-->
        </div>
        <ng-container *ngFor="let qg of questionsGrouped;index as iqg">
            <div class="panel">
                <div class="table-panel countries">
                    <div class="panel-title">{{qg.title}}</div>
                    <div class="panel-body">
                        <table>
                            <thead [class.hiddenDiv]="false">
                            <tr>
                                <!--<th>

                                </th>-->
                                <th style="width: 23rem">
                                </th>
                                <th style="width: 32rem" *ngIf="qg.list[0].question!='_'">
                                </th>
                                <th style="width: 6rem;font-weight: 400;color: #7a7a7a;text-align: center">
                                    Commentaire saisi
                                </th>
                                <th class="widthThModif widthDateLastEdit" style="font-weight: 400;color: #7a7a7a;">
                                    Conclusion
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let question of qg.list;index as i">
                                <!--<ng-container *ngFor="let question2 of question.list;index as i2">-->
                                <tr>
                                    <!--<td>
                                      #
                                    </td>-->
                                    <!--<td class="normalTextTable" style="font-weight: bold" *ngIf="i==0" [rowSpan]="qg.list.length">
                                      {{question.partie}}
                                    </td>-->
                                    <td class="normalTextTable2" *ngIf="question.question!='_'">
                                        {{question.question}}
                                    </td>
                                    <td class="normalTextTable">
                                        {{question.rayon}}
                                    </td>
                                    <td class="normalTextTable">
                                        <div class="disabled" style="min-height: 3rem;min-width: 20rem;margin: 0 0.5rem;text-align: start;padding: 0.5rem;"
                                             [class.textCommentRose]="true"
                                             (click)="showEditDialog(question)">
                                            {{question.reponse}}
                                        </div>
                                    </td>
                                    <td class="cursor-pointer normalTextTable"
                                        [rowSpan]="question.question!='_'||qg.list.length>1?getConclusedLength(qg.list,i):1"
                                        *ngIf="question.isConclused">
                                        <div class="cursor-pointer"
                                             style="min-height: 3rem;min-width: 20rem;margin: 0 0.5rem;text-align: start;padding: 0.5rem;"
                                             [class.minHeigthConclus]="question.question!='_'&&qg.list.length>1"
                                             [class.textCommentRose]="true"
                                             (click)="showEditDialogConclus(question)">
                                            {{question.conclusion}}
                                        </div>
                                    </td>
                                    <!--<td>
                                      <div class="borderDivIcon" style="background-color: #0bc326"
                                           [class.disabledIcon]="yearControl<currentYear">
                                        <i class='fas fa fa-edit iconInsideDiv'
                                           (click)="showEditDialog(question)"></i>
                                      </div>
                                    </td>-->
                                </tr>
                                <!--</ng-container>-->
                            </ng-container>

                            </tbody>
                        </table>
                    </div>
                </div>
                <!--<div class="table-panel os-versions">
                  <div class="panel-title">Sous questions</div>
                  <div class="panel-body">
                    <table>
                      <thead>
                      <tr>
                        <th>
                          #
                        </th>
                        <th>
                          Sous question
                        </th>
                        <th>
                          Reponse
                        </th>
                        <th>
                          dernier edit
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let sQuestion of sQuestions">
                        <td>#</td>
                        <td><span>{{sQuestion.question}}</span></td>
                        <td>{{sQuestion.reponse}}</td>
                        <td>{{sQuestion.lastEdit}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>-->
            </div>
        </ng-container>
      <div>
        <input *ngIf="questionsGrouped&&questionsGrouped.length&&canFinalise()" type="button" class="form-control btn-success" value="Finaliser" (click)="finalisePlaybook()" style="width: 10%;height: 3rem;float: right;background-color: #30bd70;margin-right: 2rem">
        <input *ngIf="questionsGrouped&&questionsGrouped.length&&canValideChef()&&showValiderButton()" type="button" class="form-control btn-success" value="Valider" (click)="validePlaybookN1()" style="width: 10%;height: 3rem;float: right;background-color: #30bd70;margin-right: 2rem">
        <input *ngIf="questionsGrouped&&questionsGrouped.length&&canValideChef()&&showValiderButtonN2()" type="button" class="form-control btn-success" value="Valider" (click)="validePlaybookN2()" style="width: 10%;height: 3rem;float: right;background-color: #30bd70;margin-right: 2rem">
        <input *ngIf="questionsGrouped&&questionsGrouped.length&&canValideChef()&&showValiderButtonN3()" type="button" class="form-control btn-success" value="Valider" (click)="validePlaybookN3()" style="width: 10%;height: 3rem;float: right;background-color: #30bd70;margin-right: 2rem">
      </div>
        <!--<div class="panel">
          <div class="table-panel countries">

          </div>
        </div>-->
    </main>
</div>
