<div class="page">
    <!--<app-stats></app-stats>-->
    <app-stats [isSuiviConnexion]="true" [currentUser]="username"></app-stats>
    <main>
        <div class="panel">
            <div class="table-panel users">
                <div class="panel-title">
                    Gestion des utilisateurs
                </div>
                <div class="panel-body" style="display: flex;align-items: center;justify-content: space-around;">
                    <div class="form-group" style="width: 25%;padding: 0 0.5rem;">
                        <label class="form-label">Username :</label>
                        <input type="text" class="form-control" [(ngModel)]="userControl" [disabled]="isEdit">
                    </div>
                    <div class="form-group" style="width: 25%;padding: 0 0.5rem;">
                        <label class="form-label">Role :</label>
                        <ng-select *ngIf="roles&&roles.length"
                                   [items]="roles"
                                   [bindLabel]="'label'"
                                   placeholder="Selectionner un role"
                                   [(ngModel)]="roleControl"
                                   (ngModelChange)="roleChanged()">
                        </ng-select>
                    </div>
                    <div class="form-group" style="width: 25%;padding: 0 0.5rem;"
                         *ngIf="roleControl&&roleControl.label=='ACHAT'">
                        <label class="form-label">Rayon :</label>
                        <ng-select *ngIf="rayons&&rayons.length"
                                   [items]="rayons"
                                   [multiple]="true"
                                   [closeOnSelect]="false"
                                   [searchable]="true"
                                   placeholder="Selectionner les rayons"
                                   [(ngModel)]="rayonControl">
                        </ng-select>
                    </div>
                    <div class="form-group" style="width: 25%">
                        <div style="width: 60%;margin-left: auto;margin-right: auto;">
                            <button class="btn btn-primary flexAutoMarge" *ngIf="!isEdit"
                                    style="color: white;background-color: #b22a2e;height: 3.3rem;width: 100%;margin: 0.5rem"
                                    [disabled]="!isFormulaireValid()" (click)="addUser()">Ajouter
                            </button>
                            <button class="btn btn-primary flexAutoMarge" *ngIf="isEdit"
                                    style="color: white;background-color: #b22a2e;height: 3.3rem;width: 100%;margin: 0.5rem"
                                    [disabled]="!isFormulaireValid()" (click)="editUser()">Modifier
                            </button>
                            <button class="btn btn-light flexAutoMarge"
                                    style="height: 3.3rem;width: 100%;margin: 0.5rem"
                                    (click)="this.isEdit = false;this.userControl = '';this.roleControl = {};this.rayonControl = [];">Annuler
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="panel">
            <div class="table-panel users">
                <div class="panel-title">
                    Liste des utilisateurs
                </div>
                <input type="text" class="form-control" [(ngModel)]="searchFilter" placeholder="Recherche"
                       style="width: 90%;margin-left: auto;margin-right: auto">
                <div class="panel-body">
                    <table>
                        <thead>
                        <tr>
                            <th style="width: 25%;text-align: center">
                                Username
                            </th>
                            <th style="width: 25%;text-align: center">
                                Role
                            </th>
                            <th style="width: 25%;text-align: center">
                                Activé
                            </th>
                            <th style="width: 25%;text-align: center">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let user of getUserFiltered()">
                            <td style="text-align: center">{{user.user}}</td>
                            <td style="text-align: center"><span>{{user.roleuser}}</span></td>
                            <td style="text-align: center">
                                <div class="form-check form-switch center-div-inside-td"
                                     style="width: 10%;margin-left: auto;margin-right: auto;">
                                    <input class="form-check-input" type="checkbox"
                                           (click)="editDisable(user)"
                                           [disabled]="user.user==username"
                                           [checked]="user.disable!='oui'">
                                    <label class="form-check-label"></label>
                                </div>
                            </td>
                            <td style="text-align: center"><i class="fa fas fa-edit cursor-pointer" [class.disabled]="user.user==username"
                                                              (click)="isEdit=true;chargeUser(user)"></i>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </main>
</div>
