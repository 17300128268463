import {Component, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import Swal from 'sweetalert2';
import {Utils} from './core/utils/Utils';
import {AttachmentService} from './shared/services/attachment.service';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'playbook-app';
  public user: any;
  public menu: any[];
  isSidebarFolded = false;
  username: string;
  tryCount = 3;
  respReturned: boolean = false;
  isChefDep = false;

  constructor(private keycloakService: KeycloakService,
              private serviceAt: AttachmentService,
              private router: Router,
              private ngxService: NgxUiLoaderService,
              private sanitizer: DomSanitizer) {
    let userNameEmail = this.keycloakService['_userProfile'].email;
    this.testIfUserExist(userNameEmail);
  }

  ngOnInit() {
    this.setFrameOptions();
    let userNameEmail = this.keycloakService['_userProfile'].email;
    this.ngxService.start();
    setTimeout(() => {
      this.ngxService.stop();
    }, 2000);
    this.user = JSON.parse(localStorage.getItem('userObject'));
    this.keycloakService.getToken().then(res => {
    });
    localStorage.setItem('username', userNameEmail);
    this.username = localStorage.getItem('username');
    console.log(this.keycloakService.getUserRoles());
  }

  setFrameOptions() {
    const frameOptions = this.sanitizer.bypassSecurityTrustResourceUrl('DENY');
    document.defaultView?.parent.postMessage({frameOptions}, '*');
  }

  testIfUserExist(userNameEmail) {
    let obj = {
      refreshToken: this.keycloakService.getKeycloakInstance().refreshToken
    };
    this.serviceAt.ifUserExist(obj).subscribe(res => {
      if (res && res.length) {
        this.serviceAt.role = res[0];
        this.serviceAt.respReturned = true;
        this.respReturned = true;
        this.serviceAt.ifUserChefDept().subscribe(res2=>{
          this.isChefDep=res2;
          this.menu = [
            {
              'title': 'Brief De Nego',
              'action': 'dashboard',
              'icon': 'fa-tachometer',
              'i18n': 'Brief de négo',
              'disabled': false,
              'submenu': []
            }, {
              'title': 'Demande De Performance',
              'action': 'demande-performance',
              'icon': 'fa-table',
              'i18n': 'Demande de Performance',
              'disabled': false,
              'submenu': []
            }, {
              'title': 'Demande Nvx Actifs',
              'action': 'demande-nvx-actifs',
              'icon': 'fa-table',
              'i18n': 'Demande Nvx Actifs',
              'disabled': false,
              'submenu': []
            }, {
              'title': 'Demande Actifs Physiques',
              'action': 'actif-physique',
              'icon': 'fa-table',
              'i18n': 'Demande Actifs Physiques',
              'disabled': false,
              'submenu': []
            }, {
              'title': 'Chiffres Clés',
              'action': 'joint',
              'icon': 'fa-table',
              'i18n': 'Chiffres Clés',
              'disabled': false,
              'gris': true,
              'submenu': []
            }, {
              'title': 'Plan Commercial',
              'action': 'vue-fournisseur',
              'icon': 'fa-table',
              'i18n': 'Plan Commercial',
              'disabled': false,
              'submenu': []
            }, {
              'title': 'Gestion des utilisateurs',
              'action': 'user-admin',
              'icon': 'fa-users',
              'i18n': 'Gestion des utilisateurs',
              'disabled': res[0] != 'ADMIN',
              'submenu': []
            }, {
              'title': 'Gestion lead',
              'action': 'gestion-lead',
              'icon': 'fa-users',
              'i18n': 'Gestion lead',
              'disabled': res[0] != 'ADMIN',
              'submenu': []
            }, {
              'title': 'Gestion des validateurs',
              'action': 'gestion-chef-dept',
              'icon': 'fa-users',
              'i18n': 'Gestion des validateurs',
              'disabled': res[0] != 'ADMIN',
              'submenu': []
            }, {
              'title': 'Gestion liste playbook',
              'action': 'gestion-liste-playbook',
              'icon': 'fa-users',
              'i18n': 'Gestion liste playbook',
              'disabled': res[0] != 'ADMIN',
              'submenu': []
            }, {
              'title': 'Liste validation',
              'action': 'liste-validation',
              'icon': 'fa-users',
              'i18n': 'Liste validation',
              'disabled': res[0] != 'ADMIN',
              'submenu': []
            },{
              'title': 'Campagne rapport',
              'action': 'rapport-tiers',
              'icon': 'fa-bar-chart',
              'i18n': 'Campagne rapport',
              'disabled': res[0] != 'ADMIN',
              'submenu': []
            }, {
              'title': 'Gestion des questionnaires',
              'action': 'questions-admin',
              'icon': 'fa-gear',
              'i18n': 'Gestion des questionnaires',
              'disabled': res[0] != 'ADMIN',
              'submenu': []
            }
          ];
        });
        Utils.showPopUpNotif('Bonjour');
      } else {
        //this.router.navigate(['access-denied']);
        this.logout();
        this.serviceAt.canConnect = false;
      }
    }, error => {
      /*if (this.tryCount) {
        this.tryCount--;
        this.testIfUserExist(userNameEmail);
      } else {*/
      //this.router.navigate(['server-down']);
      if (error.status == 401) {
        this.logout();
      }/*else if(error.status==500){
        Swal.fire({
          title: 'Error!',
          text: 'La connexion n\'est pas etablir avec la base de donnees',
          icon: 'error',
          timer: 5000,
          timerProgressBar: true,
          showConfirmButton: true
        });
      }*/
      this.serviceAt.canConnect = false;
      /*}*/
    });
  }

  logout() {
    this.clearLocalstorageSessionCookies();
    location.reload();
    //location.replace(environment.keycloak.issuer + '/realms/' + environment.keycloak.realm + '/protocol/openid-connect/logout');
  }

  clearLocalstorageSessionCookies() {
    localStorage.clear();
    sessionStorage.clear();
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  onToggleSidebar(isFolded: boolean) {
    this.isSidebarFolded = isFolded;
  }


  getChefDeptDetail() {
    this.serviceAt.ifUserChefDept().subscribe(res => {
      this.isChefDep = res;
    });
  }
}
