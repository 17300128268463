import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AttachmentService} from '../../shared/services/attachment.service';
import Swal from 'sweetalert2';
import {DemandeModel} from '../../core/models/demande.model';
import {Utils} from '../../core/utils/Utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-demande-performance',
  templateUrl: './demande-performance.component.html',
  styleUrls: ['./demande-performance.component.scss']
})
export class DemandePerformanceComponent implements OnInit {

  countries = [];
  years = [];
  osVersions = [];
  isAccepted: any = false;
  yearControl: any = '0';
  rayonControl: any = '0';
  deptControl: any = '0';
  rayons = [];
  depts = [];
  fournisseurControl: any = '0';
  fournisseurs = [];
  questions = [];
  demandes: DemandeModel[] = [];
  demandesFamille: DemandeModel[] = [];
  demandesSousFamille: DemandeModel[] = [];
  demandesRayon: DemandeModel[] = [];
  sQuestions = [];
  user: any;
  currentYear: string;
  currentFournisseur: string;
  issfamille = false;
  isfamille = false;
  totalCA = 0;
  totalTM = 0;
  totalDCV = 0;
  totalDMV = 0;
  totalDTV = 0;
  totalDTT = 0;
  totalCAFamille = 0;
  totalDCVFamille = 0;
  totalDMVFamille = 0;
  totalDTVFamille = 0;
  totalDTTFamille = 0;
  totalCASousFamille = 0;
  totalDCVSousFamille = 0;
  totalDMVSousFamille = 0;
  totalDTVSousFamille = 0;
  totalDTTSousFamille = 0;
  totalCARayon = 0;
  totalDCVRayon = 0;
  totalDMVRayon = 0;
  totalDTVRayon = 0;
  totalDTTRayon = 0;
  totalDCT = 0;
  totalDMT = 0;
  username: string;
  demandeArchives = [];

  constructor(private http: HttpClient,
              private router: Router,
              private attachmentService: AttachmentService) {
    if (!attachmentService.canConnect) {
      this.router.navigate(['access-denied']);
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userObject'));
    this.username = localStorage.getItem('username');
    this.getDemandes();
  }

  fetchCountries() {
    this.http.get<any>('../../../../assets/data/mock/countries.json').subscribe(res => {
      this.countries = res;
    });
  }

  getFornisseurs() {
    this.attachmentService.getFornisseurs(this.rayonControl).subscribe(response => {
      response.forEach(fournisseur => {
        this.fournisseurs.push(fournisseur.fournisseur);
      });
    });
  }

  fetchOsVersions() {
    this.http.get<any>('../../../../assets/data/mock/os-versions.json').subscribe(res => {
      this.osVersions = res;
    });
  }

  isFormulaireValid() {
    if (!this.isRayonValid()) {
      return false;
    } else if (this.yearControl == '0') {
      return false;
    } else {
      return this.fournisseurControl != '0';
    }
  }

  yearChanged() {
    console.log(this.yearControl);
  }

  isRayonValid() {
    return this.rayonControl != '0';
  }

  isFournisValid() {
    return this.fournisseurControl != '0';
  }

  isdeptValid() {
    return this.deptControl != '0';
  }

  rayonChanged() {
    //this.getFornisseurs();
  }

  getQuestions() {
    this.questions = [];
    this.attachmentService.getComments(this.fournisseurControl).subscribe(response => {
      response.forEach(comment => {
        this.questions.push(comment);
      });
    });
  }

  getDemandes() {
    this.currentYear = localStorage.getItem('year');
    this.currentFournisseur = localStorage.getItem('fournisseur');
    this.demandes = [];
    let dto = {
      fournisseur: this.currentFournisseur,
      annee: this.currentYear,
      issfamille: this.issfamille,
      isfamille: this.isfamille
    };
    this.attachmentService.getDemandes(dto, this.username).subscribe(response => {
      this.demandes = response;
      this.demandes.forEach(dm => {
        dm.annee = this.currentYear;
        dm.ca = this.checkNumber(dm.ca);
        if (dm.ca) {
          dm.caDisabled = true;
        }
        dm.dmv = this.checkNumber(dm.dmv);
        dm.dcv = this.checkNumber(dm.dcv);
        dm.dct = this.checkNumber(dm.dct);
        dm.dmt = this.checkNumber(dm.dmt);
        dm.dtt = this.checkNumber(dm.dtt);
        dm.dtv = this.checkNumber(dm.dtv);
        dm.tm = this.checkNumber(dm.tm);
      });
      this.changeCA();
      if (this.isfamille) {
        this.getDemandesRayon();
      } else {
        this.getDemandesFamilleInverse();
      }
      setTimeout(() => {
        this.demandes.forEach(dmd => this.changeCA(dmd));
      }, 1000);
    });
  }

  getDemandesFamille() {
    this.currentYear = localStorage.getItem('year');
    this.currentFournisseur = localStorage.getItem('fournisseur');
    this.demandesFamille = [];
    let dto = {
      fournisseur: this.currentFournisseur,
      annee: this.currentYear,
      issfamille: false,
      isfamille: true
    };
    this.attachmentService.getDemandes(dto, this.username).subscribe(response => {
      this.demandesFamille = response;
      this.demandesFamille.forEach(dm => {
        dm.annee = this.currentYear;
        dm.ca = this.checkNumber(dm.ca);
        dm.dmv = this.checkNumber(dm.dmv);
        dm.dcv = this.checkNumber(dm.dcv);
        dm.dct = this.checkNumber(dm.dct);
        dm.dmt = this.checkNumber(dm.dmt);
        dm.dtt = this.checkNumber(dm.dtt);
        dm.dtv = this.checkNumber(dm.dtv);
        dm.tm = this.checkNumber(dm.tm);
      });
      this.changeCAFamille();
      //from parent to child
      /*this.demandes.forEach(dmd => {
        if (!dmd.dmt || dmd.dmt == 0) {
          this.demandesFamille.filter(dmf => dmf.famille == dmd.famille && dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              dmd.dmt = dmf.dmt;
            });
        }
        if (!dmd.dct || dmd.dct == 0) {
          this.demandesFamille.filter(dmf => dmf.famille == dmd.famille && dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              dmd.dct = dmf.dct;
            });
        }
      });*/
    });
  }

  getDemandesSousFamilleInverse() {
    this.currentYear = localStorage.getItem('year');
    this.currentFournisseur = localStorage.getItem('fournisseur');
    this.demandesSousFamille = [];
    let dto = {
      fournisseur: this.currentFournisseur,
      annee: this.currentYear,
      issfamille: true,
      isfamille: true
    };
    this.attachmentService.getDemandes(dto, this.username).subscribe(response => {
      this.demandesSousFamille = response;
      this.demandesSousFamille.forEach(dm => {
        dm.annee = this.currentYear;
        dm.ca = this.checkNumber(dm.ca);
        dm.dmv = this.checkNumber(dm.dmv);
        dm.dcv = this.checkNumber(dm.dcv);
        dm.dct = this.checkNumber(dm.dct);
        dm.dmt = this.checkNumber(dm.dmt);
        dm.dtt = this.checkNumber(dm.dtt);
        dm.dtv = this.checkNumber(dm.dtv);
        dm.tm = this.checkNumber(dm.tm);
      });
      this.changeCASousFamille();
      this.demandes.forEach(dmd => {
        if (!dmd.dmt || dmd.dmt == 0) {
          let totalCaTemp = 0;
          this.demandesSousFamille.filter(dmf => dmf.famille == dmd.famille && dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              dmd.dmt += dmf.dmt * dmf.ca / 100;
              totalCaTemp += dmf.ca;
            });
          if (totalCaTemp != 0) {
            dmd.dmt = this.getTwoNumbersAfterVirgule(dmd.dmt / totalCaTemp * 100);
            if (dmd.ca != totalCaTemp) {
              dmd.changed = true;
            }
            dmd.ca = totalCaTemp;
          }
        }
        if (!dmd.dct || dmd.dct == 0) {
          let totalCaTemp = 0;
          this.demandesSousFamille.filter(dmf => dmf.famille == dmd.famille && dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              dmd.dct += dmf.dct * dmf.ca / 100;
              totalCaTemp += dmf.ca;
            });
          if (totalCaTemp != 0) {
            dmd.dct = this.getTwoNumbersAfterVirgule(dmd.dct / totalCaTemp * 100);
          }
        }
      });
    });
  }

  getDemandesFamilleInverse() {
    this.currentYear = localStorage.getItem('year');
    this.currentFournisseur = localStorage.getItem('fournisseur');
    this.demandesFamille = [];
    let dto = {
      fournisseur: this.currentFournisseur,
      annee: this.currentYear,
      issfamille: false,
      isfamille: true
    };
    this.attachmentService.getDemandes(dto, this.username).subscribe(response => {
      this.demandesFamille = response;
      this.demandesFamille.forEach(dm => {
        dm.annee = this.currentYear;
        dm.ca = this.checkNumber(dm.ca);
        dm.dmv = this.checkNumber(dm.dmv);
        dm.dcv = this.checkNumber(dm.dcv);
        dm.dct = this.checkNumber(dm.dct);
        dm.dmt = this.checkNumber(dm.dmt);
        dm.dtt = this.checkNumber(dm.dtt);
        dm.dtv = this.checkNumber(dm.dtv);
        dm.tm = this.checkNumber(dm.tm);
      });
      this.changeCAFamille();
      this.demandes.forEach(dmd => {
        if (!dmd.dmt || dmd.dmt == 0) {
          let totalCaTemp = 0;
          this.demandesFamille.filter(dmf => dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              dmd.dmt += dmf.dmt * dmf.ca / 100;
              totalCaTemp += dmf.ca;
            });
          if (totalCaTemp != 0) {
            dmd.dmt = this.getTwoNumbersAfterVirgule(dmd.dmt / totalCaTemp * 100);
            dmd.ca = totalCaTemp;
          }
        }
        if (!dmd.dct || dmd.dct == 0) {
          let totalCaTemp = 0;
          this.demandesFamille.filter(dmf => dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              dmd.dct += dmf.dct * dmf.ca / 100;
              totalCaTemp += dmf.ca;
            });
          if (totalCaTemp != 0) {
            dmd.dct = this.getTwoNumbersAfterVirgule(dmd.dct / totalCaTemp * 100);
          }
        }
      });
    });
  }

  getDemandesRayon() {
    this.currentYear = localStorage.getItem('year');
    this.currentFournisseur = localStorage.getItem('fournisseur');
    this.demandesRayon = [];
    let dto = {
      fournisseur: this.currentFournisseur,
      annee: this.currentYear,
      issfamille: false,
      isfamille: false
    };
    this.attachmentService.getDemandes(dto, this.username).subscribe(response => {
      this.demandesRayon = response;
      this.demandesRayon.forEach(dm => {
        dm.annee = this.currentYear;
        dm.ca = this.checkNumber(dm.ca);
        dm.dmv = this.checkNumber(dm.dmv);
        dm.dcv = this.checkNumber(dm.dcv);
        dm.dct = this.checkNumber(dm.dct);
        dm.dmt = this.checkNumber(dm.dmt);
        dm.dtt = this.checkNumber(dm.dtt);
        dm.dtv = this.checkNumber(dm.dtv);
        dm.tm = this.checkNumber(dm.tm);
      });
      this.changeCARayon();
      //from parent to child
      /*this.demandes.forEach(dmd => {
        if (!dmd.dmt || dmd.dmt == 0) {
          this.demandesRayon.filter(dmf => dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              dmd.dmt = dmf.dmt;
            });
        }
        if (!dmd.dct || dmd.dct == 0) {
          this.demandesRayon.filter(dmf => dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              dmd.dct = dmf.dct;
            });
        }
      });*/
      if (this.issfamille) {
        this.getDemandesFamille();
      } else {
        this.getDemandesSousFamilleInverse();
      }
    });
  }

  checkNumber(number) {
    return (!number || number == '') ? null : Number(number);
  }

  showEditDialog(question: any) {
    Swal.fire({
      title: '<strong></strong>',
      html:
        '<label for="swal2-input" class="swal2-input-label">Commentaire Année ' + (this.yearControl - 1) + '</label>' +
        '<textarea class="swal2-textarea disabled" style="display: flex;width: 88%;">' + 'Commentaire année précedente' + '</textarea>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: question.reponse,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#d5d5efde',
      focusConfirm: false,
      inputValidator: result => {
        if (!result) {
          return 'Il faut écrire un texte!';
        }
      },
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      cancelButtonText:
        '<i class="fa fa-ban" style="color: black">Annuller</i>',
      cancelButtonAriaLabel: 'Annuller'
    }).then(response => {
      console.log(response);
      if (response.isConfirmed) {
        Swal.fire({
          title: 'Modifié!', text: 'Votre questionnaire a été Modifié.', icon: 'success', timer: 1500,
          timerProgressBar: true, showConfirmButton: false
        });
      }
    });
  }

  controlChangedFournisseur() {
  }

  controlChangedYear() {
    this.questions = [];
  }

  showEditDialogPosture(question: any) {
    Swal.fire({
      title: '<strong></strong>',
      input: 'textarea',
      inputLabel: 'Commentaire',
      inputValue: question.argument,
      inputPlaceholder: 'Tapez votre commentaire ici...',
      inputAttributes: {
        'aria-label': 'Tapez votre commentaire ici...'
      },
      showCloseButton: true,
      showCancelButton: true,
      showDenyButton: true,
      denyButtonColor: '#d5d5efde',
      cancelButtonColor: '#f0f8ff',
      focusConfirm: false,
      /*inputValidator: result => {
        if (!result)
          return 'Il faut écrire un texte!';
      },*/
      confirmButtonText:
        '<i class="fa fa-edit"></i> Modifier!',
      confirmButtonAriaLabel: 'Modifier',
      denyButtonAriaLabel: 'Annuller',
      denyButtonText:
        '<i class="fa fa-ban" style="color: black"> Annuller</i>',
      cancelButtonText:
        '<i class="fa fa-info" style="color: black"> Detail historique</i>',
      cancelButtonAriaLabel: 'Detail'
    }).then(response => {
      console.log(response);
      if (response.isConfirmed) {
        /*let index = this.demandes.findIndex(qst => qst.argument == question.argument);
        this.demandes[index].argument = response.value;*/
        question.argument = response.value;
        this.updateCommentDemande(question);
      } else if (response.isDismissed && response.dismiss == Swal.DismissReason.cancel) {
        question.departement = question.departemant;
        this.attachmentService.getDemandeArchive(question).subscribe(result => {
          this.demandeArchives = result;
          Swal.fire({
            title: '<strong></strong>',
            width: 1000,
            html: (this.demandeArchives && this.demandeArchives.length) ? ('<table>' +
              '            <thead>' +
              '            <tr>' +
              '                <th class="normalTextTableTh" style="width: 15rem">' +
              '                    Demande croissance TX' +
              '                </th>' +
              '                <th class="normalTextTableTh" style="width: 15rem">' +
              '                    Demande Marge TX' +
              '                </th>' +
              '                <th class="normalTextTableTh" style="width: 40rem">' +
              '                    Argument' +
              '                </th>' +
              '                <th class="normalTextTableTh" style="width: 20rem">' +
              '                    CA ACHAT' +
              '                </th>' +
              '                <th class="normalTextTableTh" style="width: 20rem">' +
              '                    TX Marge Arr(Historique)' +
              '                </th>' +
              '                <th class="normalTextTableTh" style="width: 25rem">' +
              '                    Dernière modification (user/date)' +
              '                </th>' +
              '            </tr>' +
              '            </thead>' +
              '            <tbody><tr><td style="color: transparent">_</td><td></td><td></td><td></td><td></td></tr>' + this.getTrsDemandeArchives() +
              '            </tbody>' +
              '        </table>') : ('<label class="swal2-input-label"> Aucun Commentaire historique </label>'),
          }).then(res2 => {
          });
        });
        /*let elementById = document.getElementById('parentDivToSwal');
        document.body.appendChild(elementById);*/

      }
    });
  }

  getTrsDemandeArchives() {
    let result = '';
    this.demandeArchives.forEach(resArchiv => {
      result += '            <tr>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.dct ? resArchiv.dct : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.dmt ? resArchiv.dmt : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.argument ? resArchiv.argument : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.ca ? resArchiv.ca : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-right: 1px solid rgba(0,0,0,0.29);border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.tm ? resArchiv.tm : '_') +
        '                </td>' +
        '                <td class="normalTextTable" style="border-bottom: 1px solid rgba(0,0,0,0.29)">' +
        (resArchiv.lastUserEdit ? (resArchiv.lastUserEdit + ' / ' + resArchiv.lastTimeEdit) : '_') +
        '                </td>' +
        '            </tr>';
    });
    return result;
  }

  changeCA(demande?) {
    this.totalCA = 0;
    this.demandes.forEach(dmnd => this.totalCA += dmnd.ca);
    if (demande) {
      demande.dcv = demande.ca * demande.dct / 100;
      if (!demande.dcv) {
        demande.dcv = 0;
      }
      demande.dmv = demande.ca * demande.dmt / 100;
      if (!demande.dmv) {
        demande.dmv = 0;
      }
      demande.dtv = demande.dcv + demande.dmv;
      if (!demande.dtv) {
        demande.dtv = 0;
      }
      demande.dtt = demande.dtv / demande.ca * 100;
      if (!demande.dtt) {
        demande.dtt = 0;
      }
    }
    this.totalDMV = 0;
    this.demandes.forEach(dmnd => this.totalDMV += dmnd.dmv);
    this.totalDCV = 0;
    this.demandes.forEach(dmnd => this.totalDCV += dmnd.dcv);
    this.totalDTV = this.totalDCV + this.totalDMV;
    this.totalDTT = this.totalDTV / this.totalCA * 100;
    if (this.totalCA == 0) {
      this.totalDTT = 0;
    }
    this.totalDCT = this.totalDCV / this.totalCA * 100;
    this.totalDMT = this.totalDMV / this.totalCA * 100;
    if (this.totalCA == 0) {
      this.totalDCT = 0;
      this.totalDMT = 0;
    }
  }

  changeCAFamille() {
    this.totalCAFamille = 0;
    this.demandesFamille.forEach(dmnd => this.totalCAFamille += dmnd.ca);
    this.totalDMVFamille = 0;
    this.demandesFamille.forEach(dmnd => this.totalDMVFamille += dmnd.dmv);
    this.totalDCVFamille = 0;
    this.demandesFamille.forEach(dmnd => this.totalDCVFamille += dmnd.dcv);
    this.totalDTVFamille = this.totalDCVFamille + this.totalDMVFamille;
    this.totalDTTFamille = this.totalDTVFamille / this.totalCAFamille * 100;
  }

  changeCASousFamille() {
    this.totalCASousFamille = 0;
    this.demandesSousFamille.forEach(dmnd => this.totalCASousFamille += dmnd.ca);
    this.totalDMVSousFamille = 0;
    this.demandesSousFamille.forEach(dmnd => this.totalDMVSousFamille += dmnd.dmv);
    this.totalDCVSousFamille = 0;
    this.demandesSousFamille.forEach(dmnd => this.totalDCVSousFamille += dmnd.dcv);
    this.totalDTVSousFamille = this.totalDCVSousFamille + this.totalDMVSousFamille;
    this.totalDTTSousFamille = this.totalDTVSousFamille / this.totalCASousFamille * 100;
  }

  changeCARayon() {
    this.totalCARayon = 0;
    this.demandesRayon.forEach(dmnd => this.totalCARayon += dmnd.ca);
    this.totalDMVRayon = 0;
    this.demandesRayon.forEach(dmnd => this.totalDMVRayon += dmnd.dmv);
    this.totalDCVRayon = 0;
    this.demandesRayon.forEach(dmnd => this.totalDCVRayon += dmnd.dcv);
    this.totalDTVRayon = this.totalDCVRayon + this.totalDMVRayon;
    this.totalDTTRayon = this.totalDTVRayon / this.totalCARayon * 100;
    console.log('totalDTTRayon', this.totalDTTRayon);
  }

  changeDCT(demande: any) {
    demande.dcv = demande.ca * demande.dct / 100;
    if (!demande.dcv) {
      demande.dcv = 0;
    }
    demande.dtv = demande.dcv + demande.dmv;
    if (!demande.dtv) {
      demande.dtv = 0;
    }
    demande.dtt = demande.dtv / demande.ca * 100;
    if (!demande.dtt) {
      demande.dtt = 0;
    }
    this.totalDCV = 0;
    this.demandes.forEach(dmnd => this.totalDCV += dmnd.dcv);
    this.totalDTV = this.totalDCV + this.totalDMV;
    this.totalDTT = this.totalDTV / this.totalCA * 100;
    if (this.totalCA == 0) {
      this.totalDTT = 0;
    }
    this.totalDCT = this.totalDCV / this.totalCA * 100;
    if (this.totalCA == 0) {
      this.totalDCT = 0;
    }
  }

  changeDMT(demande: any) {
    demande.dmv = demande.ca * demande.dmt / 100;
    if (!demande.dmv) {
      demande.dmv = 0;
    }
    demande.dtv = demande.dcv + demande.dmv;
    if (!demande.dtv) {
      demande.dtv = 0;
    }
    demande.dtt = demande.dtv / demande.ca * 100;
    if (!demande.dtt) {
      demande.dtt = 0;
    }
    this.totalDMV = 0;
    this.demandes.forEach(dmnd => this.totalDMV += dmnd.dmv);
    this.totalDTV = this.totalDCV + this.totalDMV;
    this.totalDTT = this.totalDTV / this.totalCA * 100;
    if (this.totalCA == 0) {
      this.totalDTT = 0;
    }
    this.totalDMT = this.totalDMV / this.totalCA * 100;
    if (this.totalCA == 0) {
      this.totalDMT = 0;
    }
  }

  saveDemandes() {

    if (!this.valideDemande()) {
      return false;
    }
    if (this.issfamille && this.totalDTT && this.totalDTT != 0 && ((this.totalDTTRayon && this.totalDTTRayon != 0) || (this.totalDTTFamille && this.totalDTTFamille != 0)) && (this.totalDTTRayon && this.getTwoNumbersAfterVirgule(this.totalDTTRayon) != this.getTwoNumbersAfterVirgule(this.totalDTT) || this.getTwoNumbersAfterVirgule(this.totalDTTFamille) != this.getTwoNumbersAfterVirgule(this.totalDTT))) {
      this.showMessageError('Le taux (sous-famille) n\'équivaut pas au taux global du (famille rayon).');
      return false;
    }
    if (this.isfamille && this.totalDTT && this.totalDTT != 0 && this.totalDTTRayon && this.totalDTTRayon != 0 && this.getTwoNumbersAfterVirgule(this.totalDTTRayon) != this.getTwoNumbersAfterVirgule(this.totalDTT)) {
      this.showMessageError('Le taux (famille) n\'équivaut pas au taux global du rayon.');
      return false;
    }

    this.attachmentService.saveDemandes(this.demandes, this.username).subscribe(response => {
      this.getDemandes();
      Swal.fire({
        title: 'Modifié!', text: 'Votre demande a été Modifié.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
    });
    return true;
  }

  updateCommentDemande(demande) {
    this.attachmentService.updateCommentDemande(demande, this.username).subscribe(response => {
      Utils.showPopUpNotif('Votre demande a été Modifié.');
    });
  }

  valideDemande() {
    let founded = false;
    //tout va remplir
    /*this.demandes.forEach(dmd => {
      if ((Number(dmd.dct) && !Number(dmd.ca)) || (Number(dmd.dmt) && !Number(dmd.ca))) {
        founded = true;
      }
    });
    if (founded) {
      this.showMessageError('Merci de remplir tous les chiffres d\'affaires .');
      return false;
    }*/
    //tout va remplir
    /*this.demandes.forEach(dmd => {
      if ((Number(dmd.ca) && !Number(dmd.dct) && !Number(dmd.dmt))) {
        founded = true;
      }
    });
    if (founded) {
      this.showMessageError('Merci de remplir tous les taux .');
      return false;
    }*/
    if (this.issfamille) {
      //tout les famille de rayon va remplir
      /*this.demandes.forEach(dmd => {
        if (dmd.dct && dmd.ca) {
          let allRemplir = this.demandes.filter(demande => demande.famille == dmd.famille && demande.rayon == dmd.rayon)
            .every(demande => demande.dct && dmd.ca);
          if (!allRemplir) {
            this.showMessageError('Merci de remplir tous les chiffres pour la famille : ' + dmd.famille + ' .');
            founded = true;
          }
        }
        if (dmd.dmt && dmd.ca) {
          let allRemplir = this.demandes.filter(demande => demande.famille == dmd.famille && demande.rayon == dmd.rayon)
            .every(demande => demande.dmt && dmd.ca);
          if (!allRemplir) {
            this.showMessageError('Merci de remplir tous les chiffres pour la famille : ' + dmd.famille + ' .');
            founded = true;
          }
        }
      });
      if (founded) {
        return false;
      }*/
      // regle de calcul dtt pour chaque rayon
      this.demandesFamille.forEach(dmd => {
        if (dmd.dmt) {
          let totalCaTemp = 0;
          let totalDMTTemp = 0;
          this.demandes.filter(dmf => dmf.famille == dmd.famille && dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              totalDMTTemp += dmf.dmt * dmf.ca / 100;
              totalCaTemp += dmf.ca;
            });
          if (totalCaTemp != 0) {
            if (totalCaTemp != dmd.ca) {
              this.showMessageError('Merci de vérifier total de chiffre d\'affaire ' + totalCaTemp + (totalCaTemp > dmd.ca ? '>' : '<') + dmd.ca + ' .');
              founded = true;
            } else {
              let twoNumbersAfterVirgule = this.getTwoNumbersAfterVirgule(totalDMTTemp / totalCaTemp * 100);
              if (Number(dmd.dmt) != Number(twoNumbersAfterVirgule)) {
                this.showMessageError('Merci de vérifier le calcul ' + twoNumbersAfterVirgule + (twoNumbersAfterVirgule > dmd.dmt ? '>' : '<') + dmd.dmt + ' .');
                founded = true;
              }
            }

          } /*else {
            this.showMessageError('Merci de vérifier total de chiffre d\'affaire ' + totalCaTemp + (totalCaTemp > dmd.ca ? '>' : '<') + dmd.ca + ' .');
            founded = true;
          }*/
        }
        if (dmd.dct) {
          let totalCaTemp = 0;
          let totalDCTTemp = 0;
          this.demandes.filter(dmf => dmf.famille == dmd.famille && dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              totalDCTTemp += dmf.dct * dmf.ca / 100;
              totalCaTemp += dmf.ca;
            });
          if (totalCaTemp != 0) {
            if (totalCaTemp != dmd.ca) {
              this.showMessageError('Merci de vérifier total de chiffre d\'affaire ' + totalCaTemp + (totalCaTemp > dmd.ca ? '>' : '<') + dmd.ca + ' .');
              founded = true;
            } else {
              let twoNumbersAfterVirgule = this.getTwoNumbersAfterVirgule(totalDCTTemp / totalCaTemp * 100);
              if (Number(dmd.dct) != Number(twoNumbersAfterVirgule)) {
                this.showMessageError('Merci de vérifier le calcul ' + twoNumbersAfterVirgule + (twoNumbersAfterVirgule > dmd.dct ? '>' : '<') + dmd.dct + ' .');
                founded = true;
              }
            }
          } /*else {
            this.showMessageError('Merci de vérifier total de chiffre d\'affaire ' + totalCaTemp + (totalCaTemp > dmd.ca ? '>' : '<') + dmd.ca + ' .');
            founded = true;
          }*/
        }
      });
      if (founded) {
        return false;
      }
    } else if (this.isfamille) {
      //tout les famille de rayon va remplir
      /*this.demandes.forEach(dmd => {
        if (dmd.dct && dmd.ca) {
          let allRemplir = this.demandes.filter(demande => demande.rayon == dmd.rayon)
            .every(demande => demande.dct && dmd.ca);
          if (!allRemplir) {
            this.showMessageError('Merci de remplir tous les chiffres pour le rayon ' + dmd.rayon + ' .');
            founded = true;
          }
        }
        if (dmd.dmt && dmd.ca) {
          let allRemplir = this.demandes.filter(demande => demande.rayon == dmd.rayon)
            .every(demande => demande.dmt && dmd.ca);
          if (!allRemplir) {
            this.showMessageError('Merci de remplir tous les chiffres pour le rayon ' + dmd.rayon + ' .');
            founded = true;
          }
        }
      });
      if (founded) {
        return false;
      }*/
      // regle de calcul dtt pour chaque rayon
      this.demandesRayon.forEach(dmd => {
        if (dmd.dmt) {
          let totalCaTemp = 0;
          let totalDMTTemp = 0;
          this.demandes.filter(dmf => dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              totalDMTTemp += dmf.dmt * dmf.ca / 100;
              totalCaTemp += dmf.ca;
            });
          if (totalCaTemp != 0) {
            if (totalCaTemp != dmd.ca) {
              this.showMessageError('Merci de vérifier total de chiffre d\'affaire ' + totalCaTemp + (totalCaTemp > dmd.ca ? '>' : '<') + dmd.ca + ' .');
              founded = true;
            } else {
              let twoNumbersAfterVirgule = this.getTwoNumbersAfterVirgule(totalDMTTemp / totalCaTemp * 100);
              if (Number(dmd.dmt) != Number(twoNumbersAfterVirgule)) {
                this.showMessageError('Merci de vérifier le calcul ' + twoNumbersAfterVirgule + (twoNumbersAfterVirgule > dmd.dmt ? '>' : '<') + dmd.dmt + ' .');
                founded = true;
              }
            }

          } /*else {
            this.showMessageError('Merci de vérifier total de chiffre d\'affaire ' + totalCaTemp + (totalCaTemp > dmd.ca ? '>' : '<') + dmd.ca + ' .');
            founded = true;
          }*/
        }
        if (dmd.dct) {
          let totalCaTemp = 0;
          let totalDCTTemp = 0;
          this.demandes.filter(dmf => dmf.rayon == dmd.rayon)
            .forEach(dmf => {
              totalDCTTemp += dmf.dct * dmf.ca / 100;
              totalCaTemp += dmf.ca;
            });
          if (totalCaTemp != 0) {
            if (totalCaTemp != dmd.ca) {
              this.showMessageError('Merci de vérifier total de chiffre d\'affaire ' + totalCaTemp + (totalCaTemp > dmd.ca ? '>' : '<') + dmd.ca + ' .');
              founded = true;
            } else {
              let twoNumbersAfterVirgule = this.getTwoNumbersAfterVirgule(totalDCTTemp / totalCaTemp * 100);
              if (Number(dmd.dct) != Number(twoNumbersAfterVirgule)) {
                this.showMessageError('Merci de vérifier le calcul ' + twoNumbersAfterVirgule + (twoNumbersAfterVirgule > dmd.dct ? '>' : '<') + dmd.dct + ' .');
                founded = true;
              }
            }
          } /*else {
            this.showMessageError('Merci de vérifier total de chiffre d\'affaire ' + totalCaTemp + (totalCaTemp > dmd.ca ? '>' : '<') + dmd.ca + ' .');
            founded = true;
          }*/
        }
      });
      if (founded) {
        return false;
      }
    }
    return true;
  }

  showMessageError(message) {
    Swal.fire({
      title: 'Error!',
      text: message,
      icon: 'error',
      timer: 5000,
      timerProgressBar: true,
      showConfirmButton: true
    });
  }

  clickSFamille() {
    Swal.fire({
      title: '',
      text: 'Vous voulez que ce soit enregistré ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.saveDemandes()) {
          if (!this.isfamille) {
            this.isfamille = true;
          }
        } else {
          setTimeout(() => this.issfamille = !this.issfamille, 500);
        }
      } else {
        if (!this.isfamille) {
          this.isfamille = true;
        }
        this.getDemandes();
      }
    });
  }

  clickFamille() {
    Swal.fire({
      title: '',
      text: 'Vous voulez que ce soit enregistré ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non'
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.saveDemandes()) {
          if (this.issfamille) {
            this.issfamille = false;
          }
        } else {
          setTimeout(() => this.isfamille = !this.isfamille, 500);
        }
      } else {
        if (this.issfamille) {
          this.issfamille = false;
        }
        this.getDemandes();
      }
    });
  }

  getTwoNumbersAfterVirgule(number) {
    var num = Number(number);
    return this.numberWithSpaces(num.toFixed(2));
  }

  getTwoNumbersAfterVirguleRemoveTwoZero(number) {
    var num = Number(number);
    return this.numberWithSpacesRemoveTwoZero(num.toFixed(2));
  }

  getTwoNumbersReal(number) {
    var num = Number(number);
    return this.numberWithSpaces(Math.round(num));
  }

  numberWithSpaces(number) {
    if (number == 0) {
      return 0;
    } else {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.00', '');
    }
  }

  numberWithSpacesRemoveTwoZero(number) {
    if (number == 0) {
      return 0;
    } else {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.00', '');
    }
  }

  demandeChanged(demande: DemandeModel) {
    demande.changed = true;
  }
}
