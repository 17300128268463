import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AttachmentService} from '../../shared/services/attachment.service';
import Swal from 'sweetalert2';
import {Utils} from '../../core/utils/Utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-liste-validation',
  templateUrl: './liste-validation.component.html',
  styleUrls: ['./liste-validation.component.scss']
})
export class ListeValidationComponent implements OnInit {

  countries = [];
  years = [];
  osVersions = [];
  isAccepted: any = false;
  yearControl;
  rayonControl: any = '0';
  deptControl: any = '0';
  rayons = [];
  roles = [];
  depts = [];
  fournisseurControl;
  fournisseurs = [];
  questions = [];
  sQuestions = [];
  users = [];
  currentYear: number;
  username: string;
  searchFilter = '';
  userControl: any;
  roleControl: any;
  isEdit = false;
  tryCount = 3;
  leads = [];
  dateFinFinalisation: any;
  dateFinValidation: any;
  tierControl: any;
  tiers: any = [];
  niveaux = [];
  niveauControl: any;
  allFournisseurMonoRayon = [];

  constructor(private http: HttpClient,
              private router: Router,
              private attachmentService: AttachmentService) {
    if (!attachmentService.canConnect) {
      this.router.navigate(['access-denied']);
    }
    this.testRole();
  }

  testRole() {
    if (this.attachmentService.respReturned) {
      if (this.attachmentService.role != 'ADMIN') {
        this.router.navigate(['access-denied']);
      }
    } else {
      setTimeout(() => this.testRole(), 500);
    }
  }

  ngOnInit() {
    //this.user = JSON.parse(localStorage.getItem('userObject'));
    this.username = localStorage.getItem('username');
    this.getAllFournisseurMonoRayon();
  }

  getAllLeads() {
    this.attachmentService.getListePlaybookChef().subscribe(response => {
      if (response && response.length) {
        this.leads = response;
        this.leads.forEach(lead => {
          lead.sous_deptsTitle = lead.sous_depts.slice(9, lead.sous_depts.length);
          lead.achteursTitle = lead.achteurs.slice(9, lead.achteurs.length);
        });
      }
    });
  }

  getAllFournisseurMonoRayon() {
    this.attachmentService.getAllFournisseurMonoRayon().subscribe(response => {
      if (response && response.length) {
        this.allFournisseurMonoRayon = response;
        this.getAllLeads();
      }
    });
  }

  getLeadFiltered() {
    if (this.searchFilter && this.searchFilter != '') {
      return this.leads.filter(usr => usr.user.toUpperCase().includes(this.searchFilter.toUpperCase())
        || usr.fournisseurs.map(fr => fr.toUpperCase()).join('').includes(this.searchFilter.toUpperCase()));
    } else {
      return this.leads;
    }
  }

  goToLeadPage(fournisseur: any, annee: any) {
    localStorage.setItem('fournisseur', fournisseur);
    localStorage.setItem('year', annee);
    this.router.navigate(['lead-bn']);
  }

  showValiderButton(user: any) {
    if (user.isn1 == 'oui') {
      if (this.allFournisseurMonoRayon.includes(user.fournisseur)) {
        return user.status == 'FINALISED';
      } else {
        return user.status == 'FINALISEDLEAD';
      }
    }
  }

  showValiderButtonN2(user: any) {
    return user.isn2 == 'oui' && user.status == 'VALIDATIONN+1';
  }

  showValiderButtonN3(user: any) {
    return user.isn3 == 'oui' && user.status == 'VALIDATIONN+2';
  }

  validePlaybookN1(user: any) {
    this.attachmentService.validatePlaybookChef(user.fournisseur, user.annee, user.sous_dept).subscribe(response => {
      Swal.fire({
        title: 'Validé!', text: 'Ce playbook a été validé.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
      this.getAllLeads();
    });
  }
  validePlaybookN2(user: any) {
    this.attachmentService.validatePlaybookN2(user.fournisseur, user.annee).subscribe(response => {
      Swal.fire({
        title: 'Validé!', text: 'Ce playbook a été validé.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
      this.getAllLeads();
    });
  }
  validePlaybookN3(user: any) {
    this.attachmentService.validatePlaybookN3(user.fournisseur, user.annee).subscribe(response => {
      Swal.fire({
        title: 'Validé!', text: 'Ce playbook a été validé.', icon: 'success', timer: 1500,
        timerProgressBar: true, showConfirmButton: false
      });
      this.getAllLeads();
    });
  }
}
