import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {AttachmentService} from '../../shared/services/attachment.service';
import Swal from 'sweetalert2';
import {Utils} from '../../core/utils/Utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-suivi-connexion',
  templateUrl: './suivi-connexion.component.html',
  styleUrls: ['./suivi-connexion.component.scss']
})
export class SuiviConnexionComponent implements OnInit {

  countries = [];
  years = [];
  osVersions = [];
  isAccepted: any = false;
  yearControl: any = '0';
  rayonControl = [];
  deptControl: any = '0';
  rayons = [];
  roles = [];
  depts = [];
  fournisseurControl: any = '0';
  fournisseurs = [];
  questions = [];
  sQuestions = [];
  users = [];
  currentYear: number;
  username: string;
  searchFilter = '';
  userControl: any;
  roleControl: any;
  isEdit = false;

  constructor(private http: HttpClient,
              private router: Router,
              private attachmentService: AttachmentService) {
    if (!attachmentService.canConnect) {
      this.router.navigate(['access-denied']);
    }
    this.testRole();
  }

  testRole() {
    if (this.attachmentService.respReturned) {
      if (this.attachmentService.role != 'ADMIN') {
        this.router.navigate(['access-denied']);
      }
    } else {
      setTimeout(() => this.testRole(), 500);
    }
  }

  ngOnInit() {
    //this.user = JSON.parse(localStorage.getItem('userObject'));
    this.username = localStorage.getItem('username');
    this.getlesRoles();
    this.getAllUsers();
  }

  isFormulaireValid() {
    return this.roleControl && this.roleControl.label && this.roleControl.label != '' &&
      this.rayonControl && this.rayonControl.length &&
      this.userControl && this.userControl != '';
  }

  getAllRayon(isAdmin) {
    this.rayonControl = [];
    this.rayons = [];
    this.attachmentService.getAllRayons().subscribe(response => {
      response.forEach(comment => {
        /*let rayon = {};
        rayon['label'] = comment;*/
        this.rayons.push(comment);
      });
      if (isAdmin) {
        this.rayonControl = this.rayons;
      }
    });
  }

  getAllUsers() {
    this.attachmentService.getAllUsers().subscribe(response => {
      if (response && response.length) {
        this.users = response;
      }
    });
  }

  addUser() {
    let user = {
      username: this.userControl,
      role: this.roleControl.label,
      rayons: this.rayonControl
    };
    this.attachmentService.addUser(user).subscribe(response => {
      this.getAllUsers();
      this.userControl = '';
      this.roleControl = {};
      this.rayonControl = [];
      Utils.showPopUpNotif('Utilisateur a été Ajouté.');
    });
  }

  editUser() {
    let user = {
      username: this.userControl,
      role: this.roleControl.label,
      rayons: this.rayonControl
    };
    this.attachmentService.editUser(user).subscribe(response => {
      this.getAllUsers();
      this.isEdit = false;
      this.userControl = '';
      this.roleControl = {};
      this.rayonControl = [];
      Utils.showPopUpNotif('Utilisateur a été Modifié.');
    });
  }

  getUserFiltered() {
    if (this.searchFilter && this.searchFilter != '') {
      return this.users.filter(usr => usr.user.toUpperCase().includes(this.searchFilter.toUpperCase()) || usr.roleuser.toUpperCase().includes(this.searchFilter.toUpperCase()));
    } else {
      return this.users;
    }
  }

  editDisable(user: any) {
    if (user.disable == 'oui') {
      user.disable = 'non';
    } else {
      user.disable = 'oui';
    }
    this.attachmentService.editUserDisable(user.user, user.disable).subscribe(response => {
      Utils.showPopUpNotif('Édité avec succès');
    });
  }

  private getlesRoles() {
    let roles = [];
    let role = {};
    role['label'] = 'ADMIN';
    roles.push(role);
    role = {};
    role['label'] = 'ACHAT';
    roles.push(role);
    this.roles = roles;
  }

  roleChanged() {
    if (this.roleControl && this.roleControl.label && this.roleControl.label != '') {
      if (this.roleControl.label == 'ADMIN') {
        this.getAllRayon(true);
      } else if (this.roleControl.label == 'ACHAT') {
        this.getAllRayon(false);
      }
    }
  }

  chargeUser(user: any) {
    this.userControl = user.user;
    this.roleControl = {};
    this.roleControl.label = user.roleuser;
    this.getAllRayon(false);
    this.rayonControl = user.rayons;
  }
}
