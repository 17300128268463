<div class="page">
  <!--<app-stats></app-stats>-->
  <app-stats [isForActifPhysique]="true" [currentYear]="currentYear"
             [currentFournisseur]="currentFournisseur"></app-stats>
  <main>
    <div class="panel">
      <div class="table-panel countries">
        <div class="panel-title">Demande Actifs Physiques</div>
        <div class="panel-body">

          <p><span style="font-size:11px"><span style="color:#000000"><strong><span
            style="font-family:Arial,sans-serif">Compl&eacute;tez les cellules avec le code &nbsp;couleur &quot;bleu ciel&quot;.</span></strong></span></span>
          </p>

          <p><span style="font-size:11px"><span style="color:#000000"><span style="font-family:Arial,sans-serif">S&eacute;lectionner dans les menus d&eacute;roulants : le type d&#39;actifs, le moment de vente et le cluster concern&eacute;.</span></span></span>
          </p>

          <p><span style="font-size:11px"><span style="color:#000000"><span style="font-family:Arial,sans-serif">Remplir vos objectifs en terme de nombre d&#39;actifs et de d&eacute;pliants pour la campagne</span><strong><span
            style="font-family:Arial,sans-serif">.</span></strong></span><span><strong><span
            style="font-family:Arial,sans-serif"> ATTENTION: mettre 1 pour les actifs permanents dans la colonne nombre de d&eacute;pliants.</span></strong></span></span>
          </p>
          <p><span style="color:#2ecc71"><span style="font-size:15px"><strong><span
                  style="font-family:Arial,sans-serif;font-style: italic">NOTE :Calcul des tarifs 2022</span></strong></span></span></p>
          <hr/>
          <p><span style="font-size:16px"><strong><span style="color:#e74c3c">AVERTISSEMENT&nbsp;</span></strong></span>
          </p>

          <p>1. Les actifs EPCS sont vendus uniquement en permanent dans les hypermarch&eacute;s&nbsp;<br/>
            2. Les actifs sorties de caisse (1 caisse sur 2, sur l&#39;ensemble du magasin) sont vendus uniquement en
            permanent<br/>
            3. Les actifs Podium PoS sont vendus uniquement lors des &eacute;v&egrave;nements&nbsp;<br/>
            4. Les actifs Podium all&eacute;e centrale, Podium PoS et EPCS ne sont pas vendus en
            Supermarch&eacute;s<br/>
            5. Les actifs Portiques ne sont pas vendus en permanent</p>

          <hr/>
          <div style="display: flex;justify-content: space-around;">
            <p>Prix à demander pour le package </p>
            <div class="text-center" style="width: 20%">
              <label>CIBLE 100%</label>
              <input type="number" min="0" class="form-control" [(ngModel)]="cible" *ngIf="hideCible" (focusout)="hideCible=false">
              <input type="text" class="form-control" [value]="getTwoNumbersAfterVirgule(cible)" (click)="hideCible=true" *ngIf="!hideCible">
            </div>
            <div class="text-center" style="width: 20%">
              <label>DEMANDE en DHS</label>
              <input type="number" min="0" class="form-control saisie_c" disabled [(ngModel)]="demande" (ngModelChange)="demandeChanged()" *ngIf="hideDemande" (focusout)="hideDemande=false">
              <input type="text" class="form-control saisie_c" disabled [value]="getTwoNumbersAfterVirgule(demande)" (click)="hideDemande=true" *ngIf="!hideDemande">
            </div>
            <div class="text-center" style="width: 20%">
              <label>RATIO en %</label>
              <input type="number" min="0" disabled class="form-control" [(ngModel)]="ratio" *ngIf="hideRatio" (focusout)="hideRatio=false">
              <input type="text" disabled class="form-control" [value]="getTwoNumbersAfterVirgule(ratio)" (click)="hideRatio=true" *ngIf="!hideRatio">
            </div>
          </div>
          <hr/>
          <div style="display: flex;overflow: scroll;">
            <div style="overflow: scroll;width: 100%;height: 63vh">
              <table class="table-responsive tableFixHead">
                <thead>
                <tr>
                  <th style="border-radius: 2rem 0 0" class="thTableStyle">
                    Département
                  </th>
                  <th class="thTableStyle" style="width: 15rem">
                    Rayon
                  </th>
                  <th class="thTableStyle" style="width: 20rem">
                    Type<br>actifs
                  </th>
                  <th class="thTableStyle" style="width: 6rem">
                    Nb<br>actifs
                  </th>
                  <th class="thTableStyle" style="width: 15rem">
                    Moment de vente
                  </th>
                  <th class="thTableStyle">
                    Cluster
                  </th>
                  <th class="thTableStyle" style="width: 8rem">
                    Nb magasins<br>premiums
                  </th>
                  <th class="thTableStyle" style="width: 8rem">
                    Nb magasins<br>standars
                  </th>
                  <th class="thTableStyle" style="width: 6rem">
                    Nb<br>dépliants
                  </th><th class="thTableStyle" style="width: 6rem">
                    Demande
                  </th>
                  <th class="thTableStyle" style="border-radius: 0 2rem 0 0;width: 8rem">
                    Actions
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let demande of demandes;index as i" class="heightTdSameControls">
                  <td class="normalTextTable">
                    <select class="form-control" [(ngModel)]="demande.dept" (change)="deptChanged(demande)">
                      <option disabled value="0">Selectionner un Département</option>
                      <option *ngFor="let dept of depts" value="{{dept}}">{{dept}}</option>
                    </select>
                  </td>
                  <td class="normalTextTable">
                    <select class="form-control" [(ngModel)]="demande.rayon" (change)="rayonChanged()"
                            [disabled]="!isdeptValid(demande)">
                      <option disabled value="0">Selectionner un rayon</option>
                      <option *ngFor="let rayon of demande.rayons" value="{{rayon}}">{{rayon}}</option>
                    </select>
                  </td>
                  <td class="normalTextTable">
                    <select class="form-control" [(ngModel)]="demande.type" (ngModelChange)="typeChanged(demande)">
                      <option disabled value="0">Selectionner un type</option>
                      <option *ngFor="let option of types" value="{{option}}">{{option}}</option>
                    </select>
                  </td>
                  <td class="normalTextTable">
                    <input type="number" min="0" class="form-control" [(ngModel)]="demande.nb_actif" (ngModelChange)="depliantChanged(demande)">
                  </td>
                  <td class="normalTextTable">
                    <select class="form-control" [(ngModel)]="demande.moment" (ngModelChange)="depliantChanged(demande)">
                      <option disabled value="0">Selectionner une moment</option>
                      <option *ngFor="let option of moments" value="{{option}}">{{option}}</option>
                    </select>
                  </td>
                  <td class="normalTextTable">
                    <select class="form-control" [(ngModel)]="demande.cluster"
                            (ngModelChange)="clusterChanged(demande)">
                      <option disabled value="0">Selectionner une moment</option>
                      <option *ngFor="let option of clusters" value="{{option}}">{{option}}</option>
                    </select>
                  </td>
                  <td class="normalTextTable">
                    <input type="number" min="0" class="form-control" [(ngModel)]="demande.nb_magasin_premium" (ngModelChange)="depliantChanged(demande)">
                  </td>
                  <td class="normalTextTable">
                    <input type="number" min="0" class="form-control" [(ngModel)]="demande.nb_magasin_standar" (ngModelChange)="depliantChanged(demande)">
                  </td>
                  <td class="normalTextTable">
                    <input type="number" min="0" class="form-control" [(ngModel)]="demande.nb_depliant" (ngModelChange)="depliantChanged(demande)">
                  </td>
                  <td class="normalTextTable">
                    <input type="number" min="0" class="form-control" [(ngModel)]="demande.demande" (ngModelChange)="demandeDemandeChanged()">
                  </td>
                  <td class="text-center" style="display: flex;justify-content: center;">
                    <div class="divNewPlan" (click)="removePlanDec(i)">
                      <span><i class="fa fa-minus"></i></span>
                    </div>
                    <div class="divNewPlan" (click)="addNewPlanDec(i)">
                      <span><i class="fa fa-plus"></i></span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <button class="btn btn-primary flexAutoMarge"
                  style="color: white;background-color: #b22a2e;height: 3.3rem;float: right" (click)="saveDemandes()">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </main>
</div>
