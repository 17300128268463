<div class="page">
    <!--<app-stats></app-stats>-->
    <app-stats [isSuiviConnexion]="true" [currentUser]="username"></app-stats>
    <main>
        <div class="panel">
            <div class="table-panel users">
                <div class="panel-title">
                    Gestion des validateurs
                </div>
                <div class="panel-body" style="display: flex;align-items: center;justify-content: space-around;">
                    <div class="form-group" style="width: 25%;padding: 0 0.5rem;">
                        <label class="form-label">Validateur :</label>
                        <ng-select *ngIf="users&&users.length" [disabled]="isEdit"
                                   [items]="users"
                                   placeholder="Selectionner un validateur"
                                   [(ngModel)]="userControl">
                        </ng-select>
                    </div>

                    <div class="form-group" style="width: 25%;padding: 0 0.5rem;">
                        <label class="form-label">Sous départements :</label>
                        <ng-select *ngIf="sousDept&&sousDept.length"
                                   [items]="sousDept"
                                   [multiple]="true"
                                   [hideSelected]="true"
                                   [closeOnSelect]="false"
                                   [searchable]="true"
                                   placeholder="Selectionner les départements"
                                   [(ngModel)]="fournisseurControl">
                        </ng-select>
                    </div>
                    <div class="form-group" style="width: 25%;padding: 0 0.5rem;">
                        <label class="form-label">Niveau de validation :</label>
                        <ng-select *ngIf="years&&years.length"
                                   [items]="years"
                                   placeholder="Selectionner niveau de validation"
                                   [(ngModel)]="yearControl">
                        </ng-select>
                    </div>
                  <div class="form-group" style="width: 25%;padding: 0 0.5rem;">
                    <label class="form-label">Utilisateur :</label>
                    <ng-select *ngIf="users&&users.length"
                               [items]="users"
                               [multiple]="true"
                               [closeOnSelect]="false"
                               placeholder="Selectionner un utilisateur"
                               [(ngModel)]="user2Control">
                    </ng-select>
                  </div>
                    <div class="form-group" style="width: 25%">
                        <div style="width: 60%;margin-left: auto;margin-right: auto;">
                            <button class="btn btn-primary flexAutoMarge" *ngIf="!isEdit"
                                    style="color: white;background-color: #b22a2e;height: 3.3rem;width: 100%;margin: 0.5rem"
                                    [disabled]="!isFormulaireValid()" (click)="addUser()">Ajouter
                            </button>
                            <button class="btn btn-primary flexAutoMarge" *ngIf="isEdit"
                                    style="color: white;background-color: #b22a2e;height: 3.3rem;width: 100%;margin: 0.5rem"
                                    [disabled]="!isFormulaireValid()" (click)="editUser()">Modifier
                            </button>
                            <button class="btn btn-light flexAutoMarge"
                                    style="height: 3.3rem;width: 100%;margin: 0.5rem"
                                    (click)="this.isEdit = false;this.userControl = null;this.user2Control = null;this.fournisseurControl = [];this.yearControl = null;">
                                Annuler
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="panel">
            <div class="table-panel users">
                <div class="panel-title">
                    Liste des validateurs
                </div>
                <input type="text" class="form-control" [(ngModel)]="searchFilter" placeholder="Recherche"
                       style="width: 90%;margin-left: auto;margin-right: auto">
                <div class="panel-body">
                    <table>
                        <thead>
                        <tr>
                            <th style="width: 15%;text-align: center">
                              Validateur
                            </th>
                            <th style="width: 30%;text-align: center">
                              Sous départements
                            </th>
                          <th style="width: 35%;text-align: center">
                            Utilisateur
                            </th>
                            <th style="width: 20%;text-align: center">
                              Niveau de validation
                            </th>
                            <th style="width: 10%;text-align: center">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let user of getLeadFiltered()">
                            <td style="text-align: center">{{user.user}}</td>
                            <td style="text-align: center">
                                <div class="divWithTickets">
                                    <div class="row" *ngIf="user.depts">
                                        <pre class="col-lg-3 col-md-5 col-sm-6 mx-auto"
                                             style="color:white;background-color: rgba(54,153,255,0.71);border-radius: 1rem;padding: 0.2rem;margin: 0.1rem"
                                             *ngFor="let fournis of user.depts.slice(0,9)">{{fournis}}</pre>
                                        <pre class="col-lg-3 col-md-5 col-sm-6 mx-auto"
                                             style="color:white;background-color: rgba(54,153,255,0.71);border-radius: 1rem;padding: 0.2rem;margin: 0.1rem"
                                             *ngIf="user.depts.length>9"
                                             [title]="user.deptsTitle">...</pre>
                                    </div>
                                </div>
                            </td>
                          <td style="text-align: center">
                                <div class="divWithTickets">
                                    <div class="row" *ngIf="user.achteurs">
                                        <pre class="col-lg-7 col-md-7 col-sm-7 mx-auto"
                                             style="color:white;background-color: rgba(54,153,255,0.71);border-radius: 1rem;padding: 0.2rem;margin: 0.1rem"
                                             *ngFor="let fournis of user.achteurs.slice(0,9)">{{fournis}}</pre>
                                        <pre class="col-lg-7 col-md-7 col-sm-7 mx-auto"
                                             style="color:white;background-color: rgba(54,153,255,0.71);border-radius: 1rem;padding: 0.2rem;margin: 0.1rem"
                                             *ngIf="user.achteurs.length>9"
                                             [title]="user.achteursTitle">...</pre>
                                    </div>
                                </div>
                            </td>
                          <td style="text-align: center">{{user.niveau_validation}}</td>
                            <td style="text-align: center"><i class="fa fas fa-edit cursor-pointer"
                                                              (click)="isEdit=true;chargeUser(user)"></i>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </main>
</div>
